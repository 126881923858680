import { db } from './firebase';
import moment from 'moment';

const settings = {timestampsInSnapshots: true};
db.settings(settings);

export const deleteRecurring = (id) =>
  db.collection('Recurring').doc(id).delete();

export const deleteRegion = (id) =>
  db.collection('Regions').doc(id).delete();

export const deleteQuery = (id) =>
  db.collection('Queries').doc(id).delete();



export const getAffiliate = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Users').doc(id).get().then(doc => {
      var x = doc.data();
      if(x) x.id = doc.id;
      resolve(x);
    });
  });
}

export const getBudgetsByCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Budgets');
    var queryRef = baseRef.where('customerID','==',id);
    return queryRef.get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      resolve(objs);
    });
  });
}

export const getBudgetByAddress = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Budgets');
      var queryRef = baseRef.where('addressID','==',id);
      return queryRef.get().then(snapshot => {
        
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          resolve (d);
        });
      });
    }
    catch(exception) {
      console.log('db.getBudgetByAddress() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getBudgetExceptionsByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('BudgetExceptions');
    var queryRef = baseRef.where('CustomerID','==',customerID);
    queryRef.get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getCategories = () => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Categories');
      baseRef.orderBy("Name","asc").get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getCategories() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getCategoryGroupsByCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('CategoryGroups');
      var queryRef = baseRef.where('customerID','==',id);
      return queryRef.orderBy("name","asc").get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getCategoryGroupsByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getCompany = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Companies').doc(id).get().then(doc => {
      var x = doc.data();
      if(x) x.id = doc.id;
      resolve(x);
    });
  });
}

export const getContact = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      db.collection('Contacts').doc(id).get().then(doc => {
        var obj = doc.data();
        obj.id = doc.id;
        resolve(obj);
      });
    }
    catch(exception) {
      console.log('db.getContact() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getCurrentFutureSpecials = () => {
  return new Promise(function(resolve, reject) {
    var now = moment().valueOf();
    var baseRef = db.collection('Specials');
    return baseRef.where("to",">=",now).get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      resolve(objs);
    });
  });
}

export const getCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      db.collection('Customers').doc(id).get().then(doc => {
        var customer = doc.data();
        customer.id = doc.id;
        customer.key = doc.id;
        customer.label = doc.Name;
        resolve(customer);
      });
    }
    catch(exception) {
      console.log('db.getCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getEstimatesByUser = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Estimates');
      var queryRef = baseRef.where('userID','==',id);
      return queryRef.get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getEstimatesByUser() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getExceptionsByProfile = (profileID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Exceptions');
    var queryRef = baseRef.where('profileID','==',profileID);
    queryRef.get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getInvoice = (customerID, invoiceID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Invoices');
    var queryRef = baseRef.where('customer_id','==',customerID).where('invoice_number','==',invoiceID.toUpperCase());
    return queryRef.get().then(snapshot => {
      
      if(!snapshot.empty) {
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          resolve(d);
        });
      } else {
        resolve(null);
      }
    });
  });
}

export const getInvoicesByCustomer = (id, startAfter) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Invoices');
      var queryRef = baseRef.where('customer_id','==',id).orderBy('invoice_id','desc');
      if(startAfter) {
        queryRef = queryRef.startAfter(startAfter);
      }
      return queryRef.limit(50).get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getInvoicesByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getInvoicesByCustomerAndStatus = (id, status) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Invoices');
      var queryRef = baseRef.where('customer_id','==',id).where('status','==',status);
      return queryRef.get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getInvoicesByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getInvoicesByCustomerAndDateRange = (id, startDate, endDate) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Invoices');
      var queryRef = baseRef.where('customer_id','==',id).where('status','==','paid').where("date",">=",startDate).where("date","<=",endDate);
      return queryRef.get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.unshift(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getInvoicesByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getInvoiceNotes = (customerID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('InvoiceNotes');
    var queryRef = baseRef.where('customerID','==',customerID);
    return queryRef.orderBy("created","desc").get().then(snapshot => {
      var notes = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        notes.push(d);
      });
      resolve(notes);
    });
  });
}

export const getInvoicesBySalesOrderID = (salesOrderID) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Invoices');
      var queryRef = baseRef.where('salesorder_id','==',salesOrderID);
      return queryRef.get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getInvoicesBySalesOrderID() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getLandingPageConfig = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Others');
    baseRef.doc('webConfig').get().then(doc => {
      
      var d = doc.data();
      d.id = doc.id;
      
      resolve(d);
    });
  });
}


export const getManufacturers = () => {
  var baseRef = db.collection('Manufacturers');
  return baseRef.orderBy("Name","asc").get();
}

export const getOrderByZohoSalesOrderID = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Orders');
      var queryRef = baseRef.where('salesOrderID','==',id);
      return queryRef.get().then(snapshot => {
        
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          console.log('resolve me')
          resolve(d);
        });
      });
    }
    catch(exception) {
      console.log('db.getOrderByZohoSalesOrderID() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getOrdersByAddress = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Orders');
      var queryRef = baseRef.where('shippingAddressID','==',id);
      return queryRef.get().then(snapshot => {
        var orders = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          orders.push(d);
        });
        resolve(orders);
      });
    }
    catch(exception) {
      console.log('db.getOrdersByAddress() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getOrdersByCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Orders');
      var queryRef = baseRef.where('customerID','==',id);
      return queryRef.orderBy("modified","desc").get().then(snapshot => {
        var orders = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          orders.push(d);
        });
        resolve(orders);
      });
    }
    catch(exception) {
      console.log('db.getOrdersByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getOrdersByCustomerDateRange = (id, startDate, endDate) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Orders');
      var queryRef = baseRef.where('customerID','==',id).where("modified",">=",startDate).where("modified","<=",endDate);
      return queryRef.orderBy("modified","desc").get().then(snapshot => {
        var orders = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          orders.push(d);
        });
        
        resolve(orders);
      });
    }
    catch(exception) {
      console.log('db.getOrdersByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getOrdersByUser = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Orders');
      var queryRef = baseRef.where('userID','==',id);
      return queryRef.orderBy("modified","desc").get().then(snapshot => {
        var orders = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          orders.push(d);
        });
        resolve(orders);
      });
    }
    catch(exception) {
      console.log('db.getOrdersByUser() EXCEPTION');
      console.log(exception);
    }
  });
}
 
export const getOrganization = (id) =>
  db.collection('Organizations').doc(id).get();

export const getOther = (name) => db.collection('Others').doc(name).get()

export const getPricingByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Pricing');
      var queryRef = baseRef.where('CustomerID','==',customerID);
      queryRef.get().then(snapshot => {
        
        var objs = [];
        snapshot.forEach((doc) => {
          var obj = doc.data();
          obj.id = doc.id;
          objs.push(obj);        
        });
  
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getPricingByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getPaymentsByCustomer = (id, startAfter) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Payments');
      var queryRef = baseRef.where('customer_id','==',id).orderBy('payment_id','desc');
      if(startAfter) {
        queryRef = queryRef.startAfter(startAfter);
      }
      return queryRef.limit(50).get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getPaymentsByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getProduct = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Products').doc(id).get().then(doc => {
      var obj = doc.data();
      obj.id = doc.id;
      resolve(obj);
    });
  });
}

export const getProductCategories = () => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Products');
      return baseRef.get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          var obj = {
            id: doc.id,
            categoryID: d.CategoryID,
            subCat1: d.SubCat1ID,
            subCat2: d.SubCat2ID,
            subCat3: d.SubCat3ID
          }
          objs.push(obj);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getProductCategories() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getProductHistoryFromLocation = async (locationId) => {
  // Look for orders where the shipping address ID is equal to the given location ID. Make sure the orders are listed by orderPlaced time
  // create an object of all products in these order keeping track of the count and the orderPlace time.
  // if a product wasn't seen before, then the latest order that includes it is the first order containing it (because of the sorting in the first step)
  // create an array of the products and sort it by the orderPlaced time so that the latest product ordered is in the beginning
  try {
    const snapshot = await db.collection('Orders').where('shippingAddressID', '==', locationId).orderBy('orderPlaced', 'desc').get()
    const orders = snapshot.docs;
    const products = {};
    orders.forEach((order)=>{
      order.items.forEach((item)=>{
        const { id, name, description, manufacturer, productID } = item;
        if(!products[id]) {
          products[id] = { id, name, description, manufacturer, productID, latestOrderDate: order.orderPlaced, count: 0 }
        }
        products[id].count += 1
      })
    })
    const productsOrdered = products.values().sort((a,b)=> -(a.latestOrderDate - b.latestOrderDate))
    return productsOrdered
  } catch (err) {
    console.error("db.getProductHistoryFromLocation() EXCEPTION")
    console.error(err)
  }
}

export const getQueriesByCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Queries');
      var queryRef = baseRef.where('customerID','==',id);
      return queryRef.orderBy("name","asc").get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getQueriesByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getRegionsByCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Regions');
      var queryRef = baseRef.where('customerID','==',id);
      return queryRef.orderBy("name","asc").get().then(snapshot => {
        var regions = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          regions.push(d);
        });
        resolve(regions);
      });
    }
    catch(exception) {
      console.log('db.getRegionsByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getFrequentPurchases = async (currentAddressID, currentUserID) => {
  return new Promise(function(resolve, reject) {
    try {
      //const desiredProductCount = 8;
      const ordersFetched = [];

      getOrdersByAddress(currentAddressID).then( async (orders) => {

        const productIDs = {};

        orders.forEach((order)=>{
          ordersFetched.push(order.id)
          order.items.forEach((item)=>{
            if(!productIDs[item.productID]) 
              productIDs[item.productID] = 1;
            else 
              productIDs[item.productID] += 1;
          })
        })

        var productsOrdered = Object.keys(productIDs).sort((a, b)=>productIDs[b] - productIDs[a]);

        productsOrdered = productsOrdered.splice(0,8);

        // find products from object keys. then fetch the first value from the array that will contain a single entry. then filter out any queries that returned undefined
        const products = (await Promise.all(productsOrdered.map((productId)=>db.collection('Products').where('ProductID', '==', productId).get()))).map((snapshot)=>snapshot.docs[0]?.data()).filter((product)=>product)
        
        /*
        if(products.length < desiredProductCount) {
          const productsFetched = products.reduce((acc, product)=>{
            if(acc[product.id]) return (acc[product.id] += 1)
            return Object.assign({}, acc, {[product.id]: 1})
          }, {})
          const newOrders = (await db.collection('Orders').where('customerID', '==', currentUserID).where('modified', '>', moment().subtract(90, 'days').unix()).orderBy('modified', 'desc').get()).docs.map((doc)=>doc.data()).filter((order)=>!ordersFetched.includes(order.id))
          for (let index = 0; index < newOrders.length; index++) {
            const order = newOrders[index];
            order.items.forEach((item)=> {
              if(productsFetched[item.id]) return;
              products.push(item)
              productsFetched[item.id] = true;
            })
            if(products.length >= desiredProductCount) break;
          }
        }
        */

        resolve (products);
      });

    } catch (err) {
      console.error('getProductCategories() EXCEPTION')
      console.error(err)
      resolve([]);
    }
  });
}

export const getProfile = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Profiles').doc(id).get().then(doc => {
      var x = doc.data();
      if(x) x.id = doc.id;
      resolve(x);
    });
  });
}

export const getRenewingOrdersByCustomerLocation = (customerID, locationID) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Recurring');
      var queryRef = baseRef.where('customerID','==',customerID).where('shippingAddressID','==',locationID);
      return queryRef.get().then(snapshot => {
        var orders = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          orders.push(d);
        });
        resolve(orders);
      });
    }
    catch(exception) {
      console.log('db.getRenewingOrdersByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getSalesOrdersByCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('SalesOrders');
      var queryRef = baseRef.where('customer_id','==',id);
      return queryRef.get().then(snapshot => {
        var orders = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          orders.push(d);
        });
        resolve(orders);
      });
    }
    catch(exception) {
      console.log('db.getSalesOrdersByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getShipToAddressesByCustomer = (customerID) => {

  return new Promise(function(resolve, reject) {

    try
    {
      db.collection('Addresses').doc(customerID).get().then((doc) => {

        var obj = doc.data();
        
        var addresses = obj.addresses;
  
        // special case DTK + DTK2
        if(customerID === '1343284000000063075') {
          
          db.collection('Addresses').doc('1343284000001266431').get().then((doc2) => {
            var obj2 = doc2.data();
  
            for(var i = 0; i < obj2.addresses.length; i++) {
              obj2.addresses[i].customer_id = '1343284000001266431';
              addresses.push(obj2.addresses[i]);
            }
            
            db.collection('Addresses').doc('1343284000001915048').get().then((doc3) => {
              var obj3 = doc3.data();

              for(var i = 0; i < obj3.addresses.length; i++) {
                obj3.addresses[i].customer_id = '1343284000001915048';
                addresses.push(obj3.addresses[i]);
              }
              
              resolve(addresses);
            });
          });
        } else {
          //console.log('single customer');
          resolve(addresses);
        }
      });
    }
    catch(exception) {
      console.log('db.getShipToAddressesByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}
  
export const getStripeAccount = (id) =>
  db.collection('StripeAccounts').doc(id).get();


export const getTaxExemption = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('TaxExemptions').doc(id).get().then((doc) => {
  
        var obj = doc.data();
        if(obj) {
          obj.id = doc.id;
          resolve (obj);
        } else {
          resolve(null);
        }
    });
  });
}

export const getUser = (id) => 
  db.collection('Users').doc(id).get();

export const getUserGroupsByCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('UserGroups');
      var queryRef = baseRef.where('customerID','==',id);
      return queryRef.orderBy("name","asc").get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getUserGroupsByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const isUserDisabled = async (email) => {
  const snapshot = await db.collection('Users').where("Email", "==", email).get();
  if(snapshot.docs.length === 0) return;
  return snapshot.docs[0].data().isDisabled
}

export const getUsersByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Users');
    var queryRef = baseRef.where('customerID','==',customerID);
    queryRef.orderBy("LastName","desc").get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getVideo = (id) => 
  db.collection('Videos').doc(id).get();
  
export const getVisibilityExceptionsByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('VisibilityExceptions');
      var queryRef = baseRef.where('CustomerID','==',customerID);
      queryRef.get().then(snapshot => {
        
        var objs = [];
        snapshot.forEach((doc) => {
          var obj = doc.data();
          obj.id = doc.id;
          objs.push(obj);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getVisibilityExceptionsByCustomer() EXCEPTION');
      console.log(exception);
    }
  });
}

export const logActivity = (userID, customerID, activityType, objectID) => {

  try
  {
    if(typeof userID === 'undefined')
      userID = null;

    if(typeof customerID === 'undefined')
      customerID = null;

    if(typeof objectID === 'undefined')
      objectID = null;

    var activity = {
      UserID: userID,
      customerID: customerID,
      ActivityType: activityType,
      ObjectID: objectID,
      modified: Math.round((new Date()).getTime() / 1000)
    }

    return db.collection('Activity').add(activity);
  }
  catch(exception) {
    console.log('db.logActivity() EXCEPTION');
    console.log(exception);
  }
}

export const updateAddress = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Addresses').add(value);
  else 
    return db.collection('Addresses').doc(id).set(value);
}

export const updateBudget = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Budgets').add(value);
  else 
    return db.collection('Budgets').doc(id).set(value);
}

export const updateCategoryGroup = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('CategoryGroups').add(value);
  else 
    return db.collection('CategoryGroups').doc(id).set(value);
}

export const updateEstimate = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Estimates').add(value);
  else 
    return db.collection('Estimates').doc(id).set(value);
}

export const updateInvoiceNote = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('InvoiceNotes').add(value);
  else 
    return db.collection('InvoiceNotes').doc(id).set(value);
}

export const updateLocation = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Locations').add(value);
  else 
    return db.collection('Locations').doc(id).set(value);
}
  
export const updateOrganization = (id, value) => {
  if(id === 0) 
    return db.collection('Organizations').add(value);
  else 
    return db.collection('Organizations').doc(id).set(value);
}

export const updateOrder = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Orders').add(value);
  else 
    return db.collection('Orders').doc(id).set(value);
}

export const updateProduct = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Products').add(value);
  else 
    return db.collection('Products').doc(id).set(value);
}

export const updateQuery = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Queries').add(value);
  else 
    return db.collection('Queries').doc(id).set(value);
}

export const updateRecurringOrder = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Recurring').add(value);
  else 
    return db.collection('Recurring').doc(id).set(value);
}


export const updateRegion = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Regions').add(value);
  else 
    return db.collection('Regions').doc(id).set(value);
}

export const updateUser = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Users').add(value);
  else 
    return db.collection('Users').doc(id).set(value);
}


export const updateUserGroup = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('UserGroups').add(value);
  else 
    return db.collection('UserGroups').doc(id).set(value);
}