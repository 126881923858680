import React, { Component } from 'react'

//router
import { Redirect } from 'react-router-dom';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SpecialSelected } from 'redux/actions';

//styles
import { withStyles, createStyles } from '@material-ui/core'
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

// core components

import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Parser from 'html-react-parser';


const style = createStyles({
  special: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: 250,
    height: 400,
    margin: 10,
    padding: '20px 10px',
    border: '1px solid #eee',
    borderRadius: 12,
  },
  specialImage: {
    width: 250,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& > img': {
      maxWidth: '100%',
      height: 160,
    }
  },
  specialName: {
    textAlign: 'center'
  },
  specialLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
})


class SectionSpecials extends Component {
  constructor(props) {
    super()
    this.state = {
      specials: props.specials,
      specialSelected: false
    }
  }

  htmlContent(content) {
    if (content) return Parser(content);
    else return '';
  }

  selectSpecial(special) {
    this.props.onSpecialSelected(special);
    this.setState({specialSelected: true});
  }

  render() {
    const { classes } = this.props;

    if (this.state.specialSelected) {
      return <Redirect to="/specials" />;
    }

    return (
      <div className={classes.section} >
        {
          this.state.specials.length > 0 
          &&
          <span>
            <GridContainer>
              <GridItem >
                <h4 className={classes.title}>SPECIALS</h4>
              </GridItem>
            </GridContainer>
            <GridContainer style={{backgroundColor:"#dddddd", borderRadius:"6px", padding:"17px"}}>
            {
              this.state.specials.map((special, index)=>(
                <div key={index} >
                  <GridContainer>
                    <GridItem xs={12}>
                      <Card style={{ marginTop: '6px', marginBottom: '6px' }}>
                        <div style={{backgroundSize: "cover", height:"180px", width:"100%", backgroundColor:"#aaaaaa", borderTopRightRadius:"6px", borderTopLeftRadius:"6px",
                            backgroundImage:`url("${special.image.url}")`, backgroundRepeat:"no-repeat", backgroundPosition: "center"}}>
                        </div>
                        <CardBody>
                          <h4 className={classes.title} style={{marginTop:"0px", marginBottom:"0px", paddingTop:"0px", paddingBottom:"0px"}}>{special.name}</h4>
                          {special.description}
                        </CardBody>
                        <CardFooter>
                          <Button style={{width:"100%"}}
                            onClick={() => this.selectSpecial(special)}
                          >
                            View 
                          </Button>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              ))
            }
            </GridContainer>
          </span>
        }
      </div>
    )
  }
}

const mapStateToProps = state => state



function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSpecialSelected: SpecialSelected }, dispatch);  
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Object.assign({}, featuresStyle, style))(SectionSpecials))