import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//algolia full text search
import { algolia } from 'assets/algolia';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CartProductAdded } from 'redux/actions';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.jsx";

//components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import Table from "@material-ui/core/Table";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from "components/CustomButtons/Button.jsx";
import Hidden from '@material-ui/core/Hidden';

//kekaha
import AddressChooser from "components/AddressChooser/AddressChooser.jsx";

//icons
import Expand from "@material-ui/icons/KeyboardArrowDown";
import ShoppingCart from "@material-ui/icons/AddShoppingCart";
import Renew from "@material-ui/icons/Autorenew";

//custom pricing
import applyCustomPricing from "assets/customPricing"

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class OrderHistoryPage extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
          orders: [],
          shipTo: 'all',
          shipToAddress: { }
        };

        this.getOrders = this.getOrders.bind(this);
        this.refreshOrders = this.refreshOrders.bind(this);
        this.addressesLoaded = this.addressesLoaded.bind(this);
        this.changeShipTo = this.changeShipTo.bind(this);

        db.logActivity(this.props.user.id, this.props.user.customerID, "View-Order-History");
    }

    addressesLoaded(addresses) {
        this.setState({addresses:addresses},() => {
            this.changeShipTo('all');
        });
    }

    getOrders(callback) {
        if(this.props.user.customerID) {
            db.getOrdersByCustomer(this.props.user.customerID).then(orders => {
                callback(orders);
            });
        } else {
            db.getOrdersByUser(this.props.user.id).then(orders => {
                for(var i = 0; i < orders.length; i++) {
                    orders[i].userName = this.props.user.email;
                }
                callback(orders);
            });
        }
    }

    refreshOrders() {
        
        var filtered = [];
        this.getOrders(orders => {
            
            for(var i = 0; i < orders.length; i++) {

                if(orders[i].shippingAddressID === this.state.shipTo || this.state.shipTo === 'all') {
                    
                    for(var j = 0; j < this.props.locations.length; j++) {
                        if(this.props.locations[j].address_id === orders[i].shippingAddressID) {
                            orders[i].address = this.props.locations[j].address;
                            orders[i].city = this.props.locations[j].city;
                            orders[i].state = this.props.locations[j].state;
                            orders[i].zip = this.props.locations[j].zip;
                            break;
                        }
                    }
                    
                    if(!orders[i].tax)
                        orders[i].tax = 0;
                    orders[i].tax = orders[i].tax.toFixed(2);

                    if(!orders[i].orderTotal)
                        orders[i].orderTotal = 0;
                    orders[i].orderTotal = Number(orders[i].orderTotal).toFixed(2);

                    orders[i].orderPlaced = new Date(orders[i].orderPlaced).toLocaleDateString();

                    filtered.push(orders[i]);    
                }
            }

            this.setState({orders: filtered});
        });
    }

    reOrder(order) {
        
        for(var i = 0; i < order.items.length; i++) 
            this.addProductToCart(order.items[i]);
        
    }

    addProductToCart(item) {
        
        algolia.searchProducts(item.productID).then(results => {
            console.log(results);
            if (results.length > 0) {
                var product = results.find(({ productID }) => productID === item.productID);

                if (product) {

                    // set image property
                    if(!product.thumbnail) 
                        product.thumbnail = "/missingImage.jpg";
                    
                    if(!product.photo) 
                        product.photo = "/missingImage.jpg";
                    
                    // apply custom pricing
                    if (this.props.pricing) 
                        applyCustomPricing(this.props.pricing, product)
                    
                    product.qty = item.qty;
                    this.props.onCartProductAdded(product);
                }
            }
        });
    }

    changeShipTo(value) {
    
        if(value !== 'all') {

            //for display
            for(var i = 0; i < this.props.locations.length; i++) {
                if(this.props.locations[i].key === value) {
                this.setState( {shipToAddress: this.props.locations[i]} );
                break;
                }
            }
        }
    
        // for the select
        this.setState({shipTo: value}, () => {
            this.refreshOrders();
        });

    }

    showNoResults() {
        if(this.state.orders.length === 0) {
            return "block";
        } else {
            return "none";
        }
    }

    getStatusText(order) {

        if(!order.status)
            return <div style={{color:"#ff9800"}}>PENDING</div>
        else if (order.status === 'APPROVED')
            return <div>APPROVED</div>
    }

    showIsRecurring(order) {
        if(order.isRecurring)
            return "block";
        else
            return "none";
    }

    render() {
        const { classes } = this.props;

        if (!this.props.user) {
            return <Redirect to='/login' />
        } else if (!this.props.user.authenticated) {
            return <Redirect to='/login' />
        }
    
        return (
          <div>
            <Header
              brand="Ecclesia"
              links={<HeaderLinks dropdownHoverColor="info" />}
              fixed
              color="dark"
            />
            <div className={classes.container} style={{minHeight:"369px", marginTop:"100px"}}>
                <GridContainer>
                    <GridItem xs={12}>
                        <h3 className={classes.cardTitle}>Order History</h3>
                    </GridItem>
                    <GridItem xs={12}>
                        <AddressChooser onAddressesLoaded={this.addressesLoaded} shipToAddress={this.state.shipTo} onAddressChosen={this.changeShipTo} addressFilter />
                    </GridItem>
                    
                    <GridItem xs={12}>
                    {this.state.orders.map((order,index) => (
                        <ExpansionPanel key={index} style={{marginBottom:"17px"}} >
                            <ExpansionPanelSummary >
                                <GridContainer style={{width:"100%", color:"green", fontSize:"13px", fontWeight:400, padding:"0px"}}>
                                    <GridItem xs={4} sm={4} md={2}>
                                        {order.orderPlaced}
                                        <div style={{color:"gray", fontSize:"11px", marginTop:"-6px"}}>Order Date</div>
                                    </GridItem>
                                    <GridItem xs={5} sm={5} md={2}>
                                        {order.userName}
                                        <div style={{color:"gray", fontSize:"11px", marginTop:"-6px"}}>Order Placed By</div>
                                    </GridItem>
                                    <GridItem xs={3} sm={3} md={2}>
                                        {this.getStatusText(order)} 
                                        <div style={{color:"gray", fontSize:"11px", marginTop:"-6px"}}>Status</div>
                                    </GridItem>

                                    <GridItem xs={4} md={2} >
                                        ${numberWithCommas(order.orderTotal)}
                                        <div style={{color:"gray", fontSize:"11px", marginTop:"-6px"}}>Order Total</div>
                                    </GridItem>
                                    <GridItem xs={5} sm={5} md={3}>
                                        {order.poNumber}
                                        <div style={{color:"gray", fontSize:"11px", marginTop:"-6px"}}>Purchase Order</div>
                                    </GridItem>
                                    <GridItem xs={1} sm={1} md={1}>
                                        <div style={{display:this.showIsRecurring(order),position:"relative",top:"5px"}} title='Recurring Order'>
                                            <Renew />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={1} style={{textAlign:"right" }}>
                                        <Expand />
                                    </GridItem>
                                </GridContainer>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                <GridContainer>
                                    <GridItem xs={6} style={{fontSize:"13px",color:"#232323",marginBottom:"17px"}}>
                                        <b>Shipping Address</b>
                                        <div style={{fontWeight:400}}>
                                            {order.address}<br />
                                            {order.city}, {order.state} {order.zip}
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6} style={{textAlign:"right"}}>
                                        <Button onClick={() => this.reOrder(order)}><ShoppingCart /> Reorder </Button>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <Hidden mdUp>
                                            <Table>
                                                <TableBody>
                                                    {order.items.map((item,thirdIndex) => ( 
                                                    <TableRow key={thirdIndex} >
                                                        <TableCell style={{paddingLeft: "15px", paddingRight: "15px"}}>
                                                            <GridContainer >
                                                                <GridItem xs={5} style={{color:"green", padding:"0px"}}>
                                                                    {item.productID}
                                                                </GridItem>
                                                                <GridItem xs={1} style={{color:"green", padding:"0px"}}>
                                                                    {item.qty}
                                                                </GridItem>
                                                                <GridItem xs={3} style={{color:"green", textAlign:"right", padding:"0px" }}>
                                                                    ${item.listPrice}
                                                                </GridItem>
                                                                <GridItem xs={3} style={{color:"green", textAlign:"right", padding:"0px" }}>
                                                                    ${item.total}
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer >
                                                                <GridItem xs={12} style={{color:"#545454", fontSize:"11px", padding:"0px", paddingBottom:"4px", lineHeight:"15px"}}>
                                                                    {item.name}
                                                                </GridItem>
                                                            </GridContainer>
                                                        </TableCell>
                                                    </TableRow>
                                                    ))}
                                                 </TableBody>   
                                            </Table>
                                        </Hidden>
                                        <Hidden smDown>                             
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{width:"54px"}}>
                                                            Quantity
                                                        </TableCell>
                                                        <TableCell>
                                                            Product ID
                                                        </TableCell>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right" }}>
                                                            Price 
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right" }}>
                                                            Total
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {order.items.map((item,secondIndex) => ( 
                                                        <TableRow key={secondIndex}>
                                                            <TableCell style={{width:"54px"}}>
                                                                {item.qty}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.productID}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.name}
                                                            </TableCell>
                                                            <TableCell style={{textAlign:"right" }}>
                                                                ${item.listPrice}
                                                            </TableCell>
                                                            <TableCell style={{textAlign:"right" }}>
                                                                ${item.total}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <TableRow key={order.id + "taxRow"}>
                                                        <TableCell style={{width:"54px"}}>
                                                            
                                                        </TableCell>
                                                        <TableCell>
                                                            
                                                        </TableCell>
                                                        <TableCell>
                                                            
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right", color:"gray" }}>
                                                            Taxes
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right" }}>
                                                            ${order.tax}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={order.id + "totalRow"}>
                                                        <TableCell style={{width:"54px"}}>
                                                            
                                                        </TableCell>
                                                        <TableCell>
                                                            
                                                        </TableCell>
                                                        <TableCell>
                                                            
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right", color:"gray" }}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right" }}>
                                                            ${numberWithCommas(order.orderTotal)}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Hidden>
                                    </GridItem>
                                </GridContainer>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                    </GridItem>

                    <GridItem xs={12} style={{display:this.showNoResults(), textAlign:"center", color:"maroon", fontSize:"12px", marginTop:"50px"}}>
                        Sorry, we did not find any orders for this location.
                    </GridItem>
                </GridContainer>
            </div>
            <Footer
                content={
                    <div>
                    <div className={classes.right} style={{fontSize:"13px"}}>
                        &copy; {1900 + new Date().getYear()} Ecclesia, Inc.
                    </div>
                    </div>
                }
            />
          </div>
        );
    }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({onCartProductAdded: CartProductAdded }, dispatch);  
}

OrderHistoryPage = connect(mapStateToProps, mapDispatchToProps)(OrderHistoryPage);
export default withStyles(shoppingCartStyle)(OrderHistoryPage);
