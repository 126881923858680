
import { auth } from './firebase';
import { isUserDisabled } from './db';

export const onAuthStateChanged = () =>
  auth.onAuthStateChanged;

export const getCurrentUser = function() {
  return auth.currentUser;
} 
  
// Sign Up
export const createUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = async (email, password) => {

  const isDisabled = await isUserDisabled(email)
  if(isDisabled) {
    const error = new Error("User has been disabled for inactivity")
    error.code = "auth/custom-disabled"
    throw error;
  }

  return auth.signInWithEmailAndPassword(email, password);

}

// Sign out
export const signOut = () => {
  return new Promise(function(resolve, reject) {
    auth.signOut().then(function() {
      console.log('Signed Out');
      resolve(true);
    }, function(error) {
      console.error('Sign Out Error', error);
      resolve(false);
    });
  });
}

// Password Reset
export const sendPasswordReset = (email) =>
  auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
  auth.currentUser.updatePassword(password);

export const UpdateUserDisplayName = (name) =>
  auth.currentUser.updateProfile(name);

export const updatePassword = (newPassword) => 
  auth.currentUser.updatePassword(newPassword);