import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

// react plugin for creating charts
import ChartistGraph from "react-chartist";

// for dates
import moment from 'moment';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

//material-ui
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {TreeView, TreeItem} from '@material-ui/lab';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
//import Link from '@material-ui/core/Link';

//components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSelect from "components/Select/Select.jsx";
import Button from "components/CustomButtons/Button.jsx";

//custom components
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";
import AddressPicker from "components/AddressPicker/AddressPicker.jsx";
import CategoryPicker from "components/CategoryPicker/CategoryPicker.jsx";
import Invoice from "components/Invoice/Invoice.jsx";
import DatePicker from "components/DatePicker/DatePicker.jsx";
import UserPicker from "components/UserPicker/UserPicker.jsx";

//icons
//import Report from "@material-ui/icons/PieChart";
//import Affiliates from "@material-ui/icons/SupervisedUserCircle";
import Customers from "@material-ui/icons/Group";
import Receipt from "@material-ui/icons/Receipt"
import Loyalty from "@material-ui/icons/Loyalty"
import Filter from "@material-ui/icons/FilterList";
import Add from "@material-ui/icons/Add";
import Save from "@material-ui/icons/Save";
import Expand from "@material-ui/icons/KeyboardArrowDown";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
    dailySalesChart,
    completedTasksChart
} from "variables/charts";
import { TableFooter } from "@material-ui/core";
  
const uuidv4 = require('uuid/v4');
const Chartist = require("chartist");

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
    
    if (aName < bName)
        return -1;
    if (aName > bName)
        return 1;
    return 0;
}

function compareFullName(a,b) {

    var aName = a.label?.toString().toLowerCase();
    var bName = b.label?.toString().toLowerCase();
    
    if (aName < bName)
        return -1;
    if (aName > bName)
        return 1;
    return 0;
}

function compareLabel(a,b) {

    var aName = a.label;
    var bName = b.label;
    
    if (aName < bName)
        return -1;
    if (aName > bName)
        return 1;
    return 0;
}


class ReportingPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            totalOrders: 0,
            totalSales: 0.00,
            totalCommission: 0.00,
            amountSold: {data: {labels: [], series:[] }, highValue: 1000},
            ordersPlaced: {data: {labels: [], series:[] }, highValue: 10},
            customers: [],
            allCustomers: [],
            query: {
                budget: 'all',
                categories: [],
                groupBy: "category",
                locations: 'all',
                users: [],
                id: uuidv4()
            },
            queryResults: [],
            queryTotal: 0.00,
            categories: [],
            products: [],
            budgetOptions: [
                { key: "all", label: "All" },
                { key: "budgeted", label: "Budgeted Only" },
                { key: "nonBudgeted", label: "Non-Budgeted Only" }
            ],
            users: [],
            orders: [],
            invoices: [],
            queries: [],
            selected: "",
            showNewQuery: false,
            queryName: "",
            queryInProgress: false,
            queryChanged: false,
            selectedCustomer: this.props.user.customerID,
            locations: this.props.locations
        };

        var allCustomers = [];
        for(var i = 0; i < this.props.customers.length; i++)
            allCustomers.push({ key: this.props.customers[i].id, label:this.props.customers[i].customer_name});
        this.state.allCustomers = allCustomers;

        var lastWeek = moment().subtract(7,"days");
        var today = moment();
        this.state.startDate = lastWeek.format('MM-DD-YYYY');
        this.state.endDate = today.format('MM-DD-YYYY');
        
        this.addQuery = this.addQuery.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.getQueries = this.getQueries.bind(this);
        this.isCategoryMatch = this.isCategoryMatch.bind(this);
        this.queryOrders = this.queryOrders.bind(this);
        this.refreshReports = this.refreshReports.bind(this);
        this.saveQuery = this.saveQuery.bind(this);
        this.selectCustomer = this.selectCustomer.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
       
    }

    componentDidMount() {

        // get categories for product filtering
        db.getCategories().then(results => {
           
           var categories = [];
           var queryCategories = [];

           for(var i = 0; i < results.length; i++) {
               
                var category = results[i];
                category.key = results[i].id;
                category.label = results[i].Name;
                categories.push(category);
                
                queryCategories.push(results[i].id);
            }

            var query = this.state.query;
            query.categories = queryCategories;

            db.getProductCategories().then(products => {
                this.setState({ categories: categories.sort(compareName), products: products, query: query}, () => { 
                    this.refreshReports();
                    this.getQueries();
                });
            });
        });

        db.logActivity(this.props.user.id, this.props.user.customerID, "View-Reports");
    }

    refreshReports() {
        this.summarizeOrders();
        this.queryInvoices();
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    incrementArray(array, labels, date) {
        for(var i = 0; i < labels.length; i++) {
        if(labels[i].getMonth() === date.getMonth()
            && labels[i].getFullYear() === date.getFullYear()
            && labels[i].getDate() === date.getDate()
        ) {
            array[i] = array[i] + 1;
        }
        }
    }

    addToArrayValue(array, labels, date, value) {
        for(var i = 0; i < labels.length; i++) {
            if(labels[i].getMonth() === date.getMonth()
                && labels[i].getFullYear() === date.getFullYear()
                && labels[i].getDate() === date.getDate()
            ) {
                array[i] = Number(array[i]) + Number(value);
            }
        }
    }

    selectCustomer(id) {
        this.setState({selectedCustomer:id}, () => {
            this.getLocations();
            this.getQueries();
            this.refreshReports();
        });
    }

    summarizeOrders() {

        console.log('summarizeOrders()');
        
        var labels = [];
        var dates = [];
        
        var amountSold = [];
        var ordersPlaced = [];
        var customers = [];

        var newOrders = 0;
        var totalOrders = 0;
        var totalSales = 0.00;
        var totalCommission = 0.00;

        var startDate = new moment(this.state.startDate);
        var endDate = new moment(this.state.endDate);
        var numberOfDays = endDate.diff(startDate, 'days');

        var queryStartDate = new moment(this.state.startDate, "MM-DD-YYYY").valueOf() / 1000;
        var queryEndDate = new moment(this.state.endDate, "MM-DD-YYYY").valueOf() / 1000;

        for(var h = numberOfDays; h > -1; h--) {
            var calculatedDate = this.addDays(endDate, -h);
            if(numberOfDays < 17 || h % 2)
                labels.push(calculatedDate.getMonth() + 1 + "/" + calculatedDate.getDate());
            else 
                labels.push("");

            dates.push(calculatedDate);

            amountSold.push(0);
            ordersPlaced.push(0);
        }
        
        var promises = [];
        for(var i = 0; i < this.props.customers.length; i++) 
            promises.push(db.getOrdersByCustomerDateRange(this.props.user.customers[i].id, queryStartDate, queryEndDate));
        
        Promise.all(promises).then(dbResults => {
            for(var j = 0; j < dbResults.length; j++) {
                
                var customerOrders = 0;
                var customerSales = 0.00;
                var customerCommission = 0.00;
                
                for(var k = 0; k < dbResults[j].length; k++) {

                    var order = dbResults[j][k];

                    if(!order.status)
                        newOrders = newOrders + 1;
        
                    var orderPlaced = new Date(order.orderPlaced);
                    
                    if(order.customerID === this.state.selectedCustomer) {
                        
                        this.addToArrayValue(amountSold, dates, orderPlaced, order.orderTotal);
                        this.incrementArray(ordersPlaced, dates, orderPlaced);

                        totalOrders = Number(totalOrders) + 1;
                        totalSales = Number(totalSales) + Number(order.orderTotal);
                    }

                    customerOrders = Number(customerOrders) + 1;
                    customerSales = Number(customerSales) + Number(order.orderTotal);

                    if(order.commission) {
                        customerCommission = Number(customerCommission) + Number(order.commission);
                        totalCommission = Number(totalCommission) + Number(order.commission);
                    }
                }

                if(dbResults[j].length > 0) {
                    
                    var customerID = 0;
                    var customer = "";

                    for(var l = 0; l < this.props.customers.length; l++) {
                        if(this.props.customers[l].id === dbResults[j][0].customerID) {

                            customerID = this.props.customers[l].id;
                            customer = this.props.customers[l].customer_name;

                            if(dbResults[j][0].customerID === this.state.selectedCustomer) {
                                // actual logged in customer id
                                // not an affiliate or associated customer
                                
                                // get users who have placed orders for use in filter
                                var users = [];
                                var queryUsers = [];

                                // create users w/ orders list 
                                var orders = dbResults[j];

                                // eslint-disable-next-line
                                orders.forEach(order => {
                                    var userID = order.userID;
                                    if(!userID) // hack because Bo's orders are missing an ID
                                        userID = '6pTsX4vV6Iebf1z00w7kwb7qkBs2';
    
                                    var userFound = false;
                                    for(var v = 0; v < users.length; v++) {
                                        if(users[v].key === userID) {
                                            userFound = true;
                                            break;
                                        }
                                    }
                                    if(!userFound) {
                                        users.push({key: userID, label: order.userName});
                                        queryUsers.push(userID);
                                    }
                                })

                                var query = this.state.query;
                                if(this.state.selected.length === 0) {//only change selected users if a query isn't defined
                                    query.users = queryUsers;
                                }

                                // query Users update and orders saved for other page queries
                                this.setState({orders: orders, users:users.sort(compareFullName), query: query});
                            }

                            break;
                        }
                    }
                
                    customers.push( { key: customerID, name: customer, label: customer, orders: customerOrders, sales: customerSales.toFixed(2), commission: customerCommission.toFixed(2) } );
                }
            }

            this.setState({
                newOrders: newOrders,
                numDays: numberOfDays,
                customers: customers,
                totalCommission: totalCommission.toFixed(2),
                totalOrders: totalOrders,
                totalSales: totalSales.toFixed(2),
            });
            
            
            var amountSoldHigh = 0;
            for(var v = 0; v < amountSold.length; v++)
                if(amountSold[v] > amountSoldHigh)
                amountSoldHigh = amountSold[v];

            this.setState({amountSold:        {data: { labels: labels, series: [amountSold] }, highValue: amountSoldHigh * 1.1 }});

            var ordersPlacedHigh = 0;
            for(var w = 0; w < ordersPlaced.length; w++)
                if(ordersPlaced[w] > ordersPlacedHigh)
                ordersPlacedHigh = ordersPlaced[w];

            this.setState({ordersPlaced:        {data: { labels: labels, series: [ordersPlaced] }, highValue: ordersPlacedHigh * 1.1 }});
        });
    }

    updateQuery(property, value) {

        console.log('updateQuery()');

        var query = this.state.query;
        
        if(property === 'budget') 
            query.budget = value;
        else if(property === 'categories') 
            query.categories = value;
        else if(property === 'customers') 
            query.customers = value;
        else if (property === 'groupBy')
            query.groupBy = value;
        else if(property === 'locations') 
            query.locations = value;
        else if (property === 'users')
            query.users = value;

        this.setState({query: query, queryChanged: true}, () => {
            
        });
    }

    queryOrders() {
        console.log('queryOrders()');

        this.setState({queryInProgress: true}, () => {

            var results = this.state.orders;
            var orders = [];

            for(var i = 0; i < results.length; i++) {

                // user filter
                var userMatch = false;
                
                for(var u = 0; u < this.state.query.users.length; u++) {
                    if(results[i].userID === this.state.query.users[u]) {
                        userMatch = true;
                        break;
                    }
                }

                if(userMatch) {
                    
                    // location filter
                    if (this.state.query.locations === 'all') {
                        orders.push(results[i]);
                        
                    } else if(this.state.query.locations.length > 0) { // filter locations
    
                        for(var l = 0; l < this.state.query.locations.length; l++) {
                            
                            if(results[i].shippingAddressID === this.state.query.locations[l]) {
                                orders.push(results[i]);
                                break;
                            }
                        }
                    }
                }
            }

            let invoicePromises = []
            orders.forEach(order => {
                if (order.salesOrderID) {
                    invoicePromises.push(db.getInvoicesBySalesOrderID(order.salesOrderID));
                }
            })

            db.getInvoiceNotes(this.state.selectedCustomer).then(notes => {
                Promise.all(invoicePromises).then(invoiceResults => {
                
                    invoiceResults.forEach(invoiceArr => {
                        invoiceArr.forEach(invoice => {
                            orders.forEach(order => {
                                if (order.salesOrderID === invoice.salesorder_id) {
                                    order.items.forEach(item => {
                                        if (Number(item.total) === invoice.total) {
                                            invoice.discussion = [];
                                            for(var j = 0; j < notes.length; j++) {
                                                if(invoice.id === notes[j].invoiceID) {
                                                    invoice.discussion.push(notes[j]);
                                                }
                                            }
                                            item.invoice = invoice
                                        }
                                    })
                                }
                            })
                        })
                    })
    
                    // loop through orders to show categorized results
                    // grouping and category filters
    
                    var queryResults = [];
                    
    
                    if(this.state.query.groupBy === 'category') {
    
                        this.state.categories.forEach(category => {
    
                            for(var c4 = 0; c4 < this.state.query.categories.length; c4++) {
                                if(this.state.query.categories[c4] === category.key) {
                                    
                                    var total = 0;
                                    var subCategories = [];
    
                                    // eslint-disable-next-line
                                    orders.forEach(order => {
                                        // eslint-disable-next-line
                                        order.items.forEach(item => {
                                            if(this.isBudgetMatch(item)) {
    
                                                var product = this.getProduct(item.id);
                                                
                                                if(this.isCategoryMatch(product, category)) {
                                                    total = Number(total) + Number(item.total);
    
                                                    // need to loop through the subcategores
                                                    // add subcategory details
                                                    
                                                    for(var sc1 = 0; sc1 < category.SubCat1.length; sc1++) {
                                                        if(product.subCat1 === category.SubCat1[sc1].id) {
                                                            var isFound = false;
                                                            for(var sc = 0; sc < subCategories.length; sc++) {
                                                                if(subCategories[sc].id === product.subCat1) {
                                                                    subCategories[sc].total = Number(subCategories[sc].total) + Number(item.total);
                                                                    if (item.invoice) {
                                                                        subCategories[sc].invoices.push(item.invoice)
                                                                    }
                                                                    isFound = true;
                                                                    break;
                                                                }
                                                            }
                                                            if(!isFound) {
                                                                let invoices = []
                                                                if (item.invoice) {
                                                                    invoices.push(item.invoice)
                                                                }
                                                                subCategories.push({
                                                                    id: product.subCat1, 
                                                                    Name: category.SubCat1[sc1].Name, 
                                                                    total: Number(item.total),
                                                                    invoices
                                                                });
                                                            }
                                                        }
                                                    }
                                                
                                                }
                                            }
                                        })
                                    });
    
                                    queryResults.push({ id: category.key, 
                                                        label: category.label, 
                                                        value: Number(total).toFixed(2),
                                                        subCategories: subCategories.sort(compareName)
                                                    });
    
                                    break;
                                }
                            }
                        });
    
                    } else {
    
                        for(var o = 0; o < orders.length; o++) {
    
                            if (this.state.query.groupBy === 'location') {
    
                                var isFound = false;
                                for(var qr = 0; qr < queryResults.length; qr++) { // if a location has been added to results already
                                    if(queryResults[qr].id === orders[o].shippingAddressID) { //this.state.query.locations) {
                                        for(var li2 = 0; li2 < orders[o].items.length; li2++) {
                                            var product = this.getProduct(orders[o].items[li2].id);
                                            if(this.isCategoryMatch(product))
                                                if(this.isBudgetMatch(orders[o].items[li2]))
                                                    queryResults[qr].value = Number(Number(queryResults[qr].value) + Number(orders[o].items[li2].total)).toFixed(2);
                                        }
    
                                        isFound = true;
                                        break;
                                    }
                                }
                                
                                if(!isFound) { // first time a location is added to results
                                    for(var ln = 0; ln < this.props.locations.length; ln++) {
                                        if(this.props.locations[ln].address_id === orders[o].shippingAddressID ) {
    
                                            var orderTotal = 0;
                                            for(var li1 = 0; li1 < orders[o].items.length; li1++) {
                                                if(this.isCategoryMatch(this.getProduct(orders[o].items[li1].id)))
                                                    if(this.isBudgetMatch(orders[o].items[li1]))
                                                        orderTotal = Number(orderTotal) + Number(orders[o].items[li1].total)
                                            }
                                            queryResults.push({ id: this.props.locations[ln].address_id, label: this.props.locations[ln].name + " - " + this.props.locations[ln].address, value: Number(orderTotal).toFixed(2), subCategories:[] });
                                            break;
                                        }
                                    }
                                }
                                    
                            }
                            else if (this.state.query.groupBy === 'user') {
                                // eslint-disable-next-line
                                var isFound = false;
                                // eslint-disable-next-line
                                for(var qr = 0; qr < queryResults.length; qr++) { // if a location has been added to results already
                                    if(queryResults[qr].id === orders[o].userID) { //this.state.query.locations) {
                                        for(var li4 = 0; li4 < orders[o].items.length; li4++) {
                                            if(this.isCategoryMatch(this.getProduct(orders[o].items[li4].id)))
                                                if(this.isBudgetMatch(orders[o].items[li4]))
                                                    queryResults[qr].value = Number(Number(queryResults[qr].value) + Number(orders[o].items[li4].total)).toFixed(2);
                                        }
                                        isFound = true;
                                        break;
                                    }
                                }
                                
                                if(!isFound) { // first time a user is added to results
                                    // eslint-disable-next-line
                                    var orderTotal = 0;
                                    for(var li5 = 0; li5 < orders[o].items.length; li5++) {
                                        if(this.isCategoryMatch(this.getProduct(orders[o].items[li5].id)))
                                            if(this.isBudgetMatch(orders[o].items[li5]))
                                                orderTotal = Number(orderTotal) + Number(orders[o].items[li5].total)
                                    }
                                    queryResults.push({ id: orders[o].userID, label: orders[o].userName, value: Number(orderTotal).toFixed(2), subCategories:[] });                            
                                }
                            }
                        }
    
                    }
    
                    var queryTotal = 0.00;
                    queryResults.forEach(result => {
                        queryTotal = queryTotal + Number(result.value);
                    });
    
                    queryResults.sort(compareLabel);
    
                    this.setState({queryResults: queryResults, queryTotal: queryTotal, queryInProgress: false});
    
                })  
            })
            
        })
    }

    queryInvoices() {

        var queryStartDate = new moment(this.state.startDate, "MM-DD-YYYY").format('YYYY-MM-DD');
        var queryEndDate = new moment(this.state.endDate, "MM-DD-YYYY").format('YYYY-MM-DD');

        db.getInvoicesByCustomerAndDateRange(this.state.selectedCustomer, queryStartDate, queryEndDate).then(invoices => {

            this.setState({invoices:invoices});

        });
    }

    getProduct(productID) {
        for(var j = 0; j < this.state.products.length; j++) {
            if(this.state.products[j].id === productID) {
                return this.state.products[j];
            }
        }
    }

    isCategoryMatch(product, category) {
        
        if(this.state.query.categories.length === 0) {// if there is no category filter
            return true;
        } 
        else { // if it matches any of the category of subcategories

            for(var i = 0; i < this.state.query.categories.length; i++) { // top level categories
                
                if(!category) { // when not grouping by category any category match is okay
                    
                    if(product.categoryID === this.state.query.categories[i]
                        || (product.subCat1 === this.state.query.categories[i]
                        ||  product.subCat2 === this.state.query.categories[i]
                        ||  product.subCat3 === this.state.query.categories[i]) )
                         {
                             
                        return true;
                    }  
                } else {

                    if(product.categoryID === category.id 
                        && (product.subCat1 === this.state.query.categories[i]
                        ||  product.subCat2 === this.state.query.categories[i]
                        ||  product.subCat3 === this.state.query.categories[i]) )
                        {

                        return true;
                    }   
                }
            }
        }

        return false;
    }

    isBudgetMatch(lineItem) {
        if(this.state.query.budget === 'all') {
            return true;
        }
        else if (this.state.query.budget === 'budgeted') {
            for(var i = 0; i < this.props.budgetExceptions.length; i++) {
                if(this.props.budgetExceptions[i].productID === lineItem.id) {
                    return false;
                }
            }
            return true;
        } 
        else if (this.state.query.budget === 'nonBudgeted') {
            for(var j = 0; j < this.props.budgetExceptions.length; j++) {
                if(this.props.budgetExceptions[j].productID === lineItem.id) {
                    return true;
                }
            }
            return false;
        }
    }

    
    getLocations() {
    
        db.getShipToAddressesByCustomer(this.state.selectedCustomer).then(result => {
    
            var filtered = [];
            var addresses = [];

            if(result)
                addresses = result;
            
            for(var i = 0; i < addresses.length; i++) {
            
                filtered.push(addresses[i]);
            }
        
            filtered.sort(this.sortByLabel);
        
            this.setState({locations: filtered});
        })
        
    }
    

    getQueries() {
        
        db.getQueriesByCustomer(this.state.selectedCustomer).then(queries => {

            for(var i = 0; i < queries.length; i++) {
                queries[i].key = queries[i].id;
                queries[i].label = queries[i].name;
            }
            queries.sort(compareLabel);

            if(queries.length === 0) { 
                var query = {
                    budget: 'all',
                    categories: [],
                    groupBy: "category",
                    locations: 'all',
                    users: [],
                    name: "New Report",
                    id: uuidv4()
                };

                query.key = query.id;
                query.label = query.name;

                queries.push(query);
            }

            this.setState({queries: queries}, () => {
                this.selectQuery(queries[0].id);
            });
        })
    }

    selectQuery(id) {
    

        var query;
        for(var i = 0; i < this.state.queries.length; i++) {
            if(this.state.queries[i].id === id) {
                query = this.state.queries[i];
                break;
            }
        }
        console.log(query);

        this.setState({query: query, queryName: query.name, selected: id}, () => {
            this.queryOrders();
        });
    }

    deleteQuery() {
      
        db.deleteQuery(this.state.query).then(() => {
          var queries = this.state.queries;
          for(var i = 0; i < queries.length; i++) {
              if(queries[i].id === this.state.query.id) {
                  queries.splice(i, 1);
    
                  this.setState({query: {}, queries: queries});
                  break;
              }
          }
        });
    }

    showAddQuery() {
        if(this.state.showNewQuery)// || this.state.queries.length > 0)
          return "none";
      else
          return "inline-block";
    }

    addQuery() {
        
        console.log('addQuery()')
        var categories = [];
        for(var i = 0; i < this.state.categories.length; i++)
            categories.push(this.state.categories[i].key);

        var users = [];
        for(var j = 0; j < this.state.users.length; j++)
            users.push(this.state.users[j].key);

        var queries = this.state.queries;

        var query = {
            budget: 'all',
            categories: categories,
            groupBy: "category",
            locations: 'all',
            users: users,
            id: uuidv4()
        };

        query.name = 'New Report';
        query.key = query.id;
        query.label = query.name;

        queries.push(query);

        this.setState({queries:queries, query: query, selected: query.id, queryName: query.name}, this.queryOrders);
    }

    showNewQuery() {
        if(this.state.showNewQuery) 
            return "inline-block";
        else
            return "none";
    }

    saveQuery() {

        if(this.state.queryName === 'New Report') // if no name, prompt for one before saving
            this.setState({showNewQuery: true});
        else 
        {
            var query = this.state.query;
            if(!query.id)
                query.id = uuidv4();
            query.name = this.state.queryName;
            query.label = this.state.queryName;
            query.customerID = this.state.selectedCustomer;

            db.updateQuery(query.id, query).then(() => {
    
                var queries = this.state.queries;
                queries.sort(compareLabel);
    
                this.setState({showNewQuery: false, query: query, queries: queries});
            });
        }
    }

    showQueriesCombo() {
        if(!this.state.showNewQuery)
            return "inline-block";
        else
            return "none";
    }

    showSaveQuery() {
        if(this.state.showNewQuery || this.state.queryChanged || this.state.queryName === 'New Report') 
            return "inline-block";
        else
            return "none";
    }
    
    showDeleteQuery() {
        if(this.state.queries.length > 0 && !this.state.showNewQuery && this.state.queryName !== 'New Report') 
          return "inline-block";
      else
          return "none";
    }

    showCommission() {
        if(this.props.user.userType === 'admin' || this.props.user.userType === 'Affiliate' )
            return "table-cell";
        else
            return "none";
    }

    getAlternatingBackground(index) {
        if(index % 2 === 0) {
            return "#efefef";
        } else {
            return "#ffffff"
        }
    }

    render() {
        const { classes } = this.props;

        if (!this.props.user) {
            return <Redirect to='/login' />
        } else if (!this.props.user.authenticated) {
            return <Redirect to='/login' />
        }
    
        return (
          <div>
            <Header
              brand="Ecclesia"
              links={<HeaderLinks dropdownHoverColor="info" />}
              fixed
              color="dark"
            />
            <div className={classes.container} style={{minHeight:"369px", marginTop:"123px"}}>
                <GridContainer>
                    <GridItem xs={12} sm={3} md={6}>
                        <h3 className={classes.cardTitle}>Reports</h3>
                    </GridItem>
                    <GridItem xs={4} sm={3} md={2} style={{marginTop:"-10px", marginBottom:"20px"}}>
                        <div>{/* TODO, hide based on role */}
                        <CustomSelect
                            labelText="Customer"
                            value={this.state.selectedCustomer}
                            inputProps={{
                                placeholder: "Customer"
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.selectCustomer(e.target.value)}
                            options={this.state.allCustomers}
                        />
                        </div>
                    </GridItem>
                    <GridItem xs={4} sm={3} md={2} style={{marginTop:"-10px", marginBottom:"20px"}}>
                        <DatePicker 
                            labelText="Start Date"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            inputProps={{
                                placeholder: "Start Date"
                            }}
                            value={this.state.startDate}
                            viewDate={this.state.startDate}
                            onChange={(selectedDate) => this.setState({startDate: selectedDate.format("MM/DD/YYYY")}, () => {
                                this.refreshReports();
                            })}
                        />
                    </GridItem>
                    <GridItem xs={4} sm={3} md={2} style={{marginTop:"-10px", marginBottom:"20px"}}>
                        <DatePicker 
                            labelText="End Date"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            inputProps={{
                                placeholder: "End Date"
                            }}
                            value={this.state.endDate}
                            viewDate={this.state.endDate}
                            onChange={(selectedDate) => this.setState({endDate: selectedDate.format("MM/DD/YYYY")}, () => {
                                this.refreshReports();
                            })}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card >
                            <CardHeader color="info">
                                <ChartistGraph
                                    className="ct-chart"
                                    data={this.state.ordersPlaced.data}
                                    type="Line"
                                    options={{
                                        lineSmooth: Chartist.Interpolation.cardinal({ tension: 0 }),
                                        low: 0,
                                        high: this.state.ordersPlaced.highValue, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                                        chartPadding: {
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                        }
                                    }}
                                    listener={completedTasksChart.animation}
                                />
                            </CardHeader>
                            <CardBody>
                                <h4 className={classes.cardTitle}>{numberWithCommas(this.state.totalOrders)}</h4>
                                <p className={classes.cardCategory}>
                                Total Orders
                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                        <Card >
                            <CardHeader color="success">
                                <ChartistGraph
                                    className="ct-chart"
                                    data={this.state.amountSold.data}
                                    type="Line"
                                    options={{
                                        lineSmooth: Chartist.Interpolation.cardinal({ tension: 0 }),
                                        low: 0,
                                        high: this.state.amountSold.highValue, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                                        chartPadding: {
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0
                                        }
                                    }}
                                    listener={dailySalesChart.animation}
                                />
                            </CardHeader>
                            <CardBody>
                                <h4 className={classes.cardTitle}>${numberWithCommas(this.state.totalSales)}</h4>
                                <p className={classes.cardCategory}>
                                Total Sales
                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomTabs
                        headerColor="success"
                        tabs={[
                            {
                                tabName: "Customers",
                                tabIcon: Customers,
                                tabContent: (
                                    <GridContainer>
                                        <GridItem xs={12}>           
                                          <Table>
                                              <TableHead>
                                                  <TableRow>
                                                      <TableCell>
                                                          Name
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right"}}>
                                                          Orders
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right"}}>
                                                          Sales
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right", backgroundColor:"#efefef", display:this.showCommission()}}>
                                                          Commission
                                                      </TableCell>
                                                  </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {this.state.customers.map((customer,index) => (
                                                <TableRow key={index}>
                                                      <TableCell>
                                                        {customer.name}
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right"}}>
                                                        {customer.orders}
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right"}}>
                                                        ${numberWithCommas(customer.sales)}
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right", backgroundColor:"#efefef", display:this.showCommission()}}>
                                                        ${numberWithCommas(customer.commission)}
                                                      </TableCell>
                                                  </TableRow>
                                                ))}
                                              </TableBody>
                                              <TableFooter>
                                                <TableRow >
                                                      <TableCell>
                                                          
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right"}}>
                                                        
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right"}}>
                                                        
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right", color:"green", backgroundColor:"#efefef", display:this.showCommission()}}>
                                                        ${numberWithCommas(this.state.totalCommission)}
                                                      </TableCell>
                                                  </TableRow>
                                              </TableFooter>
                                          </Table>
                                        </GridItem>
                                        <GridItem xs={12} >
                                            <div style={{marginLeft:"10px", marginTop:"10px"}}>
                                            
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                )
                            },
                            {
                                tabName: "Purchases",
                                tabIcon: Receipt,
                                tabContent: (
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <GridContainer style={{width:"100%"}}>
                                                <GridItem xs={4} style={{display:this.showQueriesCombo()}}>
                                                    <CustomSelect
                                                        labelText="Custom Report"
                                                        value={this.state.selected}
                                                        inputProps={{
                                                            placeholder: "Custom Report"
                                                        }}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        onChange={(e) => this.selectQuery(e.target.value)}
                                                        options={this.state.queries}
                                                    />
                                                </GridItem>
                                                <GridItem xs={4} style={{display:this.showNewQuery()}}>
                                                    <div style={{marginTop:"-17"}}>
                                                        <CustomInput
                                                            labelText="Custom Report Name"
                                                            value={this.state.queryName}
                                                            inputProps={{
                                                                placeholder: "Give it a name ..."
                                                            }}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            onChange={(e) => this.setState({queryName: e.target.value})}
                                                        />
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={4} >
                                                    <div style={{display:this.showAddQuery(), marginTop:"17px"}}>
                                                        <Tooltip id="tooltip-top-start" title="Add a New Custom Report" placement="top" classes={{ tooltip: classes.tooltip }} >
                                                            <IconButton onClick={this.addQuery}><Add /></IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{display:this.showDeleteQuery(), marginTop:"17px"}}>
                                                        <Tooltip id="tooltip-top-start" title="Delete Custom Report" placement="top" classes={{ tooltip: classes.tooltip }}  >
                                                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this custom report? This action cannot be undone." confirmed={() => this.deleteQuery()} />
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{display:this.showSaveQuery(), marginTop:"17px"}}>
                                                        <Tooltip id="tooltip-top-start" title="Save Custom Report" placement="top" classes={{ tooltip: classes.tooltip }}  >
                                                            <IconButton onClick={this.saveQuery}><Save /></IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    
                                                </GridItem>
                                                <GridItem xs={4} style={{textAlign:"right"}}>
                                                    <Button color="success" onClick={this.queryOrders}><Filter />REFRESH REPORT</Button>        
                                                </GridItem>
                                            </GridContainer>
                                        
                                            <ExpansionPanel style={{marginTop:"17px", marginBottom:"17px", backgroundColor:"#efefef"}} >                                                
                                                <ExpansionPanelSummary >
                                                    Report Filters <Expand />
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails >
                                                    <GridContainer>
                                                        <GridItem xs={12}>
                                                            <AddressPicker locations={this.state.locations} selected={this.state.query.locations} onChange={(e) => this.updateQuery("locations",e)} />
                                                        </GridItem>
                                                        <GridItem xs={12} >
                                                            <CategoryPicker categories={this.state.categories} selected={this.state.query.categories} onChange={(e) => this.updateQuery("categories",e)} />
                                                        </GridItem>
                                                        <GridItem xs={12}>
                                                            <UserPicker users={this.state.users} selected={this.state.query.users} onChange={(e) => this.updateQuery("users",e)} />    
                                                        </GridItem>
                                                        <GridItem xs={12}>
                                                            <CustomSelect
                                                                labelText="Budget"
                                                                inputProps={{ placeholder: "Budget" }}
                                                                formControlProps={{ fullWidth: true }}
                                                                onChange={(e) => this.updateQuery("budget",e.target.value)}
                                                                options={this.state.budgetOptions}
                                                                value={this.state.query.budget}
                                                                placeholder="all"
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={12}>
                                                            <CustomSelect
                                                                labelText="Group Results By"
                                                                inputProps={{ placeholder: "Group Results" }}
                                                                formControlProps={{ fullWidth: true }}
                                                                onChange={(e) => this.updateQuery("groupBy",e.target.value)}
                                                                options={[
                                                                    {key:"category", label:"Category"},
                                                                    {key:"location", label:"Location"},
                                                                    {key:"user", label:"User"},
                                                                ]}
                                                                value={this.state.query.groupBy}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </GridItem>
                                        
                                        <GridItem xs={12}>
                                            
                                            <GridContainer style={{padding:"3px", fontSize:"13px", backgroundColor:"#ffffff"}}>
                                                <GridItem xs={9}>
                                                    Category
                                                </GridItem>
                                                <GridItem xs={3} style={{textAlign:"right", fontSize:"13px", paddingRight:"20px"}}>
                                                    Total
                                                </GridItem>
                                                <GridItem xs={12}>
                                                    <hr />
                                                </GridItem>
                                            </GridContainer>

                                            <TreeView className={classes.root}
                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                defaultExpandIcon={<ChevronRightIcon />}>
                                                {this.state.queryResults.map((result,index) => (
                                                    <TreeItem nodeId={result.id} key={index}
                                                        style={{marginBottom:"13px"}}
                                                        label={
                                                            <GridContainer style={{marginLeft:"1px", padding:"3px", fontSize:"14px", borderBottom:"0.5px solid #efefef", backgroundColor:"#ffffff"}}>
                                                                <GridItem xs={9}>
                                                                    {result.label}
                                                                </GridItem>
                                                                <GridItem xs={3} style={{textAlign:"right", fontSize:"14px"}}>
                                                                    <div style={{marginRight:"10px"}}>
                                                                        ${numberWithCommas(result.value)}
                                                                    </div>
                                                                </GridItem>
                                                            </GridContainer>
                                                        } 
                                                    >
                                                        {result.subCategories.map((subCategory,index) => (
                                                            <TreeItem nodeId={subCategory.id} key={index}
                                                                label={
                                                                    <GridContainer style={{padding:"3px", color:"#545454", paddingLeft:"30px", fontSize:"14px", borderBottom:"0.5px solid #efefef"}}>
                                                                        <GridItem xs={9} >
                                                                            {subCategory.Name}
                                                                        </GridItem>
                                                                        <GridItem xs={3} style={{textAlign:"right"}}>
                                                                            <div style={{marginRight:"10px"}}>
                                                                                ${numberWithCommas(subCategory.total.toFixed(2))}
                                                                            </div>
                                                                        </GridItem>
                                                                    </GridContainer> 
                                                                } 
                                                            >
                                                                {subCategory.invoices.map((invoice,index) => (
                                                                    <TreeItem nodeId={invoice.invoice_id} 
                                                                        label={
                                                                            <GridContainer style={{padding:"3px", color:"#545454", paddingLeft:"30px", fontSize:"14px", borderBottom:"0.5px solid #efefef"}}>
                                                                                <GridItem xs={4}>
                                                                                    {invoice.date}
                                                                                </GridItem>
                                                                                <GridItem xs={4}>
                                                                                    <Invoice invoice={invoice} link hideIcon />
                                                                                </GridItem>
                                                                                <GridItem xs={4} style={{textAlign:"right"}}>
                                                                                    <div style={{marginRight:"10px"}}>
                                                                                        ${numberWithCommas(invoice.total.toFixed(2))}
                                                                                    </div>
                                                                                </GridItem>
                                                                            </GridContainer> 
                                                                        } 
                                                                    />
                                                                ))}
                                                            </TreeItem>
                                                        ))}
                                                    </TreeItem>
                                                ))}
                                            </TreeView>
                                        </GridItem>

                                        <GridItem xs={12} style={{display:"none"}}>
                                            <Table>
                                              <TableHead>
                                                  <TableRow>
                                                      <TableCell>
                                                          Name
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right"}}>
                                                          Total
                                                      </TableCell>
                                                  </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {this.state.queryResults.map((result,index) => (
                                                    
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {result.label}
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"right"}}>
                                                        ${numberWithCommas(result.value)}
                                                    </TableCell>
                                                </TableRow>
                                                ))}
                                              </TableBody>
                                              <TableFooter>
                                                <TableRow >
                                                      <TableCell>
                                                          
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right", color:"green"}}>
                                                        ${numberWithCommas(this.state.queryTotal)}
                                                      </TableCell>
                                                  </TableRow>
                                              </TableFooter>
                                            </Table>
                                        </GridItem>
                                    </GridContainer>
                                )
                            },
                            {
                                tabName: "Rebates",
                                tabIcon: Loyalty,
                                tabContent: (
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            {/*<AddressPicker selected={[]} />*/}
                                        </GridItem>
                                        <GridItem xs={6}>

                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Table>
                                              <TableHead>
                                                  <TableRow>
                                                      <TableCell>
                                                          Number
                                                      </TableCell>
                                                      <TableCell>
                                                          Due Date
                                                      </TableCell>
                                                      <TableCell>
                                                          Payment Date
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right"}}>
                                                          Total
                                                      </TableCell>
                                                      <TableCell style={{textAlign:"right"}}>
                                                          Rebate
                                                      </TableCell>
                                                  </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {this.state.invoices.map((invoice,index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {invoice.invoice_number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {invoice.due_date}
                                                    </TableCell>
                                                    <TableCell>
                                                        {invoice.last_payment_date}
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"right"}}>
                                                        ${numberWithCommas(invoice.total.toFixed(2))}
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"right", color:"gray"}}>
                                                        coming soon
                                                    </TableCell>
                                                </TableRow>
                                                ))}
                                              </TableBody>
                                            </Table>
                                        </GridItem>
                                    </GridContainer>
                                )
                            }
                        ]}
                    />
                    </GridItem>
                </GridContainer>
            </div>
            <Footer
                content={
                    <div>
                    <div className={classes.right} style={{fontSize:"13px"}}>
                        &copy; {1900 + new Date().getYear()} Ecclesia, Inc.
                    </div>
                    </div>
                }
            />
          </div>
        );
    }
}

const mapStateToProps = state => {
 return {budgetExceptions: state.budgetExceptions, 
    customers: state.customers, 
    locations: state.locations, 
    user: state.user };
 //return state;
}

ReportingPage = connect(mapStateToProps)(ReportingPage);
export default withStyles(shoppingCartStyle, dashboardStyle)(ReportingPage);


// TODO
/*

- Update order queries to use created
- Update the DB to have a created field for every order
- Why does typing a name take so long

*/
