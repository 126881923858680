//react
import React from "react";

//firebase
import { db } from 'assets/firebase';

//router
import { Redirect } from "react-router-dom";

//redux
import { connect } from 'react-redux';

//html
import Parser from 'html-react-parser';

// styles
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.jsx";

//components
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

//spiffy
import ProductDetails from "components/ProductDetails/ProductDetails.jsx";


class SpecialsPage extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
        opacity: 0,
        searchQuery: "",
        special: this.props.special
      };
      
      this.searchProducts = this.searchProducts.bind(this);
      this.resetQuery = this.resetQuery.bind(this);
  
      db.logActivity(this.props.user.id, this.props.user.customerID, "View-Specials");
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    searchProducts(query) {
        this.setState({searchQuery: query});
    }

    resetQuery() {
        this.setState({searchQuery: ""});
    }

    htmlContent(content) {
      if (content) return Parser(content);
      else return '';
    }

    render() {
        const { classes } = this.props;
    
        if (!this.props.user) {
          return <Redirect to='/login' />
        } else if (!this.props.user.orderingPermissions && this.props.user.userType !== 'admin' && this.props.user.userType !== 'Affiliate') {
          return <Redirect to='/login' />
        } 
    
        return (
          <div>
            <Header
              brand="Ecclesia"
              links={<HeaderLinks 
                        dropdownHoverColor="info" 
                        onSearch={this.searchProducts} 
                        searchQuery={this.state.searchQuery} />}
              color="dark"
            />
    
            <div className={classes.container} >
                <GridContainer>
                    <GridItem xs={12} sm={12} style={{paddingTop:"20px"}}>
                      <h3 className={classes.title}>{this.state.special.name}</h3>
                    </GridItem>
                    <GridItem xs={12}>
                      {this.htmlContent(this.state.special.content)}
                    </GridItem>
                </GridContainer>
                <GridContainer style={{paddingBottom:"50px"}}>
                {
                    this.state.special.products.map((product)=>(
                        <ProductDetails product={product} key={product.id} narrow />
                    ))
                }
                </GridContainer>
            </div>
            
            <Footer
              theme="dark"
              content={
                <div>
                  <div className={classes.right} style={{fontSize:"12px"}}>
                    Copyright &copy; {1900 + new Date().getYear()}{" "}
                    <a
                      href="https://www.eccjan.com"
                      className={classes.aClasses}
                    >
                      Ecclesia
                    </a>
                  </div>
                </div>
              }
            >
             
            </Footer>
          </div>
        );
    }
}
    
const mapStateToProps = state => {
    return state;
}



SpecialsPage = connect(mapStateToProps)(SpecialsPage);
export default withStyles(styles)(SpecialsPage);
    
    