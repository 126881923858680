//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//styles
import { withStyles } from '@material-ui/core/styles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.jsx';

//core
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

//components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

//icons
import Filter from '@material-ui/icons/FilterList';
import Check from '@material-ui/icons/Check';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class SubcategoryPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      selectedText: '',
    };

    var options = [];
    var selected = []; /*
      for(var i = 0; i < this.props.category.SubCat1.length; i++) {
        options.push({key: this.props.category.SubCat1[i].id, label: this.props.category.SubCat1[i].Name});
        selected.push(this.props.category.SubCat1[i].id);
      }*/
    this.state.options = options;
    this.state.selected = selected;

    this.filterResults = this.filterResults.bind(this);
    this.setSelectedText = this.setSelectedText.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
  }

  componentDidMount() {
    this.loadSelected();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.loadSelected();
    }
  }

  // dialog open/close
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadSelected() {
    var selected = [];
    for (var j = 0; j < this.props.selected.length; j++)
      selected.push(this.props.selected[j]);
    this.setState({ selected: selected }, this.setSelectedText);
  }

  toggleCategory(value) {
    const checked = this.state.selected;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ selected: newChecked, group: '' }, () => {
      this.setSelectedText();
    });
  }

  toggleAll(index) {
    var subcat = this.props.category.SubCat1;
    if (index === 2) subcat = this.props.category.SubCat2;
    else if (index === 3) subcat = this.props.category.SubCat3;

    var selected = this.state.selected;

    // are there any selected in this subcategory
    var existingSelections = false;
    subcat.forEach((subcategory) => {
      for (var i = 0; i < selected.length; i++) {
        if (selected[i] === subcategory.id) {
          existingSelections = true;
          break;
        }
      }
    });

    if (!existingSelections) {
      // add them all
      subcat.forEach((subcategory) => {
        selected.push(subcategory.id);
      });
    } else {
      // remove any from this subcategory

      subcat.forEach((subcategory) => {
        for (var k = 0; k < selected.length; k++) {
          if (subcategory.id === selected[k]) {
            selected.splice(k, 1);
            break;
          }
        }
      });
    }

    this.setState({ selected: selected }, () => {
      this.setSelectedText();
    });
  }

  filterResults() {
    this.props.onChange(this.state.selected);
    this.handleClose('modal');
  }

  setSelectedText() {
    var selectedText = '';
    var allSelected = true;
    let selected = this.state.selected;

    this.props.category.SubCat1.forEach((subCategory) => {
      var isSelected = false;
      for (var i = 0; i < selected.length; i++) {
        if (subCategory.id === selected[i]) {
          isSelected = true;
          selectedText = selectedText + subCategory.Name + ', ';
          break;
        }
      }

      if (!isSelected) allSelected = false;
    });

    this.props.category.SubCat2.forEach((subCategory) => {
      var isSelected = false;
      for (var i = 0; i < selected.length; i++) {
        if (subCategory.id === selected[i]) {
          isSelected = true;
          selectedText = selectedText + subCategory.Name + ', ';
          break;
        }
      }

      if (!isSelected) allSelected = false;
    });

    this.props.category.SubCat3.forEach((subCategory) => {
      var isSelected = false;
      for (var i = 0; i < selected.length; i++) {
        if (subCategory.id === selected[i]) {
          isSelected = true;
          selectedText = selectedText + subCategory.Name + ', ';
          break;
        }
      }

      if (!isSelected) allSelected = false;
    });

    if (allSelected) selectedText = 'all';
    else if (selectedText.length > 0)
      // remove trailing comma
      selectedText = selectedText.substr(0, selectedText.length - 2);

    this.setState({ selectedText: selectedText });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={1}>
            <IconButton onClick={() => this.handleClickOpen('modal')}>
              <Filter />
            </IconButton>
          </GridItem>
          <GridItem xs={11}>
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              {this.state.selectedText}
            </div>
          </GridItem>
        </GridContainer>
        <Dialog
          classes={{ root: classes.center, paper: classes.modal }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth={'md'}
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={12}>
                <h4 className={classes.modalTitle}>
                  {this.props.category.Name}
                </h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            style={{ backgroundColor: '#efefef', paddingBottom: '0px' }}
          >
            <Card>
              <CardBody style={{ overflow: 'scroll', maxHeight: '400px' }}>
                <GridContainer>
                  <GridItem xs={4}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            <IconButton onClick={() => this.toggleAll(1)}>
                              <Check style={{ color: 'green' }} />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            Subcategory
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.category.SubCat1.map((category, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ padding: '3px' }}>
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => this.toggleCategory(category.id)}
                                checked={
                                  this.state.selected.indexOf(category.id) !==
                                  -1
                                    ? true
                                    : false
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {category.Name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </GridItem>
                  <GridItem xs={4}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            <IconButton onClick={() => this.toggleAll(2)}>
                              <Check style={{ color: 'green' }} />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            Subcategory
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.category.SubCat2.map((category, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ padding: '3px' }}>
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => this.toggleCategory(category.id)}
                                checked={
                                  this.state.selected.indexOf(category.id) !==
                                  -1
                                    ? true
                                    : false
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {category.Name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </GridItem>
                  <GridItem xs={4}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            <IconButton onClick={() => this.toggleAll(3)}>
                              <Check style={{ color: 'green' }} />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            Subcategory
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.category.SubCat3.map((category, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ padding: '3px' }}>
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => this.toggleCategory(category.id)}
                                checked={
                                  this.state.selected.indexOf(category.id) !==
                                  -1
                                    ? true
                                    : false
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {category.Name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </DialogContent>
          <DialogActions
            style={{
              backgroundColor: '#efefef',
              paddingLeft: '17px',
              paddingTop: '-20px',
              paddingRight: '17px',
              paddingBottom: '17px',
              margin: '0px',
            }}
          >
            <GridContainer style={{ width: '100%' }}>
              <GridItem xs={12} style={{ textAlign: 'right' }}>
                <Button color="success" onClick={this.filterResults}>
                  <Filter />
                  OK
                </Button>
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

SubcategoryPicker = connect(mapStateToProps)(SubcategoryPicker);
export default withStyles(modalStyle)(SubcategoryPicker);
