import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { auth } from 'assets/firebase';

// Material UI
import {
  withStyles
} from '@material-ui/core';

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";

// Components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

// import Button from 'components/CustomButtons/Button.jsx';

import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
import Person from '@material-ui/icons/Person';

class Settings extends Component {

  constructor(){
    super();
    this.state = {
      password: '',
      passwordRepeat: '',
      message: '',
      error: '',
    }
  }

  handleUpdate = () => {
    console.log('updating');

    console.log(auth)
    auth.updatePassword(this.state.password)
    .then(()=>{
      this.setState({
        message: 'Password has been updated!'
      }, ()=>{
        setTimeout(()=>{
          this.setState({message: ''})
        }, 5000)
      })
    })
    .catch((err)=>{
      console.error(err)
      if(err.code === 'auth/requires-recent-login') {
        this.setState({
          error: err.message
        })
      }
    })
  }

  render() {

    const { classes } = this.props;

    if (!this.props.user) {
      return <Redirect to='/login' />
    } else if (!this.props.user.authenticated) {
        return <Redirect to='/login' />
    }

    return (
      <div className={classes.pageHeader}>
         <Header
          brand="Ecclesia"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="dark"
        />
        <div className={classes.container} >
          <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={6} >
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form} >
                    <CardHeader color="success" className={classes.cardHeader}>
                      <h4>Settings</h4>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={6}>
                          <CustomInput
                            labelText="First Name..."
                            formControlProps={{
                              fullWidth: true
                            }}
                            disabled
                            value={this.props.user.firstName}
                            inputProps={{
                              type: "text",
                              
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Person className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                      </GridItem>
                        <GridItem xs={6}>
                          <CustomInput
                            labelText="Last Name..."
                            formControlProps={{
                              fullWidth: true
                            }}
                            disabled
                            value={this.props.user.lastName}
                            inputProps={{
                              type: "text",
                              
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Person className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <CustomInput
                            labelText="Email..."
                            formControlProps={{
                              fullWidth: true
                            }}
                            disabled
                            value={this.props.user.email}
                            inputProps={{
                              type: "email",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Email className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6}>
                          <CustomInput
                            labelText="Password"
                            id="pass1"
                            formControlProps={{
                              fullWidth: true
                            }}
                            onChange={(event)=>this.setState({password: event.target.value})}
                            inputProps={{
                              type: "password",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LockOutline
                                    className={classes.inputIconsColor}
                                  />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6}>
                          <CustomInput
                            labelText="Verify Password"
                            id="pass2"
                            formControlProps={{
                              fullWidth: true
                            }}
                            onChange={(event)=>this.setState({passwordRepeat: event.target.value})}
                            inputProps={{
                              type: "password",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LockOutline
                                    className={classes.inputIconsColor}
                                  />
                                </InputAdornment>
                              )
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <CardFooter className={classes.cardFooter} >
                      <GridContainer style={{width:"100%"}}>
                        <GridItem xs={12} >
                          <Button color="success" fullWidth onClick={this.handleUpdate} disabled={this.state.password.length < 8 || this.state.password !== this.state.passwordRepeat}>
                            Update
                          </Button>
                        </GridItem>
                        <GridItem xs={12} style={{textAlign:"center"}}>
                          <br />
                          {this.state.error}
                          {this.state.message}
                          <br />
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
        </div>
      </div>
    )
  }
}

/* const mapStateToProps = (state) => ({
  user: state.user,
  classes: state.classes,
}) */
const mapStateToProps = (state) => state

export default withStyles(loginPageStyle)(connect(mapStateToProps)(Settings))