import React from 'react';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

//components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import Button from "components/CustomButtons/Button.jsx";

//icons
import Notes from "@material-ui/icons/SpeakerNotes";

function getDate(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    return month + ' ' + date + ', ' + year;
}
  
function getTime(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var hours = a.getHours();
    var minutes = a.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

class InvoiceNotes extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getBackgroundColor(index) {
    if(index % 2 === 0) 
        return "#efefef";
    else
        return "#ffffff"
  }

  render() {
    return (
      <div>
        <IconButton onClick={() => this.handleClickOpen("modal")}>
            <Notes />
        </IconButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
              Invoice Notes
            </DialogTitle>
          <DialogContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ paddingRight:"6px", width:"123px"}}>
                            Date
                        </TableCell>
                        <TableCell style={{paddingRight:"6px", width:"89px" }}>
                            Invoice    
                        </TableCell>
                        <TableCell style={{paddingRight:"6px" }}>
                            Note
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.notes.map((note,index) => (
                        <TableRow key={index} style={{backgroundColor:this.getBackgroundColor(index)}}>
                            
                            <TableCell style={{paddingRight:"6px", color:"gray", fontSize:"12px", lineHeight:"15px", width:"123px"}}>
                                {getDate(note.created)}<br />
                                {getTime(note.created)}
                            </TableCell>
                            <TableCell style={{verticalAlign:"top", paddingRight:"6px", width:"89px", color:"gray", fontSize:"12px" }}>
                                {note.invoiceNumber}
                            </TableCell>
                            <TableCell style={{paddingRight:"6px", lineHeight:"18px", fontSize:"13px" }}>
                                <div style={{color:"green", fontSize:"12px"}}>{note.author}</div>
                                {note.body}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="success">OK</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(modalStyle)(InvoiceNotes);