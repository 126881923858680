//react
import React from 'react';

//router
import { Redirect } from 'react-router-dom';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CartCleared } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

//stripe
import { CardElement, injectStripe } from 'react-stripe-elements';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.jsx';

//core
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Card from 'components/Card/Card.jsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import CircularProgress from '@material-ui/core/CircularProgress';

//components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
  return time;
}

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      order: { tax: 0.0, orderTotal: 0.0, items: [] },
      orderReviewed: false,
      orderProcessing: false,
      orderCompleted: false,
      acknowledged: false,
      tax: 0.0,
      customer: {},
      shipToAddress: {},
    };

    this.openSummary = this.openSummary.bind(this);
    this.getPayment = this.getPayment.bind(this);
    this.goBack = this.goBack.bind(this);
    this.createStripeCharge = this.createStripeCharge.bind(this);
    this.checkOut = this.checkOut.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.sendNotifications = this.sendNotifications.bind(this);

    this.getCustomer(() => {
      // make sure we have state variables necessary
    });
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.shipTo !== this.props.shipTo ||
      prevProps.tax !== this.props.tax ||
      prevProps.cart !== this.props.cart ||
      prevProps.commission !== this.props.commission ||
      prevProps.poNumber !== this.props.poNumber ||
      prevProps.notes !== this.props.notes
    ) {
      var user = this.props.user;
      var shippingAddressID = this.props.shipTo;
      var tax = Number(this.props.tax);

      var orderTotal = 0.0;

      var items = [];
      for (var i = 0; i < this.props.cart.length; i++) {
        var item = this.props.cart[i];

        var qty = 1;
        if (item.qty) qty = item.qty;

        item.listPrice = Number(item.listPrice).toFixed(2);

        // validate pricing (shouldn't need this section but ...)
        if (this.props.pricing) {
          for (var j = 0; j < this.props.pricing.length; j++) {
            if (this.props.pricing[j].productID === item.id) {
              if (this.props.pricing[j].Price) {
                if (
                  Number(this.props.pricing[j].Price).toFixed(2) !==
                  item.listPrice
                ) {
                  item.listPrice = Number(this.props.pricing[j].Price).toFixed(
                    2,
                  );
                  console.log('price validated: ' + item.name);

                  const debugInfo = {
                    user: this.props.user,
                    action: 'pricingException',
                    product: item,
                  };

                  fetch(
                    'https://us-central1-kekaha-ebf1f.cloudfunctions.net/logDebugInfo',
                    {
                      method: 'POST',
                      cache: 'no-cache',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(debugInfo),
                    },
                  )
                    .then((response) => {
                      console.log('exception logged');
                    })
                    .catch((error) => {
                      // If there is any error you will catch them here
                      console.log('error (2):  ' + error);
                    });
                }
              }
              break;
            }
          }
        }

        item.total = Number(item.listPrice * qty).toFixed(2);
        orderTotal = orderTotal + Number(item.total);

        item._highlightResult = '';
        items.push(item);
        //console.log(item);
      }

      orderTotal = (Number(orderTotal) + Number(tax)).toFixed(2);

      var order = {
        items: items,
        tax: tax,
        taxResult: this.props.taxResult,
        orderTotal: orderTotal,
        commission: this.props.commission,
        userID: user.id,
        affiliate: this.props.affiliate,
        shippingAddressID: shippingAddressID,
        poNumber: this.props.poNumber,
        notes: this.props.notes,
        modified: Math.round(new Date().getTime() / 1000),
      };

      if (user.customerID) {
        order.customerID = user.customerID;
        order.contactID = user.contactID;
      }

      this.setState(
        { order: order, shipToAddress: this.props.shippingAddress },
        () => {},
      );

      //console.log(order);
    }
  }

  openSummary() {
    this.handleClickOpen('modal');
    /*
    console.log('openSummary()')
    var user = this.props.user;
    var shippingAddressID = this.props.shipTo;

    this.getCustomer(() => { // make sure we have state variables necessary

      var tax = Number(this.props.tax);
      var orderTotal = 0.0;

      var items = [];
      for(var i = 0; i < this.props.cart.length; i++) {
          var item = this.props.cart[i];

          var qty = 1;
          if(item.qty)
            qty = item.qty;

          item.listPrice = Number(item.listPrice).toFixed(2);

          // validate pricing (shouldn't need this section but ...)
          if(this.props.pricing) {
            
            for(var j = 0; j < this.props.pricing.length; j++) {
              if(this.props.pricing[j].productID === item.id) {
                if(this.props.pricing[j].Price) {
                if(Number(this.props.pricing[j].Price).toFixed(2) !== item.listPrice) {
                
                    item.listPrice = Number(this.props.pricing[j].Price).toFixed(2);
                    console.log('price validated: ' + item.name);

                    const debugInfo = {
                      user: this.props.user,
                      action: 'pricingException',
                      product: item
                    }
                
                    fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/logDebugInfo", {
                        method: "POST", 
                        cache: "no-cache",
                        headers: {
                          "Content-Type": "application/json"
                        },
                        body: JSON.stringify(debugInfo)
                    })
                    .then((response) => {
                        console.log('exception logged')
                    })
                    .catch((error) => {
                        // If there is any error you will catch them here
                        console.log("error (2):  " + error);
                    });
                  }
                }
                break;
              }
            }
          } 
          
          item.total = Number(item.listPrice * qty).toFixed(2);
          orderTotal = orderTotal + Number(item.total);

          item._highlightResult = "";
          items.push(item);
          //console.log(item);
      }

      orderTotal = (Number(orderTotal) + Number(tax)).toFixed(2);

      var order = {
        items: items,
        tax: tax,
        orderTotal: orderTotal,
        commission: this.props.commission,
        userID: user.id,
        affiliate: this.props.affiliate,
        shippingAddressID: shippingAddressID, 
        poNumber: this.props.poNumber,
        notes: this.props.notes,
        modified: Math.round((new Date()).getTime() / 1000)
      }

      if(user.customerID) {
        order.customerID = user.customerID;
        order.contactID = user.contactID;
      }

      this.setState({order: order, shipToAddress: this.props.shippingAddress}, () => {
        this.handleClickOpen("modal");
      });

      console.log(order);
    })
    */
  }

  getCustomer(callback) {
    if (this.props.user.customerID) {
      // i.e. managed customers
      db.getCustomer(this.props.user.customerID).then((customer) => {
        this.setState({ customer: customer }, () => {
          callback();
        });
      });
    } else {
      // random internet users
      callback();
    }
  }

  getPayment() {
    this.setState({ orderReviewed: true });
  }

  goBack() {
    this.setState({ orderReviewed: false });
  }

  checkOut() {
    this.setState({ orderProcessing: true }, () => {
      var user = this.props.user;
      var order = this.state.order;

      // cleanup the records a bit
      for (var i = 0; i < order.items.length; i++) {
        delete order.items[i]._highlightResult;
        delete order.items[i].attachments;
        delete order.items[i].Content;
        delete order.items[i].photo;
        delete order.items[i].subCat1ID;
        delete order.items[i].subCat2ID;
        delete order.items[i].subCat3ID;
        delete order.items[i].thumbnail;

        if (!order.items[i].commission) {
          delete order.items[i].commission;
          delete order.items[i].totalCommission;
        }
      }
      order.userName = user.firstName + ' ' + user.lastName;
      order.orderPlaced = timeConverter(order.modified);
      order.created = order.modified;

      this.createStripeCharge((err, charge) => {
        // built-in skip for managed customers

        if (err) {
          console.log('there was an ERROR with your charge');
        } else {
          if (charge) order.charge = charge;

          console.log(order);

          db.updateOrder(order.id, order).then(doc => {
            
            this.setState({ orderProcessing: false, orderCompleted: true });
    
            this.sendNotifications(() => {
              this.props.onCartCleared();
            });
    
            db.logActivity(user.id, user.customerID, "Order-Placed");
          })
        }
      });
    });
  }

  async createStripeCharge(callback) {
    console.log('createStripeCharge()');

    if (!this.props.user.customerID) {
      console.log('calling Stripe ...');

      //let {token} = await this.props.stripe.createToken({name: "Name"});

      this.props.stripe.createToken({ name: 'Name' }).then((result) => {
        if (result.error) {
          console.log('createToken error:');
          console.log(result.error);
        } else {
          var token = result.token;
          console.log('token:');
          console.log(token);

          if (token) {
            var amount = parseInt(this.state.order.orderTotal * 100, 10);

            var data = {
              token: token.id,
              amount: amount,
              order: this.state.order.id,
            };

            console.log(data);

            fetch(
              'https://us-central1-kekaha-ebf1f.cloudfunctions.net/createStripeCharge',
              {
                method: 'POST',
                headers: { 'Content-Type': 'text/plain' },
                body: JSON.stringify(data),
              },
            )
              .then((response) => {
                console.log(response);
                if (response.ok) {
                  return response.json();
                } else {
                  console.log('error (1): ');
                  console.log(response);
                }
              })
              .then((json) => {
                callback(null, json);
              })
              .catch((error) => {
                // If there is any error you will catch them here
                console.log('error (2):  ' + error);
                callback(error);
              });
          }
        }
      });
    } else {
      console.log('skipping Stripe ...');
      callback(null, null);
    }
  }

  sendNotifications(callback) {
    var text =
      'A new order was placed by ' +
      this.props.user.email +
      ' for a total of $' +
      this.state.order.orderTotal +
      '.';

    var html = '<html>';
    if (this.state.customer.company_name)
      html =
        html +
        'A new order was placed for ' +
        this.state.customer.company_name +
        '.';

    html = html + '<br /><br />';
    html = html + '<b>PO Number</b><br />';
    html = html + this.state.order.poNumber;

    html = html + '<br /><br />';
    html = html + '<b>Shipping Address</b><br />';
    if (this.state.shipToAddress.name)
      if (this.state.shipToAddress.name.length > 0)
        html = html + this.state.shipToAddress.name + '<br />';
    html = html + this.state.shipToAddress.address + '<br />';
    html =
      html +
      this.state.shipToAddress.city +
      ', ' +
      this.state.shipToAddress.state +
      ' ' +
      this.state.shipToAddress.zip +
      '<br />';

    html = html + '<br /><br />';

    html = html + '<table style="width:100%">';
    html = html + '<tr>';
    html = html + '<td><b>Qty</b></td>';
    html = html + '<td><b>ID</b></td>';
    html = html + '<td><b>Name</b></td>';
    html = html + '<td><b>Price</b></td>';
    html = html + '<td><b>Total</b></td>';
    html = html + '</tr>';

    for (var i = 0; i < this.props.cart.length; i++) {
      html = html + '<tr>';
      html = html + '<td>' + this.props.cart[i].qty + '</td>';
      html = html + '<td>' + this.props.cart[i].productID + '</td>';
      html = html + '<td>' + this.props.cart[i].name + '</td>';
      html = html + '<td>$' + this.props.cart[i].listPrice + '</td>';
      html = html + '<td>$' + this.props.cart[i].total + '</td>';
      html = html + '</tr>';
    }
    html = html + '</table>';

    html = html + '<br />';
    html =
      html +
      '<div style="fontWeight:600;">Order Total: ' +
      this.state.order.orderTotal +
      '</div>';

    html = html + '<br /><br />';
    html = html + 'This order was placed by ' + this.props.user.email + '.';
    html = html + '';
    if (this.state.order.notes)
      html = html + '<br /><br />Notes: ' + this.state.order.notes;
    html = html + '';
    html = html + '</html>';

    var subject = 'Internet User';
    if (this.state.customer.company_name)
      subject = this.state.customer.company_name;

    // admin Email

    const adminEmail = {
      to: 'rbj@eccjan.com',
      cc: 'cs@eccjan.com',
      subject: 'New Order: ' + subject,
      text: text,
      html: html,
    };

    fetch('https://us-central1-kekaha-ebf1f.cloudfunctions.net/sendEmail', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(adminEmail),
    })
      .then((response) => {
        console.log('email sent');

        callback();
      })
      .catch((error) => {
        // If there is any error you will catch them here
        console.log('error (2):  ' + error);
        callback();
      });
  }

  headerText() {
    if (this.state.orderCompleted) {
      return 'Success';
    } else if (this.state.orderProcessing) {
      return 'Placing Order';
    } else if (this.state.orderReviewed) {
      return 'Shipping and Payment';
    } else {
      return 'Review Your Order';
    }
  }

  showPayment() {
    if (this.state.orderReviewed && !this.state.orderProcessing) {
      return 'flex';
    } else {
      return 'none';
    }
  }

  showSummary() {
    if (
      !this.state.orderReviewed &&
      !this.state.orderProcessing &&
      !this.state.orderCompleted
    ) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showProgress() {
    if (this.state.orderProcessing) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showCompleted() {
    if (this.state.orderCompleted) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showPONumber() {
    if (this.props.poNumber) return 'inline-block';
    else return 'none';
  }

  showCreditPayment() {
    if (this.props.user.customerID) {
      return 'none';
    } else {
      return 'block';
    }
  }

  showCancelButton() {
    if (!this.state.orderProcessing && !this.state.orderCompleted) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showPreviousButton() {
    if (this.state.orderReviewed) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showNextButton() {
    if (!this.state.orderReviewed) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showCompleteOrderButton() {
    if (!this.state.orderProcessing && !this.state.orderCompleted) {
      return 'block';
    } else {
      return 'none';
    }
  }

  trim(value, maxLength) {
    if (value) {
      if (value.trim().length > maxLength)
        return value.trim().substring(0, maxLength) + '...';
      else return value.trim();
    } else return '';
  }

  render() {
    const { classes } = this.props;

    if (this.state.acknowledged === true) {
      return <Redirect to="/products" />;
    }

    return (
      <div style={{ display: 'inline-block', width: '100%' }}>
        <Button
          color="success"
          onClick={this.openSummary}
          disabled={this.props.isDisabled}
          style={{ width: '100%' }}
        >
          Checkout
        </Button>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          maxWidth="md"
          fullWidth
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={12} sm={12}>
                <h4 className={classes.modalTitle}>{this.headerText()}</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{ backgroundColor: '#efefef', paddingTop: '-0px' }}
          >
            <Card
              style={{
                minHeight: '369px',
                padding: '20px',
                paddingBottom: '0px',
              }}
            >
              <div
                style={{
                  maxHeight: '369px',
                  overflowY: 'scroll',
                  display: this.showSummary(),
                }}
              >
                <GridContainer direction="row">
                  <GridItem xs={6} style={{ marginTop: '10px' }}>
                    <div style={{ fontWeight: 500, fontSize: '17px' }}>
                      Shipping Address
                    </div>
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: '15px',
                        color: '#3fa31b',
                        marginBottom: '5px',
                      }}
                    >
                      {this.state.customer.customer_name}
                    </div>
                    {this.state.shipToAddress.address}
                    <br />
                    {this.state.shipToAddress.city},{' '}
                    {this.state.shipToAddress.state}{' '}
                    {this.state.shipToAddress.zip}
                    <br />
                    {this.state.shipToAddress.country}
                  </GridItem>

                  <GridItem
                    xs={6}
                    style={{
                      marginTop: '10px',
                      display: this.showCreditPayment(),
                    }}
                  >
                    <div style={{ fontWeight: 500, fontSize: '17px' }}>
                      Payment
                    </div>
                    <p>Enter Your Credit Card Information</p>

                    <div className="checkout">
                      <hr />
                      <CardElement />
                      <hr />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} style={{ marginTop: '23px' }}>
                    <div style={{ fontWeight: 500, fontSize: '17px' }}>
                      Product Summary
                    </div>
                    <div style={{ paddingBottom: '20px' }}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ padding: '3px' }}>
                              Product ID
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              Name
                            </TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              Qty
                            </TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              Price
                            </TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              Total
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.order.items.map((value, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ padding: '3px' }}>
                                {value.productID}
                              </TableCell>
                              <TableCell style={{ padding: '3px' }}>
                                {this.trim(value.name, 54)}
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', textAlign: 'right' }}
                              >
                                {value.qty}
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', textAlign: 'right' }}
                              >
                                ${value.listPrice}
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', textAlign: 'right' }}
                              >
                                ${value.total}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              <b>Tax</b>
                              <br />
                              <b>Total</b>
                            </TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              <b>${this.state.order.tax.toFixed(2)}</b>
                              <br />
                              <b>${this.state.order.orderTotal}</b>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>

              <GridContainer
                direction="row"
                style={{ textAlign: 'center', display: this.showProgress() }}
              >
                <GridItem xs={12} sm={12} style={{ marginTop: '123px' }}>
                  <CircularProgress />
                  <br />
                  Working
                </GridItem>
              </GridContainer>
              <GridContainer
                style={{ textAlign: 'center', display: this.showCompleted() }}
              >
                <GridItem xs={12} sm={12}>
                  Your order has been placed.
                </GridItem>
              </GridContainer>
            </Card>
          </DialogContent>

          <DialogActions
            className={classes.modalFooter}
            style={{ backgroundColor: '#efefef' }}
          >
            <Button
              onClick={() => this.handleClose('modal')}
              style={{ display: this.showCancelButton() }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.checkOut}
              color="success"
              style={{ display: this.showCompleteOrderButton() }}
            >
              Place Order
            </Button>
            <Button
              onClick={() => {
                this.setState({ acknowledged: true });
              }}
              color="success"
              style={{ display: this.showCompleted() }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onCartCleared: CartCleared }, dispatch);
}

Checkout = connect(mapStateToProps, mapDispatchToProps)(Checkout);
Checkout = injectStripe(Checkout);
export default withStyles(modalStyle)(Checkout);
