
import { combineReducers } from 'redux';
import { 
    AFFILIATE_LOADED,
    ALERT_CREATED,
    BUDGET_EXCEPTIONS_LOADED,
    CART_CLEARED, CART_PRODUCT_ADDED, CART_PRODUCT_REMOVED,
    CUSTOMERS_LOADED,
    EVENT_CREATED,
    ORG_LOADED, ORG_UPDATED,
    LOCATION_CREATED, LOCATIONS_LOADED, LOCATION_UPDATED,
    LOCATION_SELECTED,
    NAVSTATE_CHANGED,
    PRICING_LOADED,
    PRODUCT_CREATED, PRODUCTS_LOADED, PRODUCT_UPDATED,
    SPECIAL_SELECTED, SPECIALS_LOADED,
    TAX_EXEMPTIONS_LOADED,
    USER_LOGGEDIN, USER_LOGGEDOUT,
    VISIBILITY_LOADED
} from './actions';
//CUSTOMER_ACTIVATED, 

// generic sort comparer
function compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
}


function affiliate(state = "", action) {
    switch (action.type) {
        case AFFILIATE_LOADED:
            return action.affiliate;
        default:
            return state;
    }
}

function alerts(state = [], action) {
    
    switch (action.type) {
        case ALERT_CREATED:

            return action.alert;

        default:
            return state;
    }
}

function budgetExceptions(state = [], action) {
    switch(action.type) {
        case BUDGET_EXCEPTIONS_LOADED:
            return action.exceptions;
        default:
            return state;
    }
}

function cart(state = [], action) {
    switch (action.type) {
        case CART_PRODUCT_ADDED:
            
            var i1 = state.slice();
            var alreadyInCart = false;
            for(var i = 0; i < i1.length; i++) {
                if(action.product.id === i1[i].id) {
                  i1[i] = action.product;
                  alreadyInCart = true;
                  break;
                }
            }

            if(!alreadyInCart)
                i1.push(action.product);

            return i1;

        case CART_PRODUCT_REMOVED:
            var i2 = state.slice();
            for(var j = 0; j < i2.length; j++) {
                if(action.product.id === i2[j].id) {
                  i2.splice(j, 1);
                  break;
                }
            }
            return i2;

        case CART_CLEARED:
            return [];
            
        default:
            return state;
    }
}

function customers(state=[], action) {
    switch (action.type) {
        case CUSTOMERS_LOADED:
            return action.customers;
        default:
            return state;
    }
}

function events(state = [], action) {
    
    switch (action.type) {
        case EVENT_CREATED:

            return action.event;

        default:
            return state;
    }
}

function locations(state = [], action) {

    switch (action.type) {
        
        case LOCATION_CREATED:
            state.push(action.location);
            return state.sort(compareName);

        case LOCATIONS_LOADED:
            return action.locations;

        case LOCATION_UPDATED:
            for(var i = 0; i < state.length; i++) {
                if(state[i].id === action.location.id) {
                    state[i] = action.location;
                    break;
                }                
            }
            return state.sort(compareName);

        default:
            return state.sort(compareName);
    }
}

function navState(state="landing", action) {
    switch (action.type) {
        case NAVSTATE_CHANGED:
            return action.navState;
        default:
            return state;
    }
}

function pricing(state = [], action) {
    switch(action.type) {
        case PRICING_LOADED:
            return action.pricing;
        default:
            return state;
    }
}

function products(state = [], action) {
    
    switch (action.type) {
        case PRODUCT_CREATED:

            state.push(action.product);
            return state.sort(compareName);

        case PRODUCTS_LOADED:

            return action.products;

        case PRODUCT_UPDATED:

            for(var i = 0; i < state.length; i++) {
                if(state[i].id === action.product.id) {
                    state[i] = action.product;
                    break;
                }                
            }
            return state.sort(compareName);

        default:
            return state.sort(compareName);
    }
}

function org(state = null, action) {
    
    switch (action.type) {
        case ORG_LOADED:

            return {
                id: action.org.id,
                name: action.org.name,
                description: action.org.description,
                website: action.org.website,
                latitude: action.org.latitude,
                longitude: action.org.longitude
            }

        case ORG_UPDATED:

            return {
                id: action.org.id,
                name: action.org.name,
                description: action.org.description,
                website: action.org.website,
                latitude: action.org.latitude,
                longitude: action.org.longitude,
                stripeCredentials: action.org.stripeCredentials
            }

        default:
            return state;
    }
}

function shipTo(state = "", action) {
    switch (action.type) {
        case LOCATION_SELECTED:
            return action.location;
        default:
            return state;
    }
}

function special(state = {}, action) {
    switch (action.type) {
        case SPECIAL_SELECTED:
            return action.special;
        default:
            return state;
    }
}

function specials(state = [], action) {
    switch(action.type) {
        case SPECIALS_LOADED:
            return action.specials;
        default:
            return state;
    }
}

function taxExempt(state = false, action) {
    switch(action.type) {
        case TAX_EXEMPTIONS_LOADED:
            return action.isExempt;
        default:
            return state;
    }
}

function user(state = {authenticated: false}, action) {

    switch (action.type) {
        case USER_LOGGEDIN:
        
            return {
                    authenticated: true,
                    id: action.user.id,
                    email: action.user.email,
                    firstName: action.user.firstName,
                    lastName: action.user.lastName,
                    customerID: action.user.customerID,
                    contactID: action.user.contactID,
                    addresses: action.user.addresses,
                    userType: action.user.userType,
                    customers: action.user.customers,
                    companyName: action.user.companyName,
                    budgetingPermissions: action.user.budgetingPermissions,
                    orderingPermissions: action.user.orderingPermissions,
                    invoicePermissions: action.user.invoicePermissions,
                    commissionType: action.user.commissionType,
                    baseCommission: action.user.baseCommission,
                    floorCommission: action.user.floorCommission,
                    parent: action.user.parent
                }

        case USER_LOGGEDOUT:

            return {
                authenticated: false
            }

        default:
            return state;
    }
}

function visibility(state = [], action) {
    switch(action.type) {
        case VISIBILITY_LOADED:
            return action.visibility;
        default:
            return state;
    }
}

const Reducers = combineReducers({ 
    affiliate,
    alerts,
    budgetExceptions,
    cart,
    customers,
    events,
    locations,
    navState,
    org, 
    pricing,
    products,
    shipTo,
    special,
    specials,
    taxExempt,
    user,
    visibility
})

const appState = (Reducers)

export default appState
