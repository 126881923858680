//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.jsx';

//core
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';

//components
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CustomSelect from 'components/Select/Select.jsx';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog.jsx';
import RecurringOrder from 'components/RecurringOrder/RecurringOrder.jsx';

//icons
import Edit from '@material-ui/icons/Edit';
import Location from '@material-ui/icons/EditLocation';
import Budget from '@material-ui/icons/BarChart';
import Renew from '@material-ui/icons/Autorenew';

//unique id gen
const uuidv4 = require('uuid/v4');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AddressEditor extends React.Component {
  constructor(props) {
    super(props);

    var today = new Date();

    this.state = {
      modal: false,
      address: { name: '', address: '', city: '', state: '', zip: '' },
      budget: {
        period: 12,
        fiscalStart: '1/1/' + today.getFullYear(),
        amount: 0.0,
        startingBalance: 0.0,
      },
      orders: [],
    };

    this.getRenewingOrders = this.getRenewingOrders.bind(this);
    this.recurringOrderSaved = this.recurringOrderSaved.bind(this);
  }

  handleClickOpen(modal) {
    this.setState({ address: this.props.address });

    if (this.props.budget) {
      var budget = this.props.budget;

      if (!budget.fiscalStart) budget.fiscalStart = '1/1/2019';

      if (!budget.amount) budget.amount = 0.0;

      if (!budget.startingBalance) budget.startingBalance = 0.0;

      if (!budget.period) budget.period = 12;

      this.setState({ budget: budget });
    }

    this.getRenewingOrders();

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  updateAddress(property, value) {
    var obj = this.state.address;
    obj[property] = value;
    this.setState({ address: obj });
  }

  getRenewingOrders() {
    db.getRenewingOrdersByCustomerLocation(
      this.props.user.customerID,
      this.props.address.address_id,
    ).then((orders) => {
      this.setState({ orders: orders });
    });
  }

  updateBudget(property, value) {
    var obj = this.state.budget;
    obj[property] = value;
    this.setState({ budget: obj });
  }

  saveAddress() {
    var budget = this.state.budget;
    if (!budget.id) {
      budget.id = uuidv4();
      budget.customerID = this.props.user.customerID;
      budget.addressID = this.props.address.address_id;
    }

    db.updateBudget(budget.id, budget).then(() => {
      this.props.onBudgetUpdated(budget);
    });
  }

  trim(value, maxLength) {
    if (value) {
      if (value.trim().length > maxLength)
        return value.trim().substring(0, maxLength) + '...';
      else return value.trim();
    } else return '';
  }

  getFrequencyDescriptor(frequency) {
    if (frequency === 'm') return 'Monthly';
    else if (frequency === 'w') return 'Weekly';
  }

  recurringOrderSaved(value) {
    var orders = this.state.orders;
    for (var i = 0; i < orders.length; i++) {
      if (orders[i].id === value.id) {
        orders[i] = value;
        break;
      }
    }
    this.setState({ orders: orders });
  }

  deleteRecurringOrder(value) {
    var orders = this.state.orders;
    for (var i = 0; i < orders.length; i++) {
      if (orders[i].id === value) {
        orders.splice(i, 1);
        break;
      }
    }

    this.setState({ orders: orders }, () => {
      db.deleteRecurring(value);
    });
  }

  getEnabledDescriptor(enabled) {
    if (enabled) return <span style={{ color: 'green' }}>YES</span>;
    else return <span style={{ color: 'maroon' }}>NO</span>;
  }

  showBudget() {
    if (
      this.props.user.budgetingPermissions ||
      this.props.user.userType === 'admin' ||
      this.props.user.userType === 'Affiliate'
    )
      return false;
    else return true;
  }

  showRecurringOrders() {
    if (
      this.props.user.orderingPermissions ||
      this.props.user.userType === 'admin' ||
      this.props.user.userType === 'Affiliate'
    )
      return false;
    else return true;
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <IconButton
          aria-label="Edit"
          onClick={() => this.handleClickOpen('modal')}
        >
          <Edit
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          fullScreen={this.props.fullScreen}
          style={{
            height: '100%',
            overflowY: 'scroll',
          }}
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={12}>
                <h4 className={classes.modalTitle}>Location Editor</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{ backgroundColor: '#efefef', overflowY: 'scroll' }}
          >
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: 'General',
                  tabIcon: Location,
                  tabContent: (
                    <GridContainer direction="row">
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Name"
                          value={this.state.address.name}
                          inputProps={{
                            placeholder: 'Name',
                          }}
                          formControlProps={{
                            fullWidth: true,
                            disabled: true,
                          }}
                          onChange={(e) =>
                            this.updateAddress('name', e.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Address"
                          value={this.state.address.address}
                          inputProps={{
                            placeholder: 'Street Address',
                          }}
                          formControlProps={{
                            fullWidth: true,
                            disabled: true,
                          }}
                          onChange={(e) =>
                            this.updateAddress('address', e.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          value={this.state.address.city}
                          inputProps={{
                            placeholder: 'City',
                          }}
                          formControlProps={{
                            fullWidth: true,
                            disabled: true,
                          }}
                          onChange={(e) =>
                            this.updateAddress('city', e.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText="State"
                          value={this.state.address.state}
                          inputProps={{
                            placeholder: 'State',
                          }}
                          formControlProps={{
                            fullWidth: true,
                            disabled: true,
                          }}
                          onChange={(e) =>
                            this.updateAddress('state', e.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText="Postal Code"
                          value={this.state.address.zip}
                          inputProps={{
                            placeholder: 'Postal Code',
                          }}
                          formControlProps={{
                            fullWidth: true,
                            disabled: true,
                          }}
                          onChange={(e) =>
                            this.updateAddress('zip', e.target.value)
                          }
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'Budget',
                  tabIcon: Budget,
                  tabHidden: this.showBudget(),
                  tabContent: (
                    <GridContainer direction="row">
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Fiscal Year Start"
                          value={this.state.budget.fiscalStart}
                          inputProps={{
                            placeholder: 'Fiscal Year Start Date',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(e) =>
                            this.updateBudget('fiscalStart', e.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomSelect
                          labelText="Period"
                          value={this.state.budget.period}
                          inputProps={{
                            placeholder: 'Period',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(e) =>
                            this.updateBudget('period', e.target.value)
                          }
                          options={[
                            { key: 52, label: 'Weekly' },
                            { key: 12, label: 'Monthly' },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Budget"
                          value={this.state.budget.amount}
                          inputProps={{
                            placeholder: 'Budget',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(e) =>
                            this.updateBudget('amount', e.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Starting Balance"
                          value={this.state.budget.startingBalance}
                          inputProps={{
                            placeholder: 'Starting Balance',
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          onChange={(e) =>
                            this.updateBudget('startingBalance', e.target.value)
                          }
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'Recurring Orders',
                  tabIcon: Renew,
                  tabHidden: this.showRecurringOrders(),
                  tabContent: (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ padding: '3px', width: '43px' }}
                          ></TableCell>
                          <TableCell style={{ padding: '3px' }}>Name</TableCell>
                          <TableCell style={{ padding: '3px' }}>
                            Frequency
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', textAlign: 'right' }}
                          >
                            Start Date
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', textAlign: 'right' }}
                          >
                            End Date
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', textAlign: 'right' }}
                          >
                            Enabled
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.orders.map((value, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ padding: '3px' }}>
                              <RecurringOrder
                                iconButton
                                order={value}
                                shippingAddress={this.state.address}
                                orderSaved={this.recurringOrderSaved}
                              />
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {this.trim(value.Name, 54)}
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {this.getFrequencyDescriptor(value.Frequency)}
                            </TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              {value.StartDate}
                            </TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              {value.EndDate}
                            </TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              {this.getEnabledDescriptor(value.IsEnabled)}
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                id="tooltip-top-start"
                                title="Delete Recurring Order"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <ConfirmDialog
                                  header="Confirm Delete"
                                  description="Are you sure you want to delete this recurring order? This action cannot be undone."
                                  confirmed={() =>
                                    this.deleteRecurringOrder(value.id)
                                  }
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ),
                },
              ]}
            />
            <br />
            <br />
          </DialogContent>
          <DialogActions
            className={classes.modalFooter}
            style={{ backgroundColor: '#efefef' }}
          >
            <Button onClick={() => this.handleClose('modal')}>Cancel</Button>
            &nbsp;
            <Button
              onClick={() => {
                this.saveAddress();
                this.handleClose('modal');
              }}
              color="success"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

AddressEditor = connect(mapStateToProps)(AddressEditor);
AddressEditor = withMobileDialog()(AddressEditor);
export default withStyles(modalStyle)(AddressEditor);
