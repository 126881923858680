//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.jsx';

//core
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

//components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import PDFViewer from 'components/PDFViewer/PDFViewer.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withMobileDialog from '@material-ui/core/withMobileDialog';

//icons
import View from '@material-ui/icons/Search';
import Receipt from '@material-ui/icons/Receipt';
import Attachment from '@material-ui/icons/Attachment';
import Notes from '@material-ui/icons/SpeakerNotes';
import Payment from '@material-ui/icons/Payment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function getDate(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  return month + ' ' + date + ', ' + year;
}

function getTime(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var hours = a.getHours();
  var minutes = a.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const defaultInvoice = {
  billing_address: {},
  custom_fields: [{ value: '' }],
  line_items: [],
  shipping_address: {},
}

class Invoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      isLoading: true,
      hasAttachment: false,
      showAttachmentLoading: false,
      invoice: defaultInvoice,
      newNote: '',
      notes: [],
      payments: [],
    };

    this.addNote = this.addNote.bind(this);
    this.getInvoiceNotes = this.getInvoiceNotes.bind(this);
    this.getInvoicePayments = this.getInvoicePayments.bind(this);
  }
  
  componentDidUpdate(prevProps) {
    
    if (prevProps.isOpen !== this.props.isOpen) {
      console.log('prop change registered');
      this.loadInvoice(this.props.invoice);
      this.setState({ modal: this.props.isOpen });
    }
  }

  handleClickOpen(modal) {
    this.loadInvoice(this.props.invoice);

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadInvoice(invoice) {
    this.setState(
      { invoice: Object.assign({}, defaultInvoice, invoice), hasAttachment: this.props.invoice.has_attachment },
      () => {
        if (this.state.invoice.documents) {
          this.setState({ showAttachmentLoading: true });
          for (var i = 0; i < this.state.invoice.documents.length; i++) {
            this.getAttachment(this.state.invoice.documents[i]);
          }
        }

        this.getInvoiceNotes();
        this.getInvoicePayments();
      },
    );
  }

  getInvoiceNotes() {
    if(this.state.invoice.discussion)
      this.setState({ notes: this.state.invoice.discussion });
    else 
      this.setState({ notes: [] });
  }

  getInvoicePayments() {}

  updateInvoice(property, value) {
    var invoice = this.state.invoice;
    invoice[property] = value;
    this.setState(invoice);
  }

  downloadInvoice() {

    console.log('downloadInvoice()');

    var invoice = this.state.invoice;

    console.log('invoice.invoice_id: ' + invoice.invoice_id);

    var url =
      'https://us-central1-kekaha-ebf1f.cloudfunctions.net/getZohoInvoice';

    console.log('url: ' + url);

    var data = {
      invoiceID: invoice.invoice_id,
    };

    fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.blob();
        } else {
          console.log('error1');
          console.log(response);
        }
      })
      .then((blob) => {
        this.downloadFile(blob, invoice.invoice_number + '.pdf');
      })
      .catch((error) => {
        // If there is any error you will catch them here
        console.log('error getting invoice attachment from zoho: ' + error);
      });
  }

  downloadFile(blob, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
  }

  getAttachment(document) {
    var invoice = this.state.invoice;

    console.log('getAttachment()');

    var url =
      'https://us-central1-kekaha-ebf1f.cloudfunctions.net/getZohoInvoiceAttachment';

    console.log('url: ' + url);

    console.log('invoice.invoice_id: ' + invoice.invoice_id);
    console.log('document.document_id: ' + document.document_id);

    var data = {
      invoiceID: invoice.invoice_id,
      documentID: document.document_id,
    };

    fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.arrayBuffer();
        } else {
          console.log('error1');
          console.log(response);
        }
      })
      .then((array) => {
        var invoice = this.state.invoice;
        for (var i = 0; i < invoice.documents.length; i++) {
          if (invoice.documents[i].document_id === document.document_id) {
            invoice.documents[i].pdf = new Uint8Array(array);
            break;
          }
        }
        this.setState({ invoice: invoice, showAttachmentLoading: false });
      })
      .catch((error) => {
        // If there is any error you will catch them here
        console.log('error getting invoice attachment from zoho: ' + error);
      });
  }

  showLoading() {
    if (this.state.showAttachmentLoading) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showAttachment() {
    if (this.state.invoice.documents) {
      //&& this.state.invoice.numPagesAttachment) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showAttachmentButton() {
    if (this.state.invoice.documents) {
      return 'inline-flex';
    } else {
      return 'none';
    }
  }

  hideAttachmentsTab() {
    if (this.state.invoice.documents) {
      return false;
    } else {
      return true;
    }
  }

  showIcon() {
    if (this.props.hideIcon) return 'none';
    else return 'block';
  }

  getAttachments() {
    var docs = [];

    if (this.state.invoice.documents) {
      for (var i = 0; i < this.state.invoice.documents.length; i++) {
        docs.push(
          <PDFViewer
            key={'pdf' + i}
            fileName={this.state.invoice.documents[i].file_name}
            pdf={this.state.invoice.documents[i].pdf}
          />,
        );
      }
    }

    return docs;
  }

  getBackgroundColor(index) {
    if (index % 2 === 0) return '#efefef';
    else return '#ffffff';
  }

  addNote() {
    var note = {
      customerID: this.state.invoice.customer_id,
      invoiceID: this.state.invoice.invoice_id,
      invoiceNumber: this.state.invoice.invoice_number,
      body: this.state.newNote,
      author: this.props.user.firstName + ' ' + this.props.user.lastName,
      created: Math.round(new Date().getTime() / 1000),
    };

    db.updateInvoiceNote(note.id, note).then(() => {
      if (
        this.props.user.id !== '6pTsX4vV6Iebf1z00w7kwb7qkBs2' && //bo
        this.props.user.id !== 'bxskvRHRPva4yGO97Hbi35uOYIW2'
      )
        //michael
        this.sendNotification(note);
    });

    var notes = this.state.notes;
    notes.push(note);

    notes.sort(this.sortByCreated);

    this.setState({ notes: notes, newNote: '' });
  }

  sortByCreated(a, b) {
    var x1 = a.created;
    var x2 = b.created;

    if (x1 > x2) return -1;
    if (x1 < x2) return 1;
    return 0;
  }

  sendNotification(note) {
    var text =
      'A new note was added by ' +
      note.author +
      ' to invoice #' +
      this.state.invoice.invoice_number +
      '.';

    var html = '<html>';
    html =
      html +
      'A new note was added by ' +
      note.author +
      ' to invoice #' +
      this.state.invoice.invoice_number +
      '.';
    html = html + '<br /><br />';

    html = html + '<br /><br />';
    html = html + 'Conversation History:<br /><br />';
    for (var i = 0; i < this.state.notes.length; i++) {
      html =
        html +
        "<div style='color:green'>" +
        this.state.notes[i].author +
        '</div>';
      html = html + this.state.notes[i].body + '<br /><br />';
    }

    html = html + '<br /><br />';
    html = html + '</html>';

    var subject = 'Invoice ' + this.state.invoice.invoice_number;

    // admin Email

    const adminEmail = {
      to: 'rbj@eccjan.com',
      cc: 'cs@eccjan.com',
      subject: 'New Note: ' + subject,
      text: text,
      html: html,
    };

    fetch('https://us-central1-kekaha-ebf1f.cloudfunctions.net/sendEmail', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(adminEmail),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log('error (1): ');
          console.log(response);
        }

        //callback();
      })
      .catch((error) => {
        // If there is any error you will catch them here
        console.log('error (2):  ' + error);
        //callback();
      });
  }

  showLink() {
    if(this.props.link) 
      return 'block';
    else
      return 'none';
  }

  invoiceNumber() {
    if(this.props.invoice)
      return this.props.invoice.invoice_number;
    else 
      return "";
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div style={{display:this.showLink(), color:'green'}} onClick={() => this.handleClickOpen('modal')}>
          {this.invoiceNumber()}
        </div>
        <IconButton
          aria-label="View"
          style={{ display: this.showIcon() }}
          onClick={() => this.handleClickOpen('modal')}
        >
          <View
            className={classes.tableActionButtonIcon + ' ' + classes.edit}
          />
        </IconButton>
        <Dialog
          fullScreen={this.props.fullScreen}
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth={true}
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          style={{
            height: '100%',
            overflowY: 'scroll',
          }}
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={12}>
                <h4 className={classes.modalTitle}>Invoice</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{ backgroundColor: '#efefef', maxHeight: '90vh', overflow: 'scroll' }}
          >
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: 'Invoice',
                  tabIcon: Receipt,
                  tabContent: (
                    <GridContainer style={{ minHeight: '369px' }}>
                      <GridItem xs={6} sm={3}>
                        <br />
                        <div
                          style={{
                            fontSize: '11px',
                            fontWeight: 400,
                            color: '#4caf50',
                            marginBottom: '-6px',
                          }}
                        >
                          Invoice Number
                        </div>
                        {this.state.invoice.invoice_number}
                        <br />
                        <div
                          style={{
                            fontSize: '11px',
                            fontWeight: 400,
                            color: '#4caf50',
                            marginBottom: '-6px',
                            marginTop: '17px',
                          }}
                        >
                          PO Number
                        </div>
                        {this.state.invoice.custom_fields[0].value}
                      </GridItem>
                      <GridItem xs={6} sm={3}>
                        <br />
                        <div
                          style={{
                            fontSize: '11px',
                            fontWeight: 400,
                            color: '#4caf50',
                            marginBottom: '-6px',
                          }}
                        >
                          Invoice Date
                        </div>
                        {this.state.invoice.date}
                        <div
                          style={{
                            fontSize: '11px',
                            fontWeight: 400,
                            color: '#4caf50',
                            marginBottom: '-6px',
                            marginTop: '17px',
                          }}
                        >
                          Due Date
                        </div>
                        {this.state.invoice.due_date}{' '}
                        <span style={{ color: '#545454', fontSize: '13px' }}>
                          {this.state.invoice.payment_terms_label && ("("+this.state.invoice.payment_terms_label+")")}
                        </span>
                      </GridItem>
                      <GridItem xs={6} sm={3}>
                        <br />
                        <div
                          style={{
                            fontSize: '11px',
                            fontWeight: 400,
                            color: '#4caf50',
                            marginBottom: '-6px',
                          }}
                        >
                          Bill To
                        </div>
                        {this.state.invoice.billing_address.address}{' '}
                        {this.state.invoice.billing_address.street2}
                        <br />
                        {this.state.invoice.billing_address.city}{' '}
                        {this.state.invoice.billing_address.state}{' '}
                        {this.state.invoice.billing_address.zip}
                      </GridItem>
                      <GridItem xs={6} sm={3}>
                        <br />
                        <div
                          style={{
                            fontSize: '11px',
                            fontWeight: 400,
                            color: '#4caf50',
                            marginBottom: '-6px',
                          }}
                        >
                          Ship To
                        </div>
                        {this.state.invoice.shipping_address.address}{' '}
                        {this.state.invoice.shipping_address.street2}
                        <br />
                        {this.state.invoice.shipping_address.city}{' '}
                        {this.state.invoice.shipping_address.state}{' '}
                        {this.state.invoice.shipping_address.zip}
                      </GridItem>

                      <GridItem xs={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ padding: '0px' }}>
                                #
                              </TableCell>
                              <TableCell style={{ padding: '3px' }}>
                                Name
                              </TableCell>
                              <TableCell style={{ padding: '3px' }}>
                                Qty
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', textAlign: 'right' }}
                              >
                                Price
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', textAlign: 'right' }}
                              >
                                Total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.invoice.line_items.map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    style={{ padding: '0px', width: '50px' }}
                                  >
                                    {index + 1}
                                    <br />
                                    &nbsp;
                                  </TableCell>
                                  <TableCell style={{ padding: '3px' }}>
                                    <b>{value.name}</b>
                                    <div style={{ marginTop: '-5px' }}>
                                      {value.description}
                                    </div>
                                  </TableCell>
                                  <TableCell style={{ padding: '3px' }}>
                                    {value.quantity}
                                    <br />
                                    &nbsp;
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: '3px',
                                      textAlign: 'right',
                                    }}
                                  >
                                    $
                                    {numberWithCommas(
                                      Number(value.rate).toFixed(2),
                                    )}
                                    <br />
                                    &nbsp;
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: '3px',
                                      textAlign: 'right',
                                    }}
                                  >
                                    $
                                    {numberWithCommas(
                                      Number(value.item_total).toFixed(2),
                                    )}
                                    <br />
                                    &nbsp;
                                  </TableCell>
                                </TableRow>
                              ),
                            )}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell
                                colSpan="3"
                                style={{ lineHeight: '19px' }}
                              >
                                {this.state.invoice.notes}
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', textAlign: 'right' }}
                              >
                                <b>Tax</b>
                                <br />
                                <b>Total</b>
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', textAlign: 'right' }}
                              >
                                <b>
                                  $
                                  {numberWithCommas(
                                    Number(
                                      this.state.invoice.tax_total,
                                    ).toFixed(2),
                                  )}
                                </b>
                                <br />
                                <b>
                                  $
                                  {numberWithCommas(
                                    Number(this.state.invoice.total).toFixed(2),
                                  )}
                                </b>
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'Attachments',
                  tabIcon: Attachment,
                  tabHidden: this.hideAttachmentsTab(),
                  tabContent: (
                    <div style={{ minHeight: '369px', textAlign: 'center' }}>
                      <div
                        style={{ display: this.showLoading(), padding: '30px' }}
                      >
                        <CircularProgress />
                      </div>
                      <div
                        style={{
                          display: this.showAttachment(),
                          textAlign: 'center',
                        }}
                      >
                        {this.getAttachments()}
                      </div>
                    </div>
                  ),
                },
                {
                  tabName: 'Payments',
                  tabIcon: Payment,
                  tabHidden: true,
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ padding: '0px' }}>
                                #
                              </TableCell>
                              <TableCell style={{ padding: '3px' }}>
                                Name
                              </TableCell>
                              <TableCell style={{ padding: '3px' }}>
                                Qty
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', textAlign: 'right' }}
                              >
                                Price
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', textAlign: 'right' }}
                              >
                                Total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.payments.map((value, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  style={{ padding: '0px', width: '50px' }}
                                >
                                  {index + 1}
                                  <br />
                                  &nbsp;
                                </TableCell>
                                <TableCell style={{ padding: '3px' }}>
                                  <b>{value.name}</b>
                                  <div style={{ marginTop: '-5px' }}>
                                    {value.description}
                                  </div>
                                </TableCell>
                                <TableCell style={{ padding: '3px' }}>
                                  {value.quantity}
                                  <br />
                                  &nbsp;
                                </TableCell>
                                <TableCell
                                  style={{ padding: '3px', textAlign: 'right' }}
                                >
                                  $
                                  {numberWithCommas(
                                    Number(value.rate).toFixed(2),
                                  )}
                                  <br />
                                  &nbsp;
                                </TableCell>
                                <TableCell
                                  style={{ padding: '3px', textAlign: 'right' }}
                                >
                                  $
                                  {numberWithCommas(
                                    Number(value.item_total).toFixed(2),
                                  )}
                                  <br />
                                  &nbsp;
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'Notes',
                  tabIcon: Notes,
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={10}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.formControl,
                          }}
                          inputProps={{
                            placeholder: 'Add a Note',
                          }}
                          value={this.state.newNote}
                          onChange={(e) =>
                            this.setState({ newNote: e.target.value })
                          }
                        />
                      </GridItem>
                      <GridItem xs={2}>
                        <Button onClick={this.addNote}>Add Note</Button>
                      </GridItem>
                      <GridItem xs={12}>
                        <Table className={classes.table}>
                          <TableBody>
                            {this.state.notes.map((value, index) => (
                              <TableRow
                                key={index}
                                style={{
                                  backgroundColor: this.getBackgroundColor(
                                    index,
                                  ),
                                  padding: '8px',
                                }}
                              >
                                <TableCell
                                  style={{
                                    paddingLeft: '10px',
                                    width: '166px',
                                    fontSize: '13px',
                                    verticalAlign: 'top',
                                  }}
                                >
                                  <div style={{ fontWeight: '400' }}>
                                    {getDate(value.created)}
                                  </div>
                                  <div style={{ marginTop: '-5px' }}>
                                    {getTime(value.created)}
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    paddingLeft: '10px',
                                    fontSize: '13px',
                                  }}
                                >
                                  <div
                                    style={{
                                      color: '#4caf50',
                                      fontWeight: '400',
                                    }}
                                  >
                                    {value.author}
                                  </div>
                                  <div
                                    style={{
                                      marginTop: '-5px',
                                      lineHeight: '19px',
                                    }}
                                  >
                                    {value.body}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </DialogContent>
          <DialogActions
            className={classes.modalFooter}
            style={{
              backgroundColor: '#efefef',
              padding: '6px',
              margin: '0px',
            }}
          >
            <GridItem xs={12} style={{ textAlign: 'right' }}>
              <Button onClick={() => this.downloadInvoice()}>
                Download Invoice
              </Button>
              &nbsp;
              <Button color="success" onClick={() => this.handleClose('modal')}>
                OK
              </Button>
            </GridItem>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

Invoice = connect(mapStateToProps)(Invoice);
Invoice = withMobileDialog()(Invoice);
export default withStyles(modalStyle)(Invoice);
//export default Invoice;
