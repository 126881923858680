//react
import React from "react";

//firebase
import { db } from 'assets/firebase';

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavStateChanged, LocationSelected } from 'redux/actions';

// styles
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.jsx";

// components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import AddressChooser from 'components/AddressChooser/AddressChooser.jsx';

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionHTML from "views/EcommercePage/Sections/SectionHTML.jsx";
import SectionLanding from "views/EcommercePage/Sections/SectionLanding.jsx";
import SectionProducts from "views/EcommercePage/Sections/SectionProducts.jsx";

class EcommercePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: 0,
      searchQuery: "",
      htmlContent:"",
      isLandingPageEnabled: false,
      navState: 'loading'
    };

    var cartTotal = 0.0;
    for (var j = 0; j < props.cart.length; j++) {
      cartTotal = cartTotal + props.cart[j].qty * props.cart[j].listPrice;
    }
    this.state.cartTotal = cartTotal;
    
    this.changeShipTo = this.changeShipTo.bind(this);
    this.searchProducts = this.searchProducts.bind(this);
    this.resetQuery = this.resetQuery.bind(this);

    db.logActivity(this.props.user.id, this.props.user.customerID, "View-Products");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    
    db.getLandingPageConfig().then(config => {
    
      this.setState({htmlContent: config.landingPageContent, isLandingPageEnabled: config.landingPageEnabled });

      if(config.landingPageEnabled) 
        this.setState({navState: 'landing'});
      else 
        this.setState({navState: 'catalog'});
        
    });
  }

  componentWillReceiveProps(nextProps){
    
    if(nextProps.navState) {
      if(this.state.isLandingPageEnabled)
        this.setState({navState: nextProps.navState});
    }
  }

  searchProducts(query) {
    this.setState({searchQuery: query});
    this.props.onNavStateChanged('catalog');
  }

  resetQuery() {
    this.setState({searchQuery: ""});
  }

  changeShipTo(location) {
    this.props.onLocationSelected(location);
  }

  showLanding() {
    
    if(this.state.navState === 'landing')
      return 'block';
    else 
      return 'none';
  }

  showCatalog() {
    
    if(this.state.navState === 'catalog' )
      return 'block';
    else 
      return 'none';
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user) {
      return <Redirect to='/login' />
    } else if (!this.props.user.orderingPermissions && this.props.user.userType !== 'admin' && this.props.user.userType !== 'Affiliate') {
      return <Redirect to='/login' />
    } 

    return (
      <div>
        <Header
          brand="Ecclesia"
          links={<HeaderLinks 
                    dropdownHoverColor="info" 
                    onSearch={this.searchProducts} 
                    searchQuery={this.state.searchQuery} />}
          color="dark"
        />

        <div className={classNames(classes.container)} style={{ marginTop:"54px", marginBottom:"54px" }}>
          
          <div style={{display:this.showLanding()}} >
            <SectionHTML content={this.state.htmlContent} />
          </div>

          <AddressChooser
            chooseShipTo
            onAddressChosen={this.changeShipTo}
            cartTotal={this.state.cartTotal}
          />    

          <div style={{display:this.showLanding()}} >
            <SectionLanding />
          </div>
          
          <div style={{display:this.showCatalog()}}>
            <SectionProducts 
              searchQuery={this.state.searchQuery} 
              resetQuery={this.resetQuery} />
          </div>
          
        </div>
        
        <Footer
          theme="dark"
          content={
            <div>
              <div className={classes.right} style={{fontSize:"12px"}}>
                Copyright &copy; {1900 + new Date().getYear()}{" "}
                <a
                  href="https://www.eccjan.com"
                  className={classes.aClasses}
                >
                  Ecclesia
                </a>
              </div>
            </div>
          }
        >
         
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onNavStateChanged: NavStateChanged, onLocationSelected: LocationSelected }, dispatch);  
}

EcommercePage = connect(mapStateToProps, mapDispatchToProps)(EcommercePage);
export default withStyles(styles)(EcommercePage);

