//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UserLoggedIn, CustomerActivated, CustomersLoaded } from 'redux/actions';

//firebase
import { auth, db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";

//icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

import logo from "assets/img/logo.png";

function compareName(a,b) {

  var aName = a.Name?.toString().toLowerCase();
  var bName = b.Name?.toString().toLowerCase();

  if (aName < bName)
    return -1;
  if (aName > bName)
    return 1;
  return 0;
}

function compareCompanyName(a,b) {

  var aName = a.company_name?.toString().toLowerCase();
  var bName = b.company_name?.toString().toLowerCase();

  if (aName < bName)
    return -1;
  if (aName > bName)
    return 1;
  return 0;
}

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.onUserLoggedIn = this.onUserLoggedIn.bind(this);
    this.showHelp = this.showHelp.bind(this);
    this.showLogin = this.showLogin.bind(this);
    this.needHelp = this.needHelp.bind(this);
    this.cancelHelp = this.cancelHelp.bind(this);
    this.sendPasswordReset = this.sendPasswordReset.bind(this);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password:"",
      error:null,
      loggedIn: false,
      user: null,
      showHelp: false
    };
  }

  emailChange(e) {
    this.setState({email: e.target.value});
  }
  
  passwordChange(e) {
    this.setState({password: e.target.value});
  }

  onUserLoggedIn(id) {

    //id = '010fb5cc-85c0-4f10-bd83-54b64b69ab52';
    //id = 'ed031582-8ebc-41cf-975f-4a796a54b130'; //jjimenez
    //console.log(id);
    db.getUser(id).then(doc => {

      var user = doc.data();
      //console.log(user);
      
      this.setState({ user: user }, () => {
        
        if(user.userType === 'Affiliate' || user.userType === 'admin') {
          var promises = [];

          for(var i = 0; i < user.Customers.length; i++) {
            promises.push(db.getCustomer(user.Customers[i].id));  
          }
          
          Promise.all(promises).then((dbResults) => { 

            dbResults.sort(compareCompanyName);
            this.props.onCustomersLoaded(dbResults);

            user.customerID = dbResults[0].id;

            for(var j = 0; j < user.Customers.length; j++)
              if(user.Customers[j].id === user.customerID) {
                user.contactID = user.Customers[j].contactID;
                break;
              }

            user.Customers.sort(compareName);

            this.finalizeLogin(user);
          });

        } else {
          db.getCustomer(user.customerID).then(customer => {
            console.log("COMPANY NAME SET AS: " + customer.company_name);
            
            user.companyName = customer.company_name;
            this.finalizeLogin(user);
          })
        }
      });
    });
  }

  finalizeLogin(user) {
    db.logActivity(user.id, user.customerID, "Login-App");
    
    this.props.onUserLoggedIn(
      { 
        id: user.id, 
        email: user.Email, 
        firstName: user.FirstName, 
        lastName: user.LastName, 
        customerID: user.customerID, 
        contactID: user.contactID, 
        addresses: user.Addresses, 
        userType: user.userType, 
        customers: user.Customers, 
        companyName: user.companyName,
        budgetingPermissions: user.BudgetingPermissions,
        invoicePermissions: user.InvoicePermissions,
        orderingPermissions: user.OrderingPermissions,
        commissionType: user.commissionType,
        baseCommission: user.baseCommission,
        floorCommission: user.floorCommission,
        parent: user.parent
      }
    );

    this.setState({loggedIn: true});
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    document.getElementById('divErrors').style.display='none';

    auth.doSignInWithEmailAndPassword(email, password)
      .then(authUser => {

        //racielle  
        //this.onUserLoggedIn('e2b02d0e-de73-4a73-a2f0-241f0e5ab7b9');
        this.onUserLoggedIn(authUser.user.uid);
    
      })
      .catch(error => {

        switch(error.code) {
          case "auth/user-not-found":
            this.setState({ error: "This email and password combination was not found." });
            break;
          case "auth/custom-disabled":
            this.setState({ error: "Due to inactivity, your account has been placed on hold. Please call us 844 432-2526 or email us at sales@eccjan.com to enable your account." });
            break;
          default:
            this.setState({ error: error.message });  
        }
        document.getElementById('divErrors').style.display='block';
      });

    event.preventDefault();
  }

  sendPasswordReset() {
    auth.sendPasswordReset(this.state.email);
    this.cancelHelp();
  }

  cancelHelp() {
    this.setState({showHelp: false});
  }

  needHelp() {
    this.setState({showHelp: true});
  }

  showHelp() {
    if(this.state.showHelp) {
      return "flex";
    } else {
      return "none";
    }
  }

  showLogin() {
    if(!this.state.showHelp) {
      return "flex";
    } else {
      return "none";
    }
  }

  render() {
    const { classes } = this.props;
  
    if(this.state.loggedIn)
      return <Redirect to="/reload" />

    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundColor:"#696969"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={4}>
                <Card className={classes[this.state.cardAnimaton]} style={{display:this.showLogin(), minHeight:"389px"}}>
                  <form className={classes.form} onSubmit={this.onSubmit} >
                    <CardHeader color="success" className={classes.cardHeader}>
                      <h4>Login</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Email..."
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.emailChange}
                      />
                      <CustomInput
                        labelText="Password"
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputIconsColor}
                              />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.passwordChange}
                      />
                      <div id='divErrors' className={classes.divider} >
                        {this.state.error}
                      </div>
                    </CardBody>
                    <CardFooter className={classes.cardFooter} >
                      <GridContainer>
                        <GridItem xs={12}>
                          <br />
                          <Button color="success" size="lg" type="submit" fullWidth>
                            Login
                          </Button>
                        </GridItem>
                        <GridItem xs={12}>
                          <Button color="success" simple size="lg" onClick={this.needHelp} fullWidth>
                            Need Help?
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                  </form>
                </Card>
                <Card className={classes[this.state.cardAnimaton]} style={{display:this.showHelp(), minHeight:"389px"}}>
                  <CardHeader color="success" className={classes.cardHeader}>
                      <h4>Help</h4>
                    </CardHeader>
                    <CardBody>
                      <br />
                      If you forgot your password we can send and email
                      to the address on file so you can reset it.
                      <CustomInput
                        labelText="Email..."
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.emailChange}
                        value={this.state.email}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter} >
                      <GridContainer>
                        <GridItem xs={12}>
                          <br />
                          <Button color="success" size="lg" fullWidth onClick={this.sendPasswordReset}>
                            Send Password Reset Email
                          </Button>
                        </GridItem>
                        <GridItem xs={12}>
                          <Button color="success" simple size="lg" onClick={this.cancelHelp} fullWidth>
                            Go Back
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} style={{textAlign:"center"}}>
                <img src={logo} style={{width:"200px"}} alt="logo" />
              </GridItem>
            </GridContainer>
          </div>
         
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onUserLoggedIn: UserLoggedIn, 
    onCustomerActivated: CustomerActivated,
    onCustomersLoaded: CustomersLoaded }, dispatch);  
}

LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default withStyles(loginPageStyle)(LoginPage);
