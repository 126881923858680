//react
import React from 'react';

//router
import { Redirect } from 'react-router-dom';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserLoggedIn } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.jsx';

//core
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

//components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class CustomerChooser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      customerChosen: false,
    };
  }

  /*
  componentWillReceiveProps(nextProps){
    this.setState({modal: nextProps.isOpen});
  }
  */
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ modal: this.props.isOpen });
    }
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  chooseCustomer(customer) {
    var user = this.props.user;

    db.getShipToAddressesByCustomer(customer.id).then((addresses) => {
      var addressIDs = [];
      for (var i = 0; i < addresses.length; i++)
        addressIDs.push(addresses[i].address_id);

      for (var j = 0; j < user.customers.length; j++)
        if (user.customers[j].id === customer.id) {
          user.contactID = user.customers[j].contactID;
          break;
        }

      var updatedUser = {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        customerID: customer.id,
        contactID: user.contactID,
        addresses: addressIDs,
        userType: user.userType,
        customers: user.customers,
        commissionType: user.commissionType,
        baseCommission: user.baseCommission,
        floorCommission: user.floorCommission,
        parent: user.parent
      };

      this.props.onUserLoggedIn(updatedUser);
      this.setState({ customerChosen: true });
      //this.props.onCustomerChosen();
    });
  }

  showSelectButton(customer) {
    if (customer.contact_id === this.props.user.customerID) return 'none';
    else return 'block';
  }

  render() {
    const { classes } = this.props;

    if (this.state.customerChosen === true) {
      //this.setState({customerChosen: false});
      return <Redirect to="/reload" />;
    }

    return (
      <div>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="sm"
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={12}>
                <h4 className={classes.modalTitle}>Choose a Customer</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{
              backgroundColor: '#efefef',
              minHeight: '432px',
              maxHeight: '432px',
              overflowY: 'scroll',
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '89px' }} />
                  <TableCell>Company Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.customers.map((customer, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: '89px', padding: '3px' }}>
                      <Button
                        color="success"
                        size="sm"
                        onClick={() => this.chooseCustomer(customer)}
                        style={{ display: this.showSelectButton(customer) }}
                      >
                        {' '}
                        Switch{' '}
                      </Button>
                    </TableCell>
                    <TableCell>{customer.company_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <br />
            <br />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onUserLoggedIn: UserLoggedIn }, dispatch);
}

CustomerChooser = connect(mapStateToProps, mapDispatchToProps)(CustomerChooser);
export default withStyles(modalStyle)(CustomerChooser);
