import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.jsx";

//core
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from '@material-ui/core/IconButton';

//components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Footer from "components/Footer/Footer.jsx";
import Invoice from "components/Invoice/Invoice.jsx";
import InvoiceNotes from "components/InvoiceNotes/InvoiceNotes.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

//icons
import Search from "@material-ui/icons/Search";
import Notes from "@material-ui/icons/SpeakerNotes";
import Receipt from "@material-ui/icons/Receipt";
import Payment from "@material-ui/icons/Payment";
import Download from "@material-ui/icons/CloudDownload";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class InvoicesList extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
          invoices: [],
          recentInvoices: [],
          currentInvoices: [],
          overdueInvoices: [],
          notes: [],
          selected: "recent",
          isLoading: true,
          moreRows: false,
          queryText: "",
          searchResults: {},
          openSearchResult: false,
          searchNotFound: false,
          current: 0.00,
          overdue: 0.00,
          balance: 0.00,
          recentNotes: [],
          payments: [],
          orders: []
        };

        this.queryTextChange = this.queryTextChange.bind(this);
        this.queryTextOnKeyDown = this.queryTextOnKeyDown.bind(this);
        this.search = this.search.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getCurrent = this.getCurrent.bind(this);
        this.getPastDue = this.getPastDue.bind(this);
        this.getRecent = this.getRecent.bind(this);
        
        this.refresh();
    }

    
    attachNotes(invoices) {
        
        for(var i = 0; i < invoices.length; i++) {
            invoices[i].discussion = [];
            for(var j = 0; j < this.state.notes.length; j++) {
                if(invoices[i].id === this.state.notes[j].invoiceID) {
                    invoices[i].discussion.push(this.state.notes[j]);
                }
            }
        }
    }

    refresh() {

        db.getInvoiceNotes(this.props.user.customerID).then(notes => {

            this.setState({notes: notes}, () => {

                db.getInvoicesByCustomer(this.props.user.customerID).then(invoices => {
            
                    this.attachNotes(invoices);
    
                    this.setState({recentInvoices: invoices, invoices: invoices, isLoading: false });
                    if(invoices.length === 50)
                        this.setState({moreRows: true});
                });
        
                db.getInvoicesByCustomerAndStatus(this.props.user.customerID, 'overdue').then(overdue => {
                    
                    db.getInvoicesByCustomerAndStatus(this.props.user.customerID, 'sent').then(sent => {
                        
                        var totalOverdue = 0.00;
                        for(var i = 0; i < overdue.length; i++) {
                            totalOverdue = Number(totalOverdue) + Number(overdue[i].balance);
                            //console.log(overdue[i].invoice_number + ": " + overdue[i].balance + ': total: ' + totalOverdue);
                        }
        
                        var totalSent = 0.00;
                        for(var j = 0; j < sent.length; j++) 
                            totalSent = Number(totalSent) + Number(sent[j].balance);
        
                        var balance = (Number(totalOverdue) + Number(totalSent)).toFixed(2);
                        
                        this.attachNotes(overdue);
                        this.attachNotes(sent);

                        this.setState({ 
                            current: totalSent.toFixed(2), 
                            overdue: totalOverdue.toFixed(2), 
                            balance: balance,
                            currentInvoices: sent, 
                            overdueInvoices: overdue
                         });
                    }); 
                });

                if(this.props.user.userType === 'Affiliate' || this.props.user.userType === 'admin') {
                    db.getOrdersByCustomer(this.props.user.customerID).then(orders => {
                        this.setState({orders: orders});
                    });
                }
            })
        });

        db.getPaymentsByCustomer(this.props.user.customerID).then(payments => {
            this.setState({payments: payments});
        });

        db.logActivity(this.props.user.id, this.props.user.customerID, "View-Invoices");
    
    }

    getCurrent() {
        this.setState({invoices: this.state.recentInvoices, selected: "current" })
    }

    getPastDue() {
        this.setState({invoices: this.state.overdueInvoices, selected: "overdue" })
    }

    getRecent() {
        this.setState({invoices: this.state.recentInvoices, selected:"recent" })
    }

    loadMore() {
        var invoices = this.state.recentInvoices;

        var index = invoices.length - 1;
        var startAt = invoices[index].invoice_id;
        db.getInvoicesByCustomer(this.props.user.customerID,startAt).then(newSet => {

            this.attachNotes(newSet);
            
            for(var i = 0; i < newSet.length; i++) 
                invoices.push(newSet[i]);
            
            this.setState({invoices: invoices, recentInvoices: invoices});
            if(newSet.length < 50)
                this.setState({moreRows: false});
        });
    }

    showNoResults() {
        if(this.state.invoices.length === 0 && !this.state.isLoading) {
            return "block";
        } else {
            return "none";
        }
    }

    showLoading() {
        if(this.state.isLoading) {
            return "block";
        } else {
            return "none";
        }
    }

    showLoadMore() {
        if(this.state.moreRows && this.state.selected === 'recent') {
            return "block";
        } else {
            return "none";
        }
    }

    getBackgroundColor(index) {
        if(index % 2 === 0) 
            return "#efefef";
        else
            return "#ffffff"
    }

    queryTextChange(e) {
        this.setState({queryText:e.target.value})
    }

    queryTextOnKeyDown(e) {
        if (e.key === 'Enter') {
            this.search();
        }
    }

    search() {
        
        var queryText = this.state.queryText.trim().toUpperCase();
        if(queryText.indexOf('ECC-') === -1)
            queryText = 'ECC-' + queryText;

        
        db.getInvoice(this.props.user.customerID,queryText).then(invoice => {

            if (!invoice) {console.warn("No result for: ", queryText); return}

            invoice.discussion = [];
            for(var j = 0; j < this.state.notes.length; j++) {
                if(invoice.id === this.state.notes[j].invoiceID) {
                    invoice.discussion.push(this.state.notes[j]);
                }
            }

            if(invoice) {
                this.setState({searchResult: invoice, searchNotFound: false }, () => {
                    this.setState({openSearchResult: true}, console.log(this.state.openSearchResult));
                });
            }
            else 
                this.setState({searchNotFound: true })
        });
    }

    showSearchNotFound() {
        if(this.state.searchNotFound) {
            return "block";
        } else {
            return "none";
        }
    }

    getBalanceColor() {
        if(this.state.overdue > 0) {
            return "danger";
        } else {
            return "success";
        }
    }

    getStatusDescriptor(value) {
        if(value.toUpperCase() === 'OVERDUE') {
            return <div style={{color:"#ef5350"}}>PAST DUE</div>
        } else if (value.toUpperCase()=== 'SENT') {
            return <div style={{color:"green"}}>OPEN</div>
        } else if (value.toUpperCase()=== 'PAID') {
            return <div style={{color:"green"}}>PAID</div>
        }
    }

    getDueDateColor(value) {
        if(value.toUpperCase() === 'OVERDUE') {
            return "#ef5350";
        } else {
            return "black";
        }
    }

    getNotesDescriptor(value) {
        if(value.length > 0) {
            return <div style={{top:"6px", position:"relative"}}> <Notes /> </div>;
        } else {
            return "";
        }
    }

    trim(value, maxLength) {
        if(value) {
            if(value.trim().length > maxLength)
                return value.trim().substring(0,maxLength) + "...";
            else
                return value.trim();
        } else return "";
    }

    downloadFile(blob, fileName){
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], {type: "application/pdf"})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
        } 

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download=fileName;
        link.click();
    }

    downloadPayment(paymentID, paymentNumber) {
        
        var url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/getZohoPaymentPDF";
    
        var data = {
            "paymentID" : paymentID
          }
    
        fetch(url, {
            method: "POST", 
            cache: "no-cache",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        })
        .then((response) => {
            console.log(response);
            if(response.ok) {
                return response.blob()
            } else {
            console.log("error1");  
            console.log(response);
            }
        })
        .then((blob) => {
            this.downloadFile(blob, paymentNumber + ".pdf");
        })
        .catch((error) => {
            // If there is any error you will catch them here
            console.log("error getting invoice attachment from zoho: " + error);
        });
    }

    secondaryPDFs(payment) {
        
        if(payment.combinedPayments) {
            return <div>
                {payment.combinedPayments.map((combined,index) => (
                    <IconButton key={index} onClick={() => this.downloadPayment(combined, payment.payment_number + "-" + index)} ><Download /></IconButton>
                ))}
            </div>
        } else {
            return "";
        }
    }

    getCommission(invoice) {
        
        if(this.props.user.userType === 'Affiliate' || this.props.user.userType === 'admin') {

            for(var i = 0; i < this.state.orders.length; i++) {

                if(this.state.orders[i].salesOrderID === invoice.salesorder_id && this.state.orders[i].salesOrderID !== "") {

                    //console.log('invoice:' + invoice.invoice_id);
                    //console.log('salesorder_id:' + invoice.salesorder_id);
                    //console.log(this.state.orders[i]);

                    if(this.state.orders[i].commission)
                        return this.state.orders[i].commission;
                    else
                        return 0.00;
                }
            }

            return 0.00;
        } else 
        
            return "";
    }

    showCommission() {
        if( (this.props.user.userType === 'Affiliate' || this.props.user.userType === 'admin') 
            && ( this.props.user.id === '4cc4b166-f706-4fbe-b042-ea1669c05635' //walt
                || this.props.user.id === 'bxskvRHRPva4yGO97Hbi35uOYIW2' //michael
                || this.props.user.id === '6pTsX4vV6Iebf1z00w7kwb7qkBs2' //bo
                || this.props.user.id === '3aacd272-8260-4db8-b11a-e81412ce616b' //bo affiliate
                 ) ) { //in progress work, temporarily hidden
            return "table-cell";
        } else {
            return "none";
        }
    }

    render() {
        const { classes } = this.props;
        
        if (!this.props.user) {
            console.log('redirecting')
            return <Redirect to='/login' />
        } else if (!this.props.user.invoicePermissions && this.props.user.userType !== 'admin' && this.props.user.userType !== 'Affiliate') {
            console.log('redirecting')
            return <Redirect to='/login' />
        } 
    
        return (
          <div>
            <Header
              brand="Ecclesia"
              links={<HeaderLinks dropdownHoverColor="info" />}
              fixed
              color="dark"
            />
            <div className={classes.container} style={{minHeight:"369px", marginTop:"100px"}}>
                <GridContainer>
                    <GridItem xs={6}>
                        <h3 className={classes.cardTitle}>Invoices</h3>
                    </GridItem>
                    <GridItem xs={5} style={{textAlign:"right"}}>
                        <div style={{display:"inline-block"}}>
                          <CustomInput
                            labelText="Search"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              placeholder: "Invoice Number"
                            }}
                            onChange={this.queryTextChange}
                            onKeyDown={this.queryTextOnKeyDown}
                            value={this.state.queryText}
                          />
                        </div>
                        <div style={{display:"inline-block", position:"relative", top:"24px"}}>
                            <IconButton onClick={this.search}>
                                <Search />
                            </IconButton>
                        </div>
                        <div style={{display:this.showSearchNotFound(), color:"maroon", fontSize:"13px", textAlign:"right", marginRight:"50px"}}>
                            Invoice Not Found
                        </div>
                    </GridItem>
                    <GridItem xs={1} style={{position:"relative", top:"23px"}}>
                        <InvoiceNotes notes={this.state.notes} />
                    </GridItem>

                    <GridItem xs={12} style={{marginTop:"23px"}}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card style={{cursor:"pointer"}} onClick={this.getCurrent}>
                                    <CardHeader color="success" >
                                        <h3 className={classes.cardTitle} style={{textAlign:"right", color:"white"}}>
                                            ${numberWithCommas(this.state.current)}
                                        </h3>
                                    </CardHeader>
                                    <CardFooter>
                                        <div className={classes.stats} style={{marginTop:"13px", width:"100%", color:"#545454", textAlign:"right"}}>
                                            Current Invoices
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card style={{cursor:"pointer"}} onClick={this.getPastDue}>
                                    <CardHeader color={this.getBalanceColor()} >
                                        <h3 className={classes.cardTitle} style={{textAlign:"right", color:"white"}}>
                                            ${numberWithCommas(this.state.overdue)}
                                        </h3>
                                    </CardHeader>
                                    <CardFooter>
                                        <div className={classes.stats} style={{marginTop:"13px", width:"100%", color:"#545454", textAlign:"right"}}>
                                            Past Due Invoices
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card style={{cursor:"pointer"}} onClick={this.getRecent}>
                                    <CardHeader color="info" >
                                        <h3 className={classes.cardTitle} style={{textAlign:"right", color:"white"}}>
                                            ${numberWithCommas(this.state.balance)}
                                        </h3>
                                    </CardHeader>
                                    <CardFooter>
                                        <div className={classes.stats} style={{marginTop:"13px", width:"100%", color:"#545454", textAlign:"right"}}>
                                            Total Balance
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                    <GridItem xs={12}>
                        <CustomTabs
                            headerColor="success"
                            tabs={[
                                {
                                    tabName: "Invoices",
                                    tabIcon: Receipt,
                                    tabContent: (
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{paddingRight:"6px"}}>
                                                        
                                                    </TableCell>
                                                    <TableCell style={{ paddingRight:"6px"}}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"right", paddingRight:"6px" }}>
                                                        Number    
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"right", paddingRight:"6px" }}>
                                                        Total
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"right", paddingRight:"6px", display:this.showCommission() }}>
                                                        Commission
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"right", paddingRight:"6px" }}>
                                                        Due Date
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"right", paddingRight:"6px" }}>
                                                        Status
                                                    </TableCell>
                                                    <TableCell style={{paddingRight:"6px"}}>
                                                        Notes
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.invoices.map((invoice,index) => (
                                                    <TableRow key={index} style={{backgroundColor:this.getBackgroundColor(index)}}>
                                                        <TableCell style={{paddingRight:"6px"}}>
                                                            <Invoice invoice={invoice} />
                                                        </TableCell>
                                                        <TableCell style={{paddingRight:"6px"}}>
                                                            {invoice.date}
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right", paddingRight:"6px" }}>
                                                            {invoice.invoice_number}
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right", paddingRight:"6px" }}>
                                                            ${invoice.total.toFixed(2)}
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right", paddingRight:"6px", display:this.showCommission() }}>
                                                            ${this.getCommission(invoice)}
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right", paddingRight:"6px", color:this.getDueDateColor(invoice.status) }}>
                                                            {invoice.due_date}
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right", paddingRight:"6px" }}>
                                                            {this.getStatusDescriptor(invoice.status)}
                                                        </TableCell>
                                                        <TableCell style={{paddingRight:"6px"}}>
                                                            {this.getNotesDescriptor(invoice.discussion)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <div onClick={this.loadMore} style={{color:"green", textAlign:"center", width:"100%", padding:"23px", cursor:"pointer", display:this.showLoadMore()}}>
                                            Load More
                                        </div>
                                        <div style={{display:this.showLoading(), textAlign:"center", marginTop:"89px", marginBottom:"89px"}}>
                                            <CircularProgress />
                                        </div>
                                        <div style={{display:this.showNoResults(), textAlign:"center", color:"maroon", fontSize:"12px", marginTop:"89px", marginBottom:"89px"}}>
                                            Sorry, we did not find any invoices.
                                        </div>
                                    </div>
                                    )
                                },
                                {
                                    tabName: "Payments",
                                    tabIcon: Payment,
                                    tabContent: (
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{paddingRight:"6px"}}>
                                                        
                                                    </TableCell>
                                                    <TableCell style={{ paddingRight:"6px", width:"100px"}}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell style={{paddingRight:"6px" }}>
                                                        Reference #
                                                    </TableCell>
                                                    <TableCell style={{paddingRight:"6px" }}>
                                                        Invoices
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"right", paddingRight:"6px" }}>
                                                        Amount
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.payments.map((payment,index) => (
                                                    <TableRow key={index} style={{backgroundColor:this.getBackgroundColor(index)}}>
                                                        <TableCell style={{padding:"6px", verticalAlign:"top"}}>
                                                            <IconButton onClick={() => this.downloadPayment(payment.payment_id, payment.payment_number)}>
                                                                <Download />
                                                            </IconButton>
                                                            {this.secondaryPDFs(payment)}
                                                        </TableCell>
                                                        <TableCell style={{padding:"6px", width:"100px", verticalAlign:"top"}}>
                                                            {payment.date}
                                                        </TableCell>
                                                        <TableCell style={{padding:"6px", verticalAlign:"top" }}>
                                                            {payment.reference_number}
                                                        </TableCell>
                                                        <TableCell style={{padding:"6px", verticalAlign:"top" }}>
                                                            {payment.invoice_numbers}
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right", verticalAlign:"top", padding:"6px" }}>
                                                            ${numberWithCommas(payment.amount.toFixed(2))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    )
                                }
                            ]}
                        />
                        
                    </GridItem>
                    
                    
                </GridContainer>
            </div>
            <div >
                
                <Invoice invoice={this.state.searchResult} isOpen={this.state.openSearchResult} onClose={() => this.setState({openSearchResult: false})}  />
                
            </div>
            <Footer
                content={
                    <div>
                    <div className={classes.right} style={{fontSize:"13px"}}>
                        &copy; {1900 + new Date().getYear()} Ecclesia, Inc.
                    </div>
                    </div>
                }
            />
          </div>
        );
    }
}

const mapStateToProps = state => {
  return state;
}

InvoicesList = connect(mapStateToProps)(InvoicesList);
export default withStyles(shoppingCartStyle)(InvoicesList);
