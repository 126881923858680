//react
import React from "react";

//routing
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { AffiliateLoaded, BudgetExceptionsLoaded, CartCleared, 
    LocationsLoaded, PricingLoaded, SpecialsLoaded, TaxExemptionsLoaded, 
    VisibilityLoaded } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

//algolia full text search
import { algolia } from 'assets/algolia';

//components
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//dates and times
import moment from 'moment';

class ReloadPage extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          loaded: false
        };
    }

    componentDidMount() {

        this.setCompanyName();
        this.props.onCartCleared();

        var promises = [];

        promises.push(this.getAffiliate());

        promises.push(this.loadLocations());

        promises.push(this.loadBudgetExceptions());
        promises.push(this.loadPricing());
        promises.push(this.loadSpecials());
        promises.push(this.loadVisibility());
        promises.push(this.loadTaxExemptStatus());

        Promise.all(promises).then( () => {
            this.setState({loaded: true});
        });
    }

    getAffiliate() {

        var floorPercentage = 1.00;

        if(this.props.user.customerID === '1343284000001266251') { 
            
            //affiliates using the demo company for estimates
            var affiliate = this.props.user;
            console.log('affiliate:')
            console.log(affiliate);
            if(affiliate.baseCommission && affiliate.commissionType && affiliate.customers) {

                for(var i = 0; i < affiliate.customers.length; i++) {
                    if(affiliate.customers[i].id === '1343284000001266251') {
                        //console.log('customer found:')
                        //console.log(affiliate.Customers[i]);

                        if(affiliate.customers[i].FloorPercentage) {    
                            floorPercentage = (Number(1) + (Number(affiliate.customers[i].FloorPercentage) / 100)).toFixed(2);
                            //console.log('custom floor %: ' + floorPercentage);
                        } 

                        break;
                    }
                }

                this.props.onAffiliateLoaded({
                    baseCommission: Number(affiliate.baseCommission),
                    commissionType: affiliate.commissionType,
                    firstName: affiliate.firstName,
                    floorCommission: Number(affiliate.floorCommission),
                    floorPercentage: floorPercentage,
                    id: affiliate.id,
                    lastName: affiliate.lastName,
                    parent: affiliate.parent
                });    
            } else {

                // someone using the demo company not setup properly as an affiliate
                this.props.onAffiliateLoaded({
    
                })
            }
        }
        else {
            //console.log("customerID:" + this.props.user.customerID);
            db.getCompany(this.props.user.customerID).then(company => {

                if(company) {
                    if(company.affiliate) {

                        // a normal company with an assigned affiliate
                        db.getAffiliate(company.affiliate).then(affiliate => {
                            //console.log('affiliate:');
                            //console.log(affiliate);
    
                            for(var i = 0; i < affiliate.Customers.length; i++) {
                                if(affiliate.Customers[i].id === this.props.user.customerID) {
                                    //console.log('customer found:')
                                    //console.log(affiliate.Customers[i]);
    
                                    if(affiliate.Customers[i].FloorPercentage) {    
                                        floorPercentage = (Number(1) + (Number(affiliate.Customers[i].FloorPercentage) / 100)).toFixed(2);
                                        //console.log('custom floor %: ' + floorPercentage);
                                    } 
    
                                    break;
                                }
                            }
    
                            this.props.onAffiliateLoaded({
                                baseCommission: Number(affiliate.baseCommission),
                                commissionType: affiliate.commissionType,
                                firstName: affiliate.FirstName,
                                floorCommission: Number(affiliate.floorCommission),
                                floorPercentage: floorPercentage,
                                id: affiliate.id,
                                lastName: affiliate.LastName
                            });
                        });
                    } else {
    
                        // a normal company without an assigned affiliate
                        this.props.onAffiliateLoaded({
        
                        })
                    }
                } else {
    
                    // a normal company without an assigned affiliate
                    this.props.onAffiliateLoaded({
    
                    })
                }
            });
        }
    }

    setCompanyName() {
        
        var user = this.props.user;

        if(user.customers) { // only applicable for admins and affiliates
            for(var x = 0; x < user.customers.length; x++) {
                if(user.customers[x].id === user.customerID) {
                  user.companyName = user.customers[x].Name;
                  break;
                }
            }
        }
    }

    loadLocations() {
    
        return new Promise((resolve, reject) => {

            var id = this.props.user.id; // for internet customers
            if(this.props.user.customerID)
                id = this.props.user.customerID; // for managed customers
        
            db.getShipToAddressesByCustomer(id).then(result => {
        
                var filtered = [];
                var addresses = [];

                if(result)
                    addresses = result;
                
                for(var i = 0; i < addresses.length; i++) {
                    if(this.props.user.addresses) { // filter for permissions 
                    
                        for(var j = 0; j < this.props.user.addresses.length; j++) {
                            if(addresses[i].address_id === this.props.user.addresses[j]) {            
                                if(addresses[i].state) 
                                    filtered.push(addresses[i]);
                    
                                break;
                            }
                        }
                    } 
                    else 
                    { // all-access baby!
                        filtered.push(addresses[i]);
                    }
                }
            
                filtered.sort(this.sortByLabel);
            
                //console.log('locations loaded: ' + filtered.length);

                this.props.onLocationsLoaded(filtered);
                resolve();
            })
        });
    }
    
    loadBudgetExceptions() {
    
        return new Promise((resolve, reject) => {

            var exceptions = [];
            var promises = [];
        
            db.getBudgetExceptionsByCustomer(this.props.user.customerID).then((results) => {
        
                for(var i = 0; i < results.length; i++) {
                    
                    if(results[i].productID) { // individual budget exceptions

                        exceptions.push(results[i]);

                    } else { // pricing rules
                    
                        var filter = results[i].filter;
                        var promise = algolia.getProductsIDs("", filter.manufacturers, filter.category, filter.subCat1, filter.subCat2, filter.subCat3, results[i].discount);
                        promises.push(promise);
                        
                    }
                }
            
                if(promises.length > 0) { // if they have any category rules
            
                    Promise.all(promises).then((queries) => {
            
                        for(var j = 0; j < queries.length; j++) 
                            for(var k = 0; k < queries[j].length; k++) 
                                exceptions.push({ productID: queries[j][k].id, discount: Number(queries[j][k].referenceData) })
            
                        //console.log('budget exceptions loaded: ' + exceptions.length);

                        this.props.onBudgetExceptionsLoaded(exceptions);
                        resolve();
                    });

                } else { // otherwise we're done

                    //console.log('budget exceptions loaded: ' + exceptions.length);

                    this.props.onBudgetExceptionsLoaded(exceptions);
                    resolve();
                }
            });
        });
    }

    getExceptions(type) {
        return new Promise((resolve, reject) => {

            db.getCompany(this.props.user.customerID).then((company) => {

                if(company) {
                    
                    var profileID = "none";
                    if(type === "pricing")
                        profileID = company.pricingProfile;
                    else if (type === "visibility")
                        profileID = company.visibilityProfile;

                    if(profileID !== "none") {
                        db.getExceptionsByProfile(profileID).then((exceptions) => {
                            resolve(exceptions);
                        });

                    } else {
                        resolve();
                    }
                } else {
                    resolve();
                }
            });
        });
    }

    processExceptions(type, exceptions) {

        return new Promise((resolve, reject) => {

            if(exceptions) {   

                var processed = [];
                var promises = [];

                for(var i = 0; i < exceptions.length; i++) {
                            
                    if(exceptions[i].productID) { // individual exception

                        processed.push({ productID: exceptions[i].productID, Price: exceptions[i].Price });
                        
                    } else { // rules
                    
                        var filter = exceptions[i].filter;
                        var promise = algolia.getProductsIDs("", filter.manufacturers, filter.category, filter.subCat1, filter.subCat2, filter.subCat3, exceptions[i]);
                        promises.push(promise);
                    }
                }
            
                if(promises.length > 0) { // if they have any category rules
            
                    Promise.all(promises).then((queries) => {
            
                        if(type === 'pricing') {
                            for(var j = 0; j < queries.length; j++) 
                                for(var k = 0; k < queries[j].length; k++) {
                                    var rule = queries[j][k].referenceData;

                                    var percentage = 0;
                                    var ruleType = "discount";
                                    if(rule.discount)
                                        percentage = Number(rule.discount);
                                    if(rule.percentage) {
                                        percentage = Number(rule.percentage);
                                        ruleType = rule.ruleType;
                                    }
                                        
                                    processed.push({ productID: queries[j][k].id, percentage: percentage, ruleType: ruleType });
                                    
                                }

                        } else if (type === 'visibility') {
                            for(var l = 0; l < queries.length; l++) 
                                for(var m = 0; m < queries[l].length; m++) 
                                    processed.push({ productID: queries[l][m].id })
                                
                        }

                        resolve(processed);
                    });

                } else { // otherwise we're done

                    resolve(processed);
                    
                }
            } else resolve();
        });
        
    }

    loadPricing() {

        return new Promise((resolve, reject) => {

            this.getExceptions("pricing").then(pbe => { // profile based exceptions
                this.processExceptions("pricing", pbe).then((profileExceptions) => {

                    if(!profileExceptions) profileExceptions = [];
                    //console.log('profile exceptions (p): ' + profileExceptions.length);

                    db.getPricingByCustomer(this.props.user.customerID).then((cse) => { // customer specific exceptions
                        this.processExceptions("pricing", cse).then((customerExceptions) => {
                        
                            if(customerExceptions) { // overwrite profile exceptions if we have conflicting customer specific exceptions
                                //console.log('customer exceptions (p): ' + customerExceptions.length);

                                for(var i=0; i < customerExceptions.length; i++) {
                                    
                                    var isFound = false;
                                    for(var j = 0; j < profileExceptions.length; j++) {
                                        if(customerExceptions[i].productID === profileExceptions[j].productID) {
                                            profileExceptions[j] = customerExceptions[i];
                                            isFound = true;

                                            break;
                                        }
                                    }
                                    if(!isFound)
                                        profileExceptions.push(customerExceptions[i]);
                                }
                            }
        
                            //console.log('total exceptions (p): ' + profileExceptions.length);
                            //console.log(profileExceptions);
                         
                            const debugInfo = {
                                user: this.props.user,
                                profileExceptions: profileExceptions
                            }
                          
                            //    customerExceptions: _customerExceptions,
                            //    totalExceptions: _totalExceptions

                            fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/logDebugInfo", {
                                method: "POST", 
                                cache: "no-cache",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify(debugInfo)
                            })
                            .then((response) => {
                                
                                if(response.ok) {
                                    //console.log('debugInfo logged');
                                    //console.log(debugInfo)
                                } else {
                                    console.log("error (1): ");  
                                    console.log(response);
                                }
                            })
                            .catch((error) => {
                                // If there is any error you will catch them here
                                console.log("error (2):  " + error);
                            });

                            this.props.onPricingLoaded(profileExceptions);
                            resolve();                    
                        });
                    });
                });
            })
        });
    }

    loadSpecials() {

        return new Promise((resolve, reject) => {

            db.getCurrentFutureSpecials().then(specials => {
        
                var active = []; // will hold final results
                var profilePromises = [];

                
                specials.forEach(special => {
                    
                    if(special.from < moment().valueOf() && ["all", "customer-site"].includes(special.site)) {
                        special.products = [];
                        if(!special.image)
                            special.image = { url: "" }
                        active.push(special);
                    
                        profilePromises.push(db.getExceptionsByProfile(special.profile));
                    }
                })
                
                Promise.all(profilePromises).then((results) => {
        
                    var attachPromises = [];
        
                    for(var a = 0; a < active.length; a++) {
                        
                        for(var r = 0; r < results.length; r++) {
                            if(results[r][0]) {
                                if(active[a].profile === results[r][0].profileID) {
                                    // found the detailed results array for the profile
                                    attachPromises.push( this.attachProducts(active[a], results[r]) );
                                    break;
                                }
                            }
                        }
                    }

                    Promise.all(attachPromises).then((results) => { 

                        // have to do this so that any specials without products aren't lost
                        for(var i = 0; i < active.length; i++) {
                            for(var j = 0; j < results.length; j++) {
                            if(active[i].id === results[j].id) {
                                active[i] = results[j];
                                break;
                            }
                            }
                        }
                        this.props.onSpecialsLoaded(active);
                        resolve();
                    });
                });
            });
        });
    }

    attachProducts(special, exceptions) {
        return new Promise((resolve, reject) => {

            this.processExceptions('pricing', exceptions).then(exceptions => {
                //special.exceptions = exceptions;

                var promises = [];
                exceptions.forEach(exception => {
                    promises.push(db.getProduct(exception.productID));
                });

                Promise.all(promises).then((products) => {

                    // tweak prices

                    products.forEach(result => {

                        var p = {
                            productID:result.ProductID,
                            name:result.ProductName,
                            description:result.BriefDescription,
                            manufacturerID:result.ManufacturerID,
                            categoryID:result.CategoryID,
                            subCat1ID:result.SubCat1ID,
                            subCat2ID:result.SubCat2ID,
                            subCat3ID:result.SubCat3ID,
                            isVisible: result.IsVisible,
                            id: result.id,
                            brandID: result.BrandID,
                            casePark: result.CasePack,
                            size: result.Size,
                            cost: result.WholesalePrice,
                            listPrice: result.ListPrice
                        }
            
                        if(result.Photos) {
                            if(result.Photos.length > 0) {
                                for(var i = 0; i < result.Photos.length; i++) {
                                    if(result.Photos[i].isCover) {
                                        p.photo = result.Photos[i].URL;
                                    }
                                    if (result.Photos[i].isThumb) {
                                        p.thumbnail = result.Photos[i].URL;
                                    }
                                }
                            }
                        }
            
                        if(result.Attachments) {
                            p.attachments = result.Attachments;
                        } else {
                            p.attachments = [];
                        }
            
                        if(result.Content) {
                            p.Content = result.Content;
                        }
            
                        if(p.listPrice) // wtf?
                            special.products.push(p);

                    });

                    console.log(special);
                    resolve(special);

                });
            });
        });
    }

    loadTaxExemptStatus() {
            
        db.getTaxExemption(this.props.user.customerID).then(exemption => {
  
          var isExempt = false;
          if(exemption)
            if(exemption.isExempt)
              isExempt = true;
  
          this.props.onTaxExemptionsLoaded(isExempt);
        });
    }

    loadVisibility() {

        return new Promise((resolve, reject) => {

            this.getExceptions("visibility").then(pbe => { // profile based exceptions
                
                this.processExceptions("visibility", pbe).then((profileExceptions) => {

                    
                    if(!profileExceptions) profileExceptions = [];
                    //console.log('profile exceptions (v): ' + profileExceptions.length);

                    db.getVisibilityExceptionsByCustomer(this.props.user.customerID).then((cse) => { // customer specific exceptions
                        //console.log("cse:" + cse.length);

                        this.processExceptions("visibility", cse).then((customerExceptions) => {
                        
                            if(customerExceptions) { // overwrite profile exceptions if we have conflicting customer specific exceptions
                                //console.log('customer exceptions (v): ' + customerExceptions.length);
                                for(var i=0; i < customerExceptions.length; i++) {
                                    
                                    var isFound = false;
                                    for(var j = 0; j < profileExceptions.length; j++) {
                                        if(customerExceptions[i].productID === profileExceptions[j].productID) {
                                            profileExceptions[j] = customerExceptions[i];
                                            isFound = true;
                                            break;
                                        }
                                    }
                                    if(!isFound)
                                        profileExceptions.push(customerExceptions[i]);
                                }
                            }
        
                            //console.log('total exceptions (v): ' + profileExceptions.length);
                            this.props.onVisibilityLoaded(profileExceptions);
                            resolve();                    
                        });
                    });
                });
            })
        });
    }

    // how we sort adddresses
    sortByLabel(a,b) {

        var aName = a.name?.toString().toLowerCase();
        var bName = b.name?.toString().toLowerCase();
        
        if (aName < bName)
            return -1;
        if (aName > bName)
            return 1;
        return 0;
    }

    render() {

        if(this.state.loaded && (this.props.user.orderingPermissions || this.props.user.userType === 'admin' || this.props.user.userType === 'Affiliate'))
             return <Redirect to='/products' />
            //return <Redirect to='/landing' />
        else if (this.state.loaded && this.props.user.invoicePermissions ) 
            return <Redirect to='/invoices' />
        
        else return (
            
            <div style={{ textAlign:"center", backgroundColor:"#696969", color:"white", position: "fixed", top: "0px", bottom:"0px", left:"0px", right:"0px" }}>
                <GridContainer direction="row" >
                    <GridItem xs={12} sm={12} style={{marginTop:"123px"}}>
                        <CircularProgress color="secondary" />
                        <br />Logging In
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onAffiliateLoaded: AffiliateLoaded,
        onBudgetExceptionsLoaded: BudgetExceptionsLoaded,
        onCartCleared: CartCleared,
        onLocationsLoaded: LocationsLoaded,
        onPricingLoaded: PricingLoaded, 
        onSpecialsLoaded: SpecialsLoaded,
        onTaxExemptionsLoaded: TaxExemptionsLoaded,
        onVisibilityLoaded: VisibilityLoaded,
       }, dispatch);  
}
  
ReloadPage = connect(mapStateToProps, mapDispatchToProps)(ReloadPage);
export default ReloadPage;