//affiliate
export const AFFILIATE_LOADED = 'AFFILIATE_LOADED';

export function AffiliateLoaded(affiliateObj) {
  return {
    type: AFFILIATE_LOADED,
    affiliate: affiliateObj
  }
}

// alerts
export const ALERT_CREATED = 'ALERT_CREATED';

export function AlertCreated(alertObj) {
  return {
    type: ALERT_CREATED,
    alert: alertObj
  }
}

// budget exceptions
export const BUDGET_EXCEPTIONS_LOADED = 'BUDGET_EXCEPTIONS_LOADED';

export function BudgetExceptionsLoaded(exceptions) {
  return {
    type: BUDGET_EXCEPTIONS_LOADED,
    exceptions: exceptions
  }
}

// cart 
export const CART_PRODUCT_ADDED = 'CART_PRODUCT_ADDED';
export const CART_PRODUCT_REMOVED = 'CART_PRODUCT_REMOVED';
export const CART_CLEARED = 'CART_CLEARED';

export function CartProductAdded(product) {
  return {
    type: CART_PRODUCT_ADDED,
    product: product
  }
}

export function CartProductRemoved(product) {
  return {
    type: CART_PRODUCT_REMOVED,
    product: product
  }
}

export function CartCleared() {
  return {
    type: CART_CLEARED
  }
}

//customer
export const CUSTOMER_ACTIVATED = 'CUSTOMER_ACTIVATED';
export function CustomerActivated(customer) {
  return {
    type: CUSTOMER_ACTIVATED,
    customer: customer
  }
}

//customers
export const CUSTOMERS_LOADED = 'CUSTOMERS_LOADED';
export function CustomersLoaded(customers) {
  return {
    type: CUSTOMERS_LOADED,
    customers: customers
  }
}

// events
export const EVENT_CREATED = 'EVENT_CREATED';
export function EventCreated(eventObj) {
  return {
    type: EVENT_CREATED,
    alert: eventObj
  }
}

// locations
export const LOCATION_CREATED = 'LOCATION_CREATED';
export const LOCATIONS_LOADED = 'LOCATIONS_LOADED';
export const LOCATION_UPDATED = 'LOCATION_UPDATED';

export function LocationCreated(location) {
  return {
    type: LOCATION_CREATED,
    location: location
  }
}

export function LocationsLoaded(locations) {
  return {
    type: LOCATIONS_LOADED,
    locations: locations
  }
}

export function LocationUpdated(location) {
  return {
    type: LOCATION_UPDATED,
    location: location
  }
}

// navState
export const NAVSTATE_CHANGED = 'NAVSTATE_CHANGED';
export function NavStateChanged(navState) {
  return {
    type: NAVSTATE_CHANGED,
    navState: navState
  }
}

// org
export const ORG_LOADED = 'ORG_LOADED';
export const ORG_UPDATED = 'ORG_UPDATED';

export function OrgLoaded(orgObj) {
  return {
    type: ORG_LOADED,
    org: orgObj
  }
}

export function OrgUpdated(orgObj) {
  return {
    type: ORG_UPDATED,
    org: orgObj
  }
}

// products 
export const PRODUCT_CREATED = 'PRODUCT_CREATED';
export const PRODUCTS_LOADED = 'PRODUCTS_LOADED';
export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';

export function ProductCreated(product) {
  return {
    type: PRODUCT_CREATED,
    product: product
  }
}

export function ProductsLoaded(products) {
  return {
    type: PRODUCTS_LOADED,
    products: products
  }
}

export function ProductUpdated(product) {
  return {
    type: PRODUCT_UPDATED,
    product: product
  }
}

// pricing
export const PRICING_LOADED = 'PRICING_LOADED';
export function PricingLoaded(pricing) {
  return {
    type: PRICING_LOADED,
    pricing: pricing
  }
}

// shipTo
export const LOCATION_SELECTED = 'LOCATION_SELECTED';
export function LocationSelected(location) {
  return {
    type: LOCATION_SELECTED,
    location: location
  }
}

// special
export const SPECIAL_SELECTED = 'SPECIAL_SELECTED';
export function SpecialSelected(special) {
  return {
    type: SPECIAL_SELECTED,
    special: special
  }
}

// specials
export const SPECIALS_LOADED = 'SPECIALS_LOADED';
export function SpecialsLoaded(specials) {
  return {
    type: SPECIALS_LOADED,
    specials: specials
  }
}

// tax exempt
export const TAX_EXEMPTIONS_LOADED = 'TAX_EXEMPTIONS_LOADED';
export function TaxExemptionsLoaded(isExempt) {
  return {
    type: TAX_EXEMPTIONS_LOADED,
    isExempt: isExempt
  }
}

// user
export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const USER_LOGGEDOUT = 'USER_LOGGEDOUT';

export function UserLoggedIn(userObj) {
  return {
    type: USER_LOGGEDIN,
    user: userObj
  }
}

export function UserLoggedOut() {
  return {
    type: USER_LOGGEDOUT,
    user: null
  }
}

// visibility
export const VISIBILITY_LOADED = 'VISIBILITY_LOADED';

export function VisibilityLoaded(visibility) {
  return {
    type: VISIBILITY_LOADED,
    visibility: visibility
  }
}
