import React from "react";

//firebase
import { db } from 'assets/firebase';

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CartCleared, CartProductRemoved, LocationSelected } from 'redux/actions';

//affiliate
import { affiliate } from 'assets/affiliate';

//stripe
import {Elements, StripeProvider} from 'react-stripe-elements';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.jsx";

//core
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import Hidden from '@material-ui/core/Hidden';

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

//components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Checkout from "components/Checkout/Checkout.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

//kekaha
import AddressChooser from "components/AddressChooser/AddressChooser.jsx";
import Estimate from "components/Estimate/Estimate.jsx";
import RecurringOrder from "components/RecurringOrder/RecurringOrder.jsx";
import PricePopover from "components/PricePopover/PricePopover.jsx";

// @material-ui/icons
import Remove from "@material-ui/icons/RemoveShoppingCart";
import Receipt from "@material-ui/icons/Receipt";
import Note from "@material-ui/icons/SpeakerNotes";

// personalized stylesheet
import '../../assets/css/ShoppingCartPage.css';

class ShoppingCartPage extends React.Component {
  constructor(props) {
    super(props);

    this.qtyChange = this.qtyChange.bind(this);
    this.notesChange = this.notesChange.bind(this);
    this.poNumberChange = this.poNumberChange.bind(this);
    this.changeShipTo = this.changeShipTo.bind(this);
    this.calculateTax = this.calculateTax.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
    this.addressesLoaded = this.addressesLoaded.bind(this);

    this.state = {
      products: [],
      visibleProducts: [],
      manufacturers: [],
      tax: 0.00,
      taxDocument: {},
      taxResult: {},
      shipTo: "",
      shipToAddress: { },
      notes: "",
      poNumber: "",
      completed: false,
      customer:{},
      cartItem: {},
    };

    if(props.cart) {
      var cart = props.cart;
      for(var i = 0; i < cart.length; i++) {
        cart[i].customPrice = cart[i].listPrice;
        cart[i].chosenMargin = 100;
        if(this.props.affiliate.id) {
          cart[i].commission = affiliate.calculateCommission(cart[i],cart[i].customPrice, this.props.affiliate);
          cart[i].totalCommission = (Number(cart[i].commission) * Number(cart[i].qty)).toFixed(2);
        }
      }
        
      this.state.cart = cart;
      //console.log(cart);
    }
    else
      this.state.cart = [];

    if(this.props.user.customerID) {
      
      db.getCustomer(this.props.user.customerID).then((customer) => {
        this.setState({customer: customer});
      });
    } 

    this.productChanged = this.productChanged.bind(this);
    this.recurringOrderCreated = this.recurringOrderCreated.bind(this);

    //console.log(props)

    db.logActivity(this.props.user.id, this.props.user.customerID, "View-Shopping-Cart");
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  qtyBlur(product, value) {
    if(value === 0) {
      this.removeFromCart(product);
    }
  }

  // qty change is fucking it up
  qtyChange(product, value) { 
    var cart = this.state.cart;
    for(var i = 0; i < cart.length; i++) {
      if(product.id === cart[i].id) {
        cart[i].qty = value;
        cart[i].total = (Number(cart[i].qty) * Number(cart[i].customPrice)).toFixed(2);
        if(this.props.affiliate.id) {
          cart[i].commission = affiliate.calculateCommission(cart[i], cart[i].customPrice, this.props.affiliate);
          cart[i].totalCommission = (Number(cart[i].commission) * Number(cart[i].qty)).toFixed(2);
        }
        
        break;
      }
    }
    this.setState({cart: cart}, this.calculateTax);
  }

  notesChange(e) {
    
    this.setState({notes: e.target.value});
  }

  poNumberChange(e) {
    
    this.setState({poNumber: e.target.value});
  }

  removeFromCart(product) {

    this.props.onCartProductRemoved(product);

    var cart = this.state.cart;

    for(var i = 0; i < cart.length; i++) {
      if(product.id === cart[i].id) {
        cart.splice(i, 1);
        break;
      }
    }
    
    this.setState({cart: cart}, () => {
      this.calculateTax();
    });
  }

  calculateTax() {
    
    if(!this.props.taxExempt) {
            
      var today = new Date();
      var dateString = today.getFullYear() + "-" + Number(Number(today.getMonth()) + 1) + "-" + today.getDate();

      var lines = [];
      var cart = this.state.cart;
      for(var i = 0; i < cart.length; i++) {
        
        if(this.state.exemptions) {
          for(var j = 0; j < this.state.exemptions.length; j++) {
            if(cart[i].id === this.state.exemptions[j].productID) {
              cart[i].isExempt = true;
              break;
            }
          }
        }
        
        if(!cart[i].isExempt) {
          var amount = Number(cart[i].qty) * Number(cart[i].customPrice).toFixed(2);
          lines.push({ quantity: Number(cart[i].qty), amount: amount, itemCode: cart[i].productID });
        }
      }
      
      this.setState({cart:cart}, () => {
        
      }); // so as to capture isExempt settings for items

      var customerCode = this.props.user.id;
      if(this.props.user.companyName)
        customerCode = this.props.user.companyName;
      else {
        console.log('NO COMPANY NAME')
        console.log('user props:')
        console.log(this.props.user)
      }
        
      if(lines.length === 0) { // i.e. if nothing is taxable skip Avalara
        this.setState({tax: 0.00}, this.calculateTotal);
      } else {

        const taxDocument = {
          type: 'SalesOrder',
          companyCode: 'ECCLESIAMANAGEMENTLLC',// KEKAHA
          date: dateString,
          customerCode: customerCode,
          addresses: {
            ShipFrom: {
              line1: '4100 Eldorado Pkwy',
              city: 'Mckinney',
              region: 'TX',
              country: 'US',
              postalCode: '75070-6102'
            },
            ShipTo: {
              line1: this.state.shipToAddress.address,
              city: this.state.shipToAddress.city,
              region: this.state.shipToAddress.state,
              country: this.state.shipToAddress.country,
              postalCode: this.state.shipToAddress.zip
            }
          },
          lines: lines,
          commit: false,
          currencyCode: 'USD',
          description: 'Janitorial Supplies'
        }

        this.setState({taxDocument: taxDocument});

        fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/calculateTax", {
          method: "POST", 
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(taxDocument)
        })
        .then((response) => {
            
            if(response.ok) {
                return response.json();
            } else {
              console.log("error (1): ");  
              console.log(response);
            }
        })
        .then((json) => {
        
          //console.log(json);
          this.setState({tax: Number(json.totalTax).toFixed(2), taxResult: json}, this.calculateTotal);
          
        })
        .catch((error) => {
            // If there is any error you will catch them here
            console.log("error (2):  " + error);
            this.setState({tax: 0.00}, this.calculateTotal);
        });
      }

    } else {
      
      this.setState({tax: 0.00}, this.calculateTotal);
    }
  }

  calculateTotal() {

    var commission = 0.0;
    var total = 0.0;
    for(var i = 0; i < this.state.cart.length; i++) {
      total = total + (parseFloat(this.state.cart[i].customPrice) * parseFloat(this.state.cart[i].qty));
      if(this.props.affiliate.id)
        commission = commission + (parseFloat(this.state.cart[i].totalCommission));
    }
      
    if(this.state.tax) 
      total = Number(total) + Number(this.state.tax);

    total = Number(total).toFixed(2);
    commission = Number(commission).toFixed(2);
    
    this.setState({commission: commission, total: total});
  }

  compareLabel(a,b) {

    var aName = a.label;
    var bName = b.label;
    
    if (aName < bName)
        return -1;
    if (aName > bName)
        return 1;
    return 0;
  }
  
  addressesLoaded(addresses) {
    this.setState({addresses: addresses}, () => {
      this.calculateTotal();
    });
  }

  changeShipTo(value) {
    
    this.props.onLocationSelected(value);

    //for display
    for(var i = 0; i < this.props.locations.length; i++) {
      if(this.props.locations[i].address_id === value) {
        this.setState( {shipToAddress: this.props.locations[i]}, () => {
          this.calculateTax();
        });
        break;
      }
    }

    // for the select
    this.setState({shipTo: value}, () => {
      
    });
  }

  readyToCheckout() {
    if(this.state.poNumber.length > 0 && this.state.cart.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  trim(value, maxLength) {
    if(value) {
        if(value.trim().length > maxLength)
            return value.trim().substring(0,maxLength) + "...";
        else
            return value.trim();
    } else return "";
  }

  showElement() {
    if(this.props.user.customerID !== '1343284000001266251') 
      return 'block';
    else
      return 'none';
  }

  showCommission() {
    if(this.props.user.customerID === '1343284000001266251') // demo company
      return 'table-cell';
    else
      return 'none';
  }

  showCreateEstimate() {
    if(this.props.user.customerID === '1343284000001266251') // demo company
      return 'block';
    else
      return 'none';
  }

  showPriceEditor() {
    if(this.props.user.customerID === '1343284000001266251' && (this.props.user.userType === 'admin' || this.props.user.userType === 'Affiliate')) // demo company
      return 'inline-block';
    else
      return 'none';
  }

  recurringOrderCreated() {
    this.props.onCartCleared();
    this.setState({completed:true})
  }

  productChanged(product) {
    var cart = this.state.cart;
    for(var i = 0; i < cart.length; i++) {
      if(product.id === cart[i].id) {
        cart[i] = product;
        
        break;
      }
    }
    this.setState({cart: cart, product: product}, () => {
      this.calculateTax();
    });
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user) {
      return <Redirect to='/login' />
    } else if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <StripeProvider apiKey="pk_test_RhQ4N71Laf00l7nFxTeqvFOT">
      <div>
        <Header
          brand="Ecclesia"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="dark"
        />

        <div className={classes.container} style={{minHeight:"369px", marginTop:"100px"}}>
          <GridContainer>
            <GridItem xs={12}>
                <h3 className={classes.cardTitle}>Shopping Cart</h3>
            </GridItem>
            <GridItem xs={12}>
              <Card >
                <CardBody >
                  
                  <GridContainer style={{margin:"0px", marginBottom:"30px"}}>
                    <GridItem xs={12} sm={12} >
                      <AddressChooser onAddressesLoaded={this.addressesLoaded} shipToAddress={this.state.shipToAddress} onAddressChosen={this.changeShipTo} cartTotal={this.state.total} chooseShipTo />
                    </GridItem>
                  </GridContainer>

                  <Hidden smDown>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{padding:"3px"}}></TableCell>
                          <TableCell style={{padding:"3px", minWidth: 200}}>Product</TableCell>
                          <TableCell style={{padding:"3px", textAlign:"right" }}>Quantity</TableCell>
                          <TableCell style={{padding:"3px", textAlign:"right" }}>Price</TableCell>
                          <TableCell style={{textAlign:"right", padding:"3px" }}>Total</TableCell>
                          <TableCell style={{textAlign:"right", display:this.showCommission(), padding:"3px"}}>Commission</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.cart.map((product,index) => (
                          <TableRow key={index}>
                            <TableCell style={{padding:"3px"}}><img src={product.thumbnail} style={{ height:"89px", paddingTop:"10px", paddingBottom:"10px" }} alt="thumbnail"></img></TableCell>
                            <TableCell style={{padding:"3px", minWidth: 200}}>
                              <b>{this.trim(product.name,123)}</b><br />
                              Manufacturer: <span style={{color:"#3fa31b"}}>{product.manufacturer}</span> - ID: <span style={{color:"#3fa31b"}}>{product.productID}</span>
                            </TableCell>
                            <TableCell style={{padding:"3px", textAlign:"right" }}>
                              <input type="text" value={product.qty} onChange={(e) => this.qtyChange(product, e.target.value)} onBlur={(e) => this.qtyBlur(product, e.target.value)} placeholder="qty" style={{borderStyle:"none", padding:"6px", borderRadius:"6px", textAlign:"right", width:"50px", backgroundColor:"#efefef", color:"green"}} />
                            </TableCell>
                            <TableCell style={{padding:"3px", textAlign:"right", width:"140px" }}>
                              <div style={{display:this.showPriceEditor()}}>
                                <PricePopover product={product} productChanged={this.productChanged} />
                              </div>
                              <div style={{display:"inline-block"}}>
                                ${Number(product.customPrice).toFixed(2)}
                              </div>
                            </TableCell>
                            <TableCell style={{padding:"3px", textAlign:"right", fontWeight:600 }}>${(product.customPrice * product.qty).toFixed(2)}</TableCell>
                            <TableCell style={{padding:"3px", textAlign:"right", display:this.showCommission(), color:"#3fa31b"}}>
                              ${product.totalCommission}
                            </TableCell>
                            <TableCell style={{padding:"3px", maxWidth:"54px", textAlign:"center"}}>
                              <IconButton onClick={() => this.removeFromCart(product)}>
                                <Remove />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow key={"taxRow"}>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell style={{textAlign:"right", paddingRight:"23px", fontWeight:"600"}}>Tax: </TableCell>
                          <TableCell style={{textAlign:"right", padding:"3px", fontWeight:"600"}}>${this.state.tax}</TableCell>
                          <TableCell style={{display:this.showCommission(), padding:"3px"}}></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow key={"totalRow"}>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell style={{textAlign:"right", paddingRight:"23px", fontWeight:"600"}}>Total: </TableCell>
                          <TableCell style={{textAlign:"right", padding:"3px", fontWeight:"600"}}>${this.state.total}</TableCell>
                          <TableCell style={{display:this.showCommission(), padding:"3px"}}></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Hidden>

                  <Hidden mdUp>
                    <GridContainer direction="column">
                    {
                      this.state.cart.map((product, index)=>(
                        <GridItem key={"mobile-cart-product-"+index}>
                          <Card style={{marginTop: 15, marginBottom: 15}}>
                            <CardBody>
                              <GridContainer direction="column">
                                <GridItem>
                                  <GridContainer direction="row" style={{marginBottom: 10}}>
                                    <GridItem style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                      <img src={product.thumbnail} style={{ maxHeight:"89px", marginTop:"10px", marginBottom:"10px" }} alt="thumbnail"></img>
                                    </GridItem>
                                    <GridItem style={{flex: 2, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                      <div>Manufacturer: <span style={{color:"#3fa31b"}}>{product.manufacturer}</span> </div>
                                      <div>ID: <span style={{color:"#3fa31b"}}>{product.productID}</span></div>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                                <GridItem style={{textAlign: 'center'}}>
                                  <b>{this.trim(product.name,123)}</b><br />
                                </GridItem>
                                <GridItem style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 10, minHeight: 48}}>
                                  <span style={{marginRight: 5}}>${Number(product.customPrice).toFixed(2)}</span>
                                  <span><input type="text" value={product.qty} onChange={(e) => this.qtyChange(product, e.target.value)} onBlur={(e) => this.qtyBlur(product, e.target.value)} placeholder="qty" style={{borderStyle:"none", padding:"6px", borderRadius:"6px", textAlign:"right", width:"50px", backgroundColor:"#efefef", color:"green"}} /></span>
                                  <span style={{display: this.showPriceEditor()}}><PricePopover product={product} productChanged={this.productChanged} /></span>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      ))
                    }
                      <GridItem>
                        
                      </GridItem>
                      <GridItem style={{display: 'flex', justifyContent: 'flex-end'}}>
                        Tax: {this.state.tax}
                      </GridItem>
                      <GridItem style={{display: 'flex', justifyContent: 'flex-end'}}>
                        Total: {this.state.total}
                      </GridItem>
                      <GridItem style={{display: (this.showCommission() === 'none'? 'none':  'flex'), justifyContent: 'flex-end'}}>
                        Commission: 
                      </GridItem>
                    </GridContainer>
                  </Hidden>

                  <GridContainer style={{marginBottom:"69px"}} className={"shopping-cart-bottom-section"}>
                    <GridItem xs={4} style={{marginTop:"16px", display:this.showElement()}} >
                      <CustomInput
                        labelText="Purchase Order #"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.formControl
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Receipt className={classes.icon} />
                            </InputAdornment>
                          ),
                          placeholder: "Purchase Order # (required)"
                        }}
                        value={this.state.poNumber}
                        onChange={this.poNumberChange}
                      />
                    </GridItem>
                    <GridItem xs={4} style={{marginTop:"16px", display:this.showElement()}}>
                      <CustomInput
                        labelText="Notes"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.formControl
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Note className={classes.icon} />
                            </InputAdornment>
                          ),
                          placeholder: "Notes",
                          multiline: true
                        }}
                        value={this.state.notes}
                        onChange={this.notesChange}
                      />{/*}
                      <div style={{fontSize:"11px", color:"#AAAAAA", marginTop:"17px", fontWeight:400}}>
                        Notes
                      </div>
                      <textarea value={this.state.notes} onChange={this.notesChange} placeholder="What else should we know?" 
                        style={{borderStyle:"none", padding:"6px", borderRadius:"6px", width:"100%", height:"79px", backgroundColor:"#efefef", color:"green"}} />
                        */}
                    </GridItem>
                    <GridItem xs={4} style={{textAlign:"right", marginTop:"23px"}}>
                      <div style={{display:this.showElement()}}>
                        <Elements>
                          <Checkout cart={this.state.cart} 
                            shipTo={this.state.shipTo} 
                            shippingAddress={this.state.shipToAddress} 
                            tax={this.state.tax} 
                            taxResult={this.state.taxResult}
                            commission={this.state.commission}
                            poNumber={this.state.poNumber} 
                            notes={this.state.notes} 
                            isDisabled={this.readyToCheckout()}
                          />
                        </Elements>
                      </div>
                      <div style={{display:this.showElement()}}>
                        <RecurringOrder button style={{width:"100%"}} isDisabled={this.readyToCheckout()} items={this.state.cart} poNumber={this.state.poNumber}
                          shipTo={this.state.shipTo} shippingAddress={this.state.shipToAddress} orderSaved={this.recurringOrderCreated} />
                      </div>
                      <div style={{display:this.showCreateEstimate()}}>
                        <Estimate button items={this.state.cart} shippingAddress={this.state.shipToAddress} />
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        
        <Footer
          content={
            <div>
              <div className={classes.right} style={{fontSize:"13px"}}>
                &copy; {1900 + new Date().getYear()} Ecclesia, Inc.
              </div>
            </div>
          }
        />
      </div>
      </StripeProvider>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onCartCleared: CartCleared, onCartProductRemoved: CartProductRemoved, onLocationSelected: LocationSelected }, dispatch);  
}

ShoppingCartPage = connect(mapStateToProps, mapDispatchToProps)(ShoppingCartPage);
export default withStyles(shoppingCartStyle)(ShoppingCartPage);
