import React from 'react';

//firebase
import { db } from 'assets/firebase';

//algolia full text search
import { algolia } from 'assets/algolia';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CartProductAdded, CartCleared } from 'redux/actions';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import styles from 'assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.jsx';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Accordion from 'components/Accordion/Accordion.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/CustomButtons/Button.jsx';
import Hidden from '@material-ui/core/Hidden';
import CustomSelect from 'components/Select/Select.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';

// icons
import Cached from '@material-ui/icons/Cached';
import Check from '@material-ui/icons/Check';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

//kekaha
import ProductDetails from 'components/ProductDetails/ProductDetails.jsx';

//custom pricing
import applyCustomPricing from "assets/customPricing"

function compareName(a, b) {
  var aName = a.Name?.toString().toLowerCase();
  var bName = b.Name?.toString().toLowerCase();

  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
}

var sort_by;

(function () {
  // utility functions
  var default_cmp = function (a, b) {
      if (a === b) return 0;
      return a < b ? -1 : 1;
    },
    getCmpFunc = function (primer, reverse) {
      var dfc = default_cmp, // closer in scope
        cmp = default_cmp;
      if (primer) {
        cmp = function (a, b) {
          return dfc(primer(a), primer(b));
        };
      }
      if (reverse) {
        return function (a, b) {
          return -1 * cmp(a, b);
        };
      }
      return cmp;
    };

  // actual implementation
  sort_by = function () {
    var fields = [],
      n_fields = arguments.length,
      field,
      name,
      cmp;

    // preprocess sorting options
    for (var i = 0; i < n_fields; i++) {
      field = arguments[i];
      if (typeof field === 'string') {
        name = field;
        cmp = default_cmp;
      } else {
        name = field.name;
        cmp = getCmpFunc(field.primer, field.reverse);
      }
      fields.push({
        name: name,
        cmp: cmp,
      });
    }

    // final comparison function
    return function (A, B) {
      var name, result;
      for (var i = 0; i < n_fields; i++) {
        result = 0;
        field = fields[i];
        name = field.name;

        result = field.cmp(A[name].toUpperCase(), B[name].toUpperCase());
        if (result !== 0) break;
      }
      return result;
    };
  };
})();

class SectionProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: [],
      checkedSubCat1: [],
      checkedSubCat2: [],
      checkedSubCat3: [],
      products: [],
      visibleProducts: [],
      manufacturers: [],
      visibleManufacturers: [],
      categories: [],
      subCat1: [],
      subCat2: [],
      subCat3: [],
      selectedEnabled: '',
      resultsCount: 0,
      collapseAccordion: false,
      lastKnownCustomerID: '',
      refreshingProducts: false,
    };

    this.resetFilters = this.resetFilters.bind(this);
    this.refreshProducts = this.refreshProducts.bind(this);
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
  }

  componentDidMount() {

    db.getManufacturers().then((snapshot) => {
      var manufacturers = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        obj.key = obj.id;
        obj.label = obj.Name;
        manufacturers.push(obj);
      });

      this.setState({ manufacturers: manufacturers });
    });

    db.getCategories().then((categories) => {
      for (var i = 0; i < categories.length; i++) {
        categories[i].key = categories[i].id;
        categories[i].label = categories[i].Name;
      }

      this.setState(
        {
          categories: categories.sort(compareName),
          selectedEnabled: categories[0].id,
        },
        () => {
          this.loadSubCategories();
        },
      );

      this.refreshProducts();
    });

    //algolia.applySettings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.setState({ query: this.props.searchQuery }, () => {
        this.resetFilters();
      });
    }

    if (prevProps.cart !== this.props.cart) {
      var cartTotal = 0.0;
      for (var j = 0; j < this.props.cart.length; j++) {
        cartTotal =
          cartTotal + this.props.cart[j].qty * this.props.cart[j].listPrice;
      }

      this.setState({ cartTotal: cartTotal });
    }
  }

  resetFilters(category) {
    if (!category) {
      category = null;
    }

    this.setState(
      {
        checked: [],
        selectedEnabled: category,
        checkedSubCat1: [],
        checkedSubCat2: [],
        checkedSubCat3: [],
      },
      () => {
        this.refreshProducts();
      },
    );
  }

  refreshProducts() {
    
    this.setState({refreshingProducts: true}, ()=>{
      var query = '';
      var category = null;
  
      // ignore category when searching w/ text
      if (this.state.query) {
        query = this.state.query;
        this.setState({ collapseAccordion: true }, () => {
          this.setState({ collapseAccordion: false });
        });
      } else {
        category = this.state.selectedEnabled;
        if(!category) // prevent not having any category selected
          category = this.state.categories[0].id;
        this.setState({ collapseAccordion: false, selectedEnabled: category });
      }
  
      algolia
        .searchProducts(
          query,
          this.state.checked,
          category,
          this.state.checkedSubCat1,
          this.state.checkedSubCat2,
          this.state.checkedSubCat3,
        )
        .then((results) => {
          var manufacturers = [];
          var filtered = [];
  
          for (var i = 0; i < results.length; i++) {
            if (this.props.visibility) {
              // me thinks isHidden is never hidden because of it's declared out of scope!?
  
              var isHidden = false;
              for (var v = 0; v < this.props.visibility.length; v++) {
                if (this.props.visibility[v].productID === results[i].id) {
                  isHidden = true;
                  break;
                }
              }
            }
  
            if (!isHidden) {
              // get manufacturers name
              for (var j = 0; j < this.state.manufacturers.length; j++) {
                if (
                  results[i].manufacturerID === this.state.manufacturers[j].id
                ) {
                  results[i].manufacturer = this.state.manufacturers[j].Name;
  
                  var isFound = false;
                  for (var k = 0; k < manufacturers.length; k++) {
                    if (manufacturers[k].id === results[i].manufacturerID) {
                      isFound = true;
                      break;
                    }
                  }
                  if (!isFound)
                    manufacturers.push({
                      id: results[i].manufacturerID,
                      key: results[i].manufacturerID,
                      Name: results[i].manufacturer,
                      label: results[i].manufacturer,
                    });
                }
              }
  
              // get any custom pricing
              if (this.props.pricing) {
                applyCustomPricing(this.props.pricing, results[i])
              }
  
              // set image property
              if (!results[i].thumbnail) {
                results[i].thumbnail = '/missingImage.jpg';
              }
              if (!results[i].photo) {
                results[i].photo = '/missingImage.jpg';
              }
  
              if (!results[i].attachments) results[i].attachments = [];
  
              filtered.push(results[i]);
            }
          }
  
          this.setState({ resultsCount: filtered.length });
  
          filtered.sort(sort_by('manufacturer', 'name'));
  
          if (this.state.checked.length > 0) {
            // if we are already filtering by manufacturers
            this.setState({ products: filtered, refreshingProducts: false });
          } else {
            // otherwise update the manufacturer filters
            manufacturers.sort(compareName);
            this.setState({
              products: filtered,
              visibleManufacturers: manufacturers,
              refreshingProducts: false
            });
          }
        });
    })
  }

  trim(value, maxLength) {
    if (value) {
      if (value.trim().length > maxLength)
        return value.trim().substring(0, maxLength) + '...';
      else return value.trim();
    } else return '';
  }

  handleToggle(value) {
    // manufacturers
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked }, () => {
      this.refreshProducts();
    });
  }

  toggleSubCat1(value) {
    const checked = this.state.checkedSubCat1;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checkedSubCat1: newChecked }, () => {
      this.refreshProducts();
    });
  }

  toggleSubCat2(value) {
    const checked = this.state.checkedSubCat2;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checkedSubCat2: newChecked }, () => {
      this.refreshProducts();
    });
  }

  toggleSubCat3(value) {
    const checked = this.state.checkedSubCat3;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checkedSubCat3: newChecked }, () => {
      this.refreshProducts();
    });
  }

  handleChangeEnabled(event) {
    // category from radio buttons
    this.props.resetQuery();
    this.setState(
      {
        query: '',
        selectedEnabled: event.target.value,
        checkedSubCat1: [],
        checkedSubCat2: [],
        visibleManufacturers: [],
      },
      () => {
        this.refreshProducts();
        this.loadSubCategories();
      },
    );
  }

  loadSubCategories() {
    // show subcategories for the selected category
    for (var i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i].id === this.state.selectedEnabled) {
        var subCat1 = this.state.categories[i].SubCat1.sort(compareName);
        var subCat2 = this.state.categories[i].SubCat2.sort(compareName);
        var subCat3 = this.state.categories[i].SubCat3.sort(compareName);

        this.setState({ subCat1: subCat1 });
        this.setState({ subCat2: subCat2 });
        this.setState({ subCat3: subCat3 });
        break;
      }
    }
  }

  resultsCount() {
    if (this.state.resultsCount === 50) {
      return <span>more than 50 matching products found</span>;
    } else {
      return <span>{this.state.resultsCount} matching products found</span>;
    }
  }

  

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <GridContainer style={{ marginTop: '23px' }}>
          <Hidden smDown>
            <GridItem md={3} sm={3}>
              <Accordion
                active={[0]}
                activeColor="success"
                collapse={this.state.collapseAccordion}
                collapses={[
                  {
                    title: 'Categories',
                    content: (
                      <div>
                        {this.state.categories.map((category, index) => (
                          <div key={index}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={
                                    this.state.selectedEnabled === category.id
                                  }
                                  onChange={this.handleChangeEnabled}
                                  value={category.id}
                                  name={category.Name}
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot,
                                  }}
                                />
                              }
                              classes={{ label: classes.label }}
                              label={category.Name}
                              style={{ marginTop: '-17px', marginLeft: '0px' }}
                            />
                          </div>
                        ))}
                      </div>
                    ),
                  },
                  {
                    title: 'Subcategories',
                    content: (
                      <div className={classes.customExpandPanel}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            ' ' +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          {this.state.subCat1.map((subcat, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.toggleSubCat1(subcat.id)}
                                  checked={
                                    this.state.checkedSubCat1.indexOf(
                                      subcat.id,
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              classes={{ label: classes.label }}
                              label={subcat.Name}
                              style={{ marginTop: '-10px', marginLeft: '0px' }}
                            />
                          ))}
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: 'Subcategories',
                    content: (
                      <div className={classes.customExpandPanel}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            ' ' +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          {this.state.subCat2.map((subcat, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.toggleSubCat2(subcat.id)}
                                  checked={
                                    this.state.checkedSubCat2.indexOf(
                                      subcat.id,
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              classes={{ label: classes.label }}
                              label={subcat.Name}
                              style={{ marginTop: '-10px', marginLeft: '0px' }}
                            />
                          ))}
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: 'Color / Scent / Other',
                    content: (
                      <div className={classes.customExpandPanel}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            ' ' +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          {this.state.subCat3.map((subcat, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.toggleSubCat3(subcat.id)}
                                  checked={
                                    this.state.checkedSubCat3.indexOf(
                                      subcat.id,
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              classes={{ label: classes.label }}
                              label={subcat.Name}
                              style={{ marginTop: '-10px', marginLeft: '0px' }}
                            />
                          ))}
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: 'Manufacturers',
                    content: (
                      <div className={classes.customExpandPanel}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            ' ' +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          {this.state.visibleManufacturers.map(
                            (manufacturer, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={() =>
                                      this.handleToggle(manufacturer.id)
                                    }
                                    checked={
                                      this.state.checked.indexOf(
                                        manufacturer.id,
                                      ) !== -1
                                        ? true
                                        : false
                                    }
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{ label: classes.label }}
                                label={manufacturer.Name}
                                style={{
                                  marginTop: '-10px',
                                  marginLeft: '6px',
                                }}
                              />
                            ),
                          )}
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
              <div style={{ fontSize: '11px', color: 'green' }}>
                {this.resultsCount()}
              </div>
              <br />
              <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <Tooltip
                  id="tooltip-top"
                  title="Reset Filter"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <React.Fragment>
                    <Button
                      link
                      size="sm"
                      className={`${classes.refineButton}`}
                      onClick={() =>
                        this.resetFilters(this.state.categories[0].id)
                      }
                    >
                      <Cached /> Clear Filters
                    </Button>
                  </React.Fragment>
                </Tooltip>
              </div>
            </GridItem>
          </Hidden>
          <GridItem xs={12} sm={9} md={9}>
            <GridContainer>
              
              <Hidden mdUp>
                <GridItem xs={12}>
                  <CustomSelect
                    labelText="Categories"
                    inputProps={{ placeholder: 'Categories' }}
                    formControlProps={{ fullWidth: true }}
                    onChange={(e) => this.handleChangeEnabled(e)}
                    options={this.state.categories}
                    value={this.state.selectedEnabled}
                  />
                </GridItem>
                {/*}
                  <GridItem xs={6}>
                    <CustomSelect
                      multiple
                      labelText="Manufacturers"
                      inputProps={{ placeholder: "Manufacturers" }}
                      formControlProps={{ fullWidth: true }}
                      onChange={(e) => this.handleToggle(e)}
                      options={this.state.visibleManufacturers}
                      value={this.state.checked}
                    />
                  </GridItem>
                  */}
              </Hidden>

              {
              this.state.refreshingProducts?
              (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: 20}}>
                  <CircularProgress color="primary" />
                </div>
              )
              :
              this.state.products.map((product, index) => (
                <ProductDetails product={product} key={product.id} />
              ))}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //return { cart: state.cart }
  return state;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onCartProductAdded: CartProductAdded,
      onCartCleared: CartCleared
    },
    dispatch,
  );
}

SectionProducts = connect(mapStateToProps, mapDispatchToProps)(SectionProducts);
export default withStyles(styles)(SectionProducts);
