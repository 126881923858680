//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UserLoggedIn } from 'redux/actions';

//firebase
import { auth, db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";

//icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
import Person from "@material-ui/icons/Person";
import Company from "@material-ui/icons/Business";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

// images
import logo from "assets/img/logo.png";

const uuidv4 = require('uuid/v4');

class SignupPage extends React.Component {
  constructor(props) {
    super(props);

    this.firstNameChange = this.firstNameChange.bind(this);
    this.lastNameChange = this.lastNameChange.bind(this);
    this.companyChange = this.companyChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.password1Change = this.password1Change.bind(this);
    this.password2Change = this.password2Change.bind(this);
    this.onUserLoggedIn = this.onUserLoggedIn.bind(this);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      password1:"",
      password2:"",
      error:null,
      loggedIn: false,
      user: null,
      userID: null,
      customerID:null,
      contactID:null
    };

    if(props.match.params.id) {
      this.state.userID = this.props.match.params.id;

      db.getUser(this.state.userID).then(doc => {

        var user = doc.data();

        if(user.isRegistered)
          this.setState({isRegistered: true});
        else {
          this.setState({
            addresses: user.Addresses,
            budgetingPermissions: user.BudgetingPermissions,
            customers: user.Customers,
            email: user.Email,
            firstName: user.FirstName,
            invoicePermissions: user.InvoicePermissions,
            lastName: user.LastName,
            orderingPermissions: user.OrderingPermissions,
            contactID: user.contactID,
            customerID: user.customerID,
            userID: user.id,
            userType: user.userType,
            commissionType: user.commissionType,
            baseCommission: user.baseCommission,
            floorCommission: user.floorCommission
          });
  
          if(!this.state.userType)
            this.state.userType = 'Customer';
  
          if(!this.state.customers)
            this.state.customers = []
        }
        
      });
    } else {

      this.state.userType = 'Internet';
      this.state.userID = uuidv4();
    }
  }

  firstNameChange(e) {
    this.setState({firstName: e.target.value});
  }
  lastNameChange(e) {
    this.setState({lastName: e.target.value});
  }
  companyChange(e) {
    this.setState({company: e.target.value});
  }
  emailChange(e) {
    this.setState({email: e.target.value});
  }
  password1Change(e) {
    this.setState({password1: e.target.value});
  }
  password2Change(e) {
    this.setState({password2: e.target.value});
  }

  onUserLoggedIn(user) {
    
    this.setState({ user: user }, () => {
      
      if(user.userType === 'Affiliate' || user.userType === 'admin') {
        var promises = [];

        for(var i = 0; i < user.Customers.length; i++) {
          promises.push(db.getCustomer(user.Customers[i].id));  
        }
        
        Promise.all(promises).then((dbResults) => { 

          user.customerID = dbResults[0].id;
          for(var j = 0; j < user.Customers.length; j++)
            if(user.Customers[j].id === user.customerID) {
              user.contactID = user.Customers[j].contactID;
              break;
            }

          this.finalizeLogin(user);
        });

      } else {
        db.getCustomer(user.customerID).then(customer => {
          user.companyName = customer.company_name;
          this.finalizeLogin(user);
        })
      }
    });
    
  }

  finalizeLogin(user) {
    db.logActivity(user.id, user.customerID, "Login-App");
    
    var loggedInUser = { 
      id: user.id, 
      email: user.Email, 
      firstName: user.FirstName, 
      lastName: user.LastName, 
      customerID: user.customerID, 
      contactID: user.contactID, 
      addresses: user.Addresses, 
      userType: user.userType, 
      customers: user.Customers, 
      companyName: user.companyName,
      budgetingPermissions: user.BudgetingPermissions,
      orderingPermissions: user.OrderingPermissions,
      invoicePermissions: user.InvoicePermissions,
      commissionType: user.commissionType,
      baseCommission: user.baseCommission,
      floorCommission: user.floorCommission
    }

    console.log(loggedInUser);
    this.props.onUserLoggedIn(loggedInUser);

    this.setState({loggedIn: true});
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  onSubmit = (event) => {
    event.preventDefault();

    const {
      userID,
      firstName,
      lastName,
      email,
      password1,
      password2,
      customerID,
      contactID,
      userType,
      customers,
      addresses,
      budgetingPermissions,
      invoicePermissions,
      orderingPermissions,
      commissionType,
      baseCommission,
      floorCommission
    } = this.state;

    this.setState({error: ''});

    if(firstName.length < 1) {
        this.setState({error: 'Sorry, what is your first name?'});
    } else if(lastName.length < 1) {
      this.setState({error: 'Sorry, what is your last name?'});
    } else if (email.length < 1) { 
        this.setState({error: 'An email address is required.'});
    } else if(password1.length < 1) {
        this.setState({error: 'Sorry, you will need a password!'});
    } else if(password1 !== password2) {
        this.setState({error: 'Your passwords do not match.'});
    } else {
      
      var data = {
        uid: userID,
        email: email,
        password: password1,
        fullName: firstName + " " + lastName
      }

      fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/createUser", {
        method: "POST", 
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
          
          if(response.ok) {

            auth.doSignInWithEmailAndPassword(email, password1).then(authUser => {
              var user = { 
                id: userID, 
                FirstName: firstName, 
                LastName: lastName, 
                Email: email, 
                Photos: [], 
                userType: userType
              }
  
              if(customerID)
                user.customerID = customerID;
              if(contactID)
                user.contactID = contactID;
              if(customers)
                user.Customers = customers;
              if(addresses)
                user.Addresses = addresses;
              if(budgetingPermissions)
                user.BudgetingPermissions = budgetingPermissions;
              if(invoicePermissions)
                user.InvoicePermissions = invoicePermissions;
              if(orderingPermissions)
                user.OrderingPermissions = orderingPermissions;
              if(commissionType)
                user.commissionType = commissionType;
              if(baseCommission)
                user.baseCommission = baseCommission;
              if(floorCommission)
                user.floorCommission = floorCommission;
  
              user.isRegistered = true;
  
              console.log('db write:::');
              console.log(user);

              db.updateUser(userID, user).then(() => {
                  this.onUserLoggedIn(user);
              });

            }).catch(error => {
              console.log(error);
            });
          } else {
            console.log("error creating user");  
            console.log(response);
          }
      })
      
      .catch((error) => {
          // If there is any error you will catch them here
          console.log("error (2):  " + error);
      });
    }
  }

  render() {
    const { classes } = this.props;
    console.log('signupPage.render()')

    if (this.state.isRegistered === true) {
      return <Redirect to='/login' />
    }
    if (this.state.loggedIn === true) {
      return <Redirect to='/reload' />
    }

    return (
      <div>
        
        <div
          className={classes.pageHeader}
          style={{
            backgroundColor:"#696969"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={6}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form} onSubmit={this.onSubmit}>
                    <CardHeader color="success" className={classes.cardHeader}>
                      <h4>Get Started</h4>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={6}>
                          <CustomInput
                            labelText="First Name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Person className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                            onChange={this.firstNameChange}
                            value={this.state.firstName}
                          />
                        </GridItem>
                        <GridItem xs={6}>
                          <CustomInput
                            labelText="Last Name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Person className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                            onChange={this.lastNameChange}
                            value={this.state.lastName}
                          />
                        </GridItem>
                        <GridItem xs={12} style={{display:"none"}}>
                          <CustomInput 
                            labelText="Company"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "text",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Company className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                            onChange={this.companyChange}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "email",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Email className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                            onChange={this.emailChange}
                            value={this.state.email}
                          />
                        </GridItem>
                        <GridItem xs={6}>
                          <CustomInput
                            labelText="Password"
                            id="pass1"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "password",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LockOutline className={classes.inputIconsColor} />
                                </InputAdornment>
                              )
                            }}
                            onChange={this.password1Change}
                          />
                        </GridItem>
                        <GridItem xs={6}>
                          <CustomInput
                            labelText="Verify Password"
                            id="pass2"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "password",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LockOutline
                                    className={classes.inputIconsColor}
                                  />
                                </InputAdornment>
                              )
                            }}
                            onChange={this.password2Change}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <div id='divErrors' className={classes.divider} style={{color:"maroon", padding:"0px", paddingLeft:"10px", paddingRight:"10px", fontSize:"12px", textAlign:"center"}} >
                      {this.state.error}
                    </div>
                    <CardFooter className={classes.cardFooter}>
                      <Button simple color="success" size="lg" type="submit" fullWidth > 
                        Get Started
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
              <GridItem xs={12} style={{textAlign:"center"}}>
                <img src={logo} style={{width:"250px"}} alt="logo" />
              </GridItem>
            </GridContainer>
          </div>
         
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onUserLoggedIn: UserLoggedIn }, dispatch);  
}

SignupPage = connect(mapStateToProps, mapDispatchToProps)(SignupPage);
export default withStyles(loginPageStyle)(SignupPage);
