import React from 'react';

//redux
import { connect } from 'react-redux';

//affiliate
import { affiliate } from 'assets/affiliate';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import popoverStyles from "assets/jss/material-kit-pro-react/popoverStyles.jsx";

// controls
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Paginations from "components/Pagination/Pagination.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

//icons
import Discount from "@material-ui/icons/MoneyOff";

class PricePopover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            product: props.product,
            visible: false,
            affiliate: props.affiliate
        };

        this.customPriceChange = this.customPriceChange.bind(this);
    }

    toggleVisible(e) {
        
        if(!this.state.visible)
            this.setState({anchorEl: e.currentTarget});
        else 
            this.setState({anchorEl: null});
        
        this.setState({visible: !this.state.visible}); 
        
        //console.log(this.props.product)
    }

    // handles manually entered prices
    customPriceVerify(value) { // call on price blur
        
        var product = this.state.product;
        var hardFloor = 1.111; // first level affiliates
        if(this.state.affiliate.parent) // i.e. a second level affiliate
            hardFloor = 1.2755; 
        if(this.state.affiliate.floorPercentage > hardFloor)
            hardFloor = this.state.affiliate.floorPercentage;

        var minimumPrice = (Number(product.cost) * hardFloor).toFixed(2);
        
        if(value < minimumPrice)
            product.customPrice = minimumPrice;
        this.updateCommission(product, product.customPrice);
    }

    customPriceChange(value) {
        var product = this.state.product;
        product.customPrice = value;
        product.chosenCommission = "";
        this.updateCommission(product, product.customPrice);   
    }

    commissionChosen(commissionPercentage) {

        var product = this.state.product;
        product.chosenCommission = commissionPercentage;

        var marginPercentage = 1.111;

        if(this.state.affiliate.parent) {// i.e. a second-level affiliate
            if(commissionPercentage === 3)
                marginPercentage = 1.2755; 
            else if(commissionPercentage === 5)
                marginPercentage = 1.3689;
            else if(commissionPercentage === 7)
                marginPercentage = 1.4769;
            else if(commissionPercentage === 9)
                marginPercentage = 1.6036;
        } else {
            if(commissionPercentage === 5)
                marginPercentage = 1.176;
            else if(commissionPercentage === 7)
                marginPercentage = 1.25;
            else if(commissionPercentage === 9)
                marginPercentage = 1.333;
        }

        console.log('marginPercentage: ' + marginPercentage);

        product.customPrice = (Number(product.cost) * marginPercentage).toFixed(2);

        this.updateCommission(product, product.customPrice);
    }

    // called in both scenarios
    

    updateCommission(product, price) {

        var hardFloor = 1.25;
        if(this.state.affiliate.parent) // i.e. a second level affiliate
            hardFloor = 1.2755; 
        if(this.state.affiliate.floorPercentage > hardFloor)
            hardFloor = this.state.affiliate.floorPercentage;

        console.log('updateCommission()');
        console.log('hardFloor: ' + hardFloor);

        var minimumPrice = (Number(product.cost) * hardFloor).toFixed(2);
        var minimumCommission = affiliate.calculateCommission(product, minimumPrice, this.props.affiliate);

        product.commission = affiliate.calculateCommission(product, price, this.props.affiliate);
        if(product.commission < minimumCommission) product.commission = 0.00;
        product.totalCommission = (product.commission * product.qty).toFixed(2);
        this.setState({product: product}, () => {
            this.props.productChanged(product);
        });
    }

    isChosenMargin(margin) {
        if(margin === this.state.product.chosenMargin) {
            return true;
        } else {
            return false;
        }
    }

    isChosenCommission(commission) {
        if(commission === this.state.product.chosenCommission) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { classes } = this.props;

        return (
        <div>
            <IconButton onClick={(e) => this.toggleVisible(e)}><Discount /></IconButton>
            <Popover 
                classes={{
                    paper: classes.popover
                }}
                open={this.state.visible} 
                onClose={() => this.toggleVisible()}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
                anchorEl={this.state.anchorEl}
            >
                <div className={classes.popoverBody} style={{padding:"23px", backgroundColor:"white"}} >
                    <GridContainer >
                        <GridItem xs={6} style={{textAlign:"center"}}>
                            <CustomInput
                                labelText="Price"
                                formControlProps={{
                                fullWidth: false,
                                className: classes.formControl
                                }}
                                inputProps={{ placeholder: "Price" }}
                                value={this.state.product.customPrice}
                                onChange={(e) => this.customPriceChange(e.target.value)}
                                onBlur={(e) => this.customPriceVerify(e.target.value)}
                            />
                        </GridItem>
                        <GridItem xs={6} style={{textAlign:"center"}}>
                        <CustomInput
                            labelText="Commission"
                            formControlProps={{
                            fullWidth: false,
                            className: classes.formControl
                            }}
                            inputProps={{
                            placeholder: "Commission",
                            
                            }}
                            disabled
                            value={this.state.product.commission}
                        />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} style={{textAlign:"center", marginTop:"15px"}}>
                            <div style={{marginLeft:"69px"}}>
                                
                                <Paginations
                                    pages={[
                                    
                                    { text: 1, onClick:() => this.commissionChosen(3), active: this.isChosenCommission(3) },
                                    { text: 2, onClick:() => this.commissionChosen(5), active: this.isChosenCommission(5) },
                                    { text: 3, onClick:() => this.commissionChosen(7), active: this.isChosenCommission(7) },
                                    { text: 4, onClick:() => this.commissionChosen(9), active: this.isChosenCommission(9) },
                                    
                                    ]}
                                    color="success"
                                />
                            </div>
                            <div style={{color:"gray", fontSize:"12px"}}>Choose a Pricing Column</div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Popover>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
}
  
PricePopover = connect(mapStateToProps)(PricePopover);
export default withStyles(popoverStyles)(PricePopover);