const applyCustomPricing = (pricing, product) => {
  for (var p = 0; p < pricing.length; p++) {
    if (product.id === pricing[p].productID) {
      if (pricing[p].Price) {
        product.listPrice = pricing[p].Price;
      } else {
        product.originalPrice = product.listPrice;

        if (pricing[p].ruleType === 'discount') {
          var p1 = Number(
            (100 - pricing[p].percentage) / 100,
          );
          product.listPrice = (
            product.originalPrice * p1
          ).toFixed(2);
        } else if (pricing[p].ruleType === 'margin') {
          var p2 = Number(pricing[p].percentage / 100);
          var margin = product.originalPrice - product.cost;
          product.listPrice = (
            Number(product.cost) +
            margin * p2
          ).toFixed(2);
        } else if (pricing[p].ruleType === 'cost') {
          var p3 = Number(pricing[p].percentage / 100);
          product.listPrice = (
            Number(product.cost) +
            product.cost * p3
          ).toFixed(2);
        }
      }

      break;
    }
  }
}

export default applyCustomPricing
