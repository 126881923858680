//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//styles
import { withStyles } from '@material-ui/core/styles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.jsx';
//import checkStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

//core
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

//components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import SubcategoryPicker from 'components/CategoryPicker/SubcategoryPicker.jsx';

//icons
import Filter from '@material-ui/icons/FilterList';
import Check from '@material-ui/icons/Check';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class CategoryPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      selectedText: '',
    };

    var options = [];
    var selected = [];
    for (var i = 0; i < this.props.categories.length; i++) {
      options.push({
        key: this.props.categories[i].key,
        label: this.props.categories[i].label,
      });
      selected.push(this.props.categories[i].key);
    }
    this.state.options = options;
    this.state.selected = selected;

    this.filterResults = this.filterResults.bind(this);
    this.setSelectedText = this.setSelectedText.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.updateSelected = this.updateSelected.bind(this);
  }

  componentDidMount() {
    this.loadSelected();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.loadSelected();
    }
  }

  // dialog open/close
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadSelected() {
    var selected = [];
    for (var j = 0; j < this.props.selected.length; j++)
      selected.push(this.props.selected[j]);
    this.setState({ selected: selected }, this.setSelectedText);
  }

  toggleCategory(value) {
    const checked = this.state.selected;
    const newChecked = [...checked];
    const currentIndex = checked.indexOf(value);

    console.log(newChecked);
    this.props.categories.forEach((category) => {
      if (category.key === value) {
        // now we have the category in question

        console.log(category);

        if (currentIndex === -1) {
          // add a category
          newChecked.push(value);

          // add all sub-categories
          category.SubCat1.forEach((subCategory) => {
            // add all items in subcategory 1
            newChecked.push(subCategory.id);
          });
          category.SubCat2.forEach((subCategory) => {
            // add all items in subcategory 2
            newChecked.push(subCategory.id);
          });
          category.SubCat3.forEach((subCategory) => {
            // add all items in subcategory 3
            newChecked.push(subCategory.id);
          });
        } else {
          // remove a category
          newChecked.splice(currentIndex, 1);

          category.SubCat1.forEach((subCategory) => {
            // remove all checked items in subcategory 1
            for (var i = newChecked.length - 1; i > -1; i--) {
              if (newChecked[i] === subCategory.id) {
                newChecked.splice(i, 1);
                break;
              }
            }
          });

          category.SubCat2.forEach((subCategory) => {
            // remove all checked items in subcategory 2
            for (var i = newChecked.length - 1; i > -1; i--) {
              if (newChecked[i] === subCategory.id) {
                newChecked.splice(i, 1);
                break;
              }
            }
          });

          category.SubCat3.forEach((subCategory) => {
            // remove all checked items in subcategory 3
            for (var i = newChecked.length - 1; i > -1; i--) {
              if (newChecked[i] === subCategory.id) {
                newChecked.splice(i, 1);
                break;
              }
            }
          });
        }
      }
    });

    this.setState({ selected: newChecked, group: '' }, () => {
      this.setSelectedText();
    });
  }

  toggleAll() {
    // TODO, selecting subcategories (unselecting already works)
    var selected = [];
    if (this.state.selected.length === 0) {
      for (var i = 0; i < this.state.options.length; i++)
        selected.push(this.state.options[i].key);
    }
    this.setState({ selected: selected, category: '' }, () => {
      this.setSelectedText();
    });
  }

  filterResults() {
    this.props.onChange(this.state.selected);
    this.handleClose('modal');
  }

  setSelectedText() {
    var selectedText = '';

    this.state.selected.forEach((key) => {
      for (var i = 0; i < this.props.categories.length; i++) {
        if (this.props.categories[i].key === key) {
          selectedText = selectedText + this.props.categories[i].label + ', ';
          break;
        }
      }
    });

    if (selectedText.length > 0)
      // remove trailing comma
      selectedText = selectedText.substr(0, selectedText.length - 2);

    this.setState({ selectedText: selectedText });
  }

  updateSelected(selected) {
    this.setState({ selected: selected });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={11}>
            <CustomInput
              disabled
              labelText="Categories"
              inputProps={{ placeholder: 'Categories' }}
              formControlProps={{ fullWidth: true }}
              value={this.state.selectedText}
              placeholder="all"
            />
          </GridItem>
          <GridItem xs={1}>
            <IconButton
              style={{ marginTop: '14px' }}
              onClick={() => this.handleClickOpen('modal')}
            >
              <Filter />
            </IconButton>
          </GridItem>
        </GridContainer>
        <Dialog
          classes={{ root: classes.center, paper: classes.modal }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth={'md'}
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={12}>
                <h4 className={classes.modalTitle}>Categories</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            style={{ backgroundColor: '#efefef', paddingBottom: '0px' }}
          >
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardBody style={{ overflow: 'scroll', maxHeight: '400px' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            <IconButton onClick={this.toggleAll}>
                              <Check style={{ color: 'green' }} />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            Category
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            Sub-Categories
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.categories.map((category, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ padding: '3px' }}>
                              <Checkbox
                                tabIndex={-1}
                                onClick={() =>
                                  this.toggleCategory(category.key)
                                }
                                checked={
                                  this.state.selected.indexOf(category.key) !==
                                  -1
                                    ? true
                                    : false
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {category.label}
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              <SubcategoryPicker
                                category={category}
                                selected={this.state.selected}
                                onChange={this.updateSelected}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            style={{
              backgroundColor: '#efefef',
              paddingLeft: '17px',
              paddingTop: '-20px',
              paddingRight: '17px',
              paddingBottom: '17px',
              margin: '0px',
            }}
          >
            <GridContainer style={{ width: '100%' }}>
              <GridItem xs={12} style={{ textAlign: 'right' }}>
                <Button color="success" onClick={this.filterResults}>
                  <Filter />
                  OK
                </Button>
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

CategoryPicker = connect(mapStateToProps)(CategoryPicker);
export default withStyles(modalStyle)(CategoryPicker);
