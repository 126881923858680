
import BlogPostPage from "views/BlogPostPage/BlogPostPage.jsx";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.jsx";
import ComponentsPage from "views/ComponentsPage/ComponentsPage.jsx";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.jsx";
import EcommercePage from "views/EcommercePage/EcommercePage.jsx";
import InvoicesList from "views/InvoicesList/InvoicesList.jsx";
import EstimatesList from "views/EstimatesList/EstimatesList.jsx";
import LocationList from "views/LocationList/LocationList.jsx";
import LoginPage from "views/LoginPage/LoginPage.jsx";
import LogoutPage from "views/LogoutPage/LogoutPage.jsx";
import OrderHistoryPage from "views/OrderHistoryPage/OrderHistoryPage.jsx";
import ReloadPage from "views/ReloadPage/ReloadPage.jsx";
import ReportingPage from "views/ReportingPage/ReportingPage.jsx";
import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.jsx";
import SettingsPage from 'views/SettingsPage/SettingsPage.jsx';
import SignupPage from "views/SignupPage/SignupPage.jsx";
import SpecialsPage from 'views/SpecialsPage/SpecialsPage.jsx';

let indexRoutes = [

  { path: "/blog-post", name: "BlogPostPage", component: BlogPostPage },
  { path: "/blog-posts", name: "BlogPostsPage", component: BlogPostsPage },
  { path: "/components", name: "Components", component: ComponentsPage },
  { path: "/contact-us", name: "ContactUsPage", component: ContactUsPage },
  { path: "/ecommerce-page", name: "EcommercePage", component: EcommercePage },
  { path: "/login/", name: "LoginPage", component: LoginPage },
  { path: "/logout", name: "LogoutPage", component: LogoutPage },
  
  { path: "/signup/:id", name: "SignupPage", component: SignupPage },
  { path: "/signup", name: "SignupPage", component: SignupPage },

  { path: "/locations", name: "LocationList", component: LocationList },
  { path: "/invoices", name: "InvoicesList", component: InvoicesList },
  { path: "/estimates", name: "EstimatesList", component: EstimatesList },
  { path: "/orders", name: "OrderHistoryPage", component: OrderHistoryPage },
  { path: "/products", name: "EcommercePage", component: EcommercePage },
  { path: "/reporting", name: "Reporting", component: ReportingPage },
  { path: "/specials", name: "SpecialsPage", component: SpecialsPage },
  { path: "/cart", name: "ShoppingCartPage", component: ShoppingCartPage },
  { path: "/settings", name: "SettingsPage", component: SettingsPage },

  { path: "/reload", name: "ReloadPage", component: ReloadPage },
  
  { path: "/", name: "LoginPage", component: LoginPage }

];

export default indexRoutes;
