/* eslint-disable */
import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// react components for routing our app without refresh
import { Redirect, Link } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavStateChanged } from 'redux/actions';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";

// @material-ui/core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Badge from 'components/Badge/Badge.jsx';
import Hidden from '@material-ui/core/Hidden';

//ecclesia
import CustomerChooser from "components/CustomerChooser/CustomerChooser.jsx";
  
//icons
import Group from "@material-ui/icons/Group";
import Event from "@material-ui/icons/Event";
import History from "@material-ui/icons/History";
import Person from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Location from "@material-ui/icons/LocationOn";
import Estimate from "@material-ui/icons/ListAlt";
import Report from "@material-ui/icons/PieChart";
import Catalog from "@material-ui/icons/Store";
import Settings from '@material-ui/icons/Settings';

class HeaderLinks extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cart: props.cart,
      query:"",
      customerID:"",
      customers:[],
      openCustomerChooser: false,
      catalogRedirect: false
    }

    if(props.customers)
      this.state.customers = props.customers;

    this.catalogClick = this.catalogClick.bind(this);
    this.setSearchTimer = this.setSearchTimer.bind(this);
    this.searchProducts = this.searchProducts.bind(this);

  }

  componentDidUpdate(prevProps) {
        
    if(prevProps.searchQuery !== this.props.searchQuery) {
      this.setState({query: this.props.searchQuery}, () => {
      
      });
    }

    if(prevProps.cart !== this.props.cart) {
      this.setState({cart: this.props.cart}, () => {
      
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.searchProducts);
  }
  
  easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  
  scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  authLink = () => {
    if (this.props.user.authenticated) {
      return "/logout";
    } else {
      return "/login"
    }
  }
  
  authText = () => {
    if (this.props.user.authenticated) {
      return "Logout";
    } else {
      return "Login"
    }
  }

  cartCount = () => {
    if(this.props.cart.length > 0) {
      return <GridContainer>
              <GridItem xs={5} style={{textAlign:"right"}}><ShoppingCart /></GridItem>
              <GridItem xs={5}><Badge color="success">{this.state.cart.length}</Badge></GridItem>
             </GridContainer>
    } else {
      return <div></div>
    }
  }

  setSearchTimer(query) {
    clearTimeout(this.searchProducts);
    this.setState({query}, () => {
        setTimeout(this.searchProducts,2300);
    });
  }

  searchProducts() {
    this.props.onSearch(this.state.query);
  }

  showSearch() {
    if(this.props.onSearch && (this.props.user.orderingPermissions || this.props.user.userType === 'admin' || this.props.user.userType === 'Affiliate')) {
      return "block";
    } else {
      return "none";
    }
  }

  showShopping() {
    if(this.props.user.orderingPermissions || this.props.user.userType === 'admin' || this.props.user.userType === 'Affiliate') {
      return "block";
    } else {
      return "none";
    }
  }

  showEstimates() {
    if(this.props.user.userType === 'Affiliate') {
      return "block";
    } else {
      return "none";
    }
  }

  showInvoices() {
    if(this.props.user.customerID 
      && (this.props.user.invoicePermissions || this.props.user.userType === 'admin' || this.props.user.userType === 'Affiliate' ) )
      return "flex";
    else
      return "none";
  }

  showReporting() {
    if(this.props.user.userType == 'admin') { //this.props.user.userType === 'Affiliate' || ) {
      return "block";
    } else {
      return "none";
    }
  }

  showCustomerSwitcher() {
    if(this.props.user.userType === 'admin' || this.props.user.userType === 'Affiliate' )
      return "block";
    else
      return "none";
  }

  companyName() {
    if(this.props.user.companyName) {
      return this.props.user.companyName;
    } else { 
      for(var i = 0; i < this.props.customers.length; i++) {
        if(this.props.customers[i].id === this.props.user.customerID) {
          return this.props.customers[i].company_name;
        }
      }
    }
  }

  catalogClick() {
    this.props.onNavStateChanged('catalog');
    if(window.location.pathname !== '/products')
      this.setState({catalogRedirect: true});
  }

  render() {
    const { classes, ...rest } = this.props;

    if(this.state.catalogRedirect)
      return <Redirect to='/products' />

    return (
      <List className={classes.list + " " + classes.mlAuto}>
        <ListItem style={{display:this.showShopping(), color:"white", fontWeight:400, fontSize:"13px", cursor:"pointer"}} onClick={this.catalogClick}>  
            Catalog
        </ListItem>  
        
        <Hidden mdUp>
        <ListItem style={{display:this.showSearch()}}>
          <CustomInput
                value={this.state.query}
                inputProps={{ placeholder: "Search" }}
                formControlProps={{ fullWidth: true, style:{paddingTop:"0px", margin:"0px", width:"134px"} }}
                onChange={(e) => this.setSearchTimer(e.target.value)}
              />
        </ListItem>
          <ListItem style={{display:this.showShopping()}}>
            <Link to="/cart" className={classes.dropdownLink} >
              <ShoppingCart className={classes.dropdownIcons} /> Cart &nbsp; &nbsp; <Badge color="success">{this.state.cart.length}</Badge>
            </Link>
          </ListItem>
          <ListItem style={{display:this.showShopping(), cursor:"pointer"}} onClick={this.catalogClick}>
              <Catalog className={classes.dropdownIcons} /> Catalog
          </ListItem> 
          <ListItem style={{display:this.showEstimates()}}>
            <Link to="/estimates" className={classes.dropdownLink} >
              <Estimate className={classes.dropdownIcons} /> Estimates
            </Link>
          </ListItem>  
          <ListItem style={{display:this.showShopping()}}>
            <Link to="/orders" className={classes.dropdownLink} >
              <History className={classes.dropdownIcons} /> Order History
            </Link>
          </ListItem>
          <ListItem style={{display:this.showInvoices()}}>
            <Link to="/invoices" className={classes.dropdownLink} >
              <Event className={classes.dropdownIcons} /> Invoices
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/locations" className={classes.dropdownLink} >
              <Location className={classes.dropdownIcons} /> Locations
            </Link>
          </ListItem>
          <ListItem style={{display:this.showReporting()}}>
            <Link to="/reporting" className={classes.dropdownLink} >
              <Report className={classes.dropdownIcons} /> Reporting
            </Link>
          </ListItem>
          <ListItem style={{display:this.showCustomerSwitcher()}} onClick={() => this.setState({openCustomerChooser: true})}>
            <Link to="#" className={classes.dropdownLink} >
              <Group className={classes.dropdownIcons} /> Switch Customer
            </Link>
          </ListItem>
          <ListItem style={{display: 'block'}}>
            <Link to="/settings" className={classes.dropdownLink} >
              <Settings className={classes.dropdownIcons} /> Settings
            </Link>
          </ListItem>
          <ListItem>
            <Link to={this.authLink()} className={classes.dropdownLink}>
              <Lock className={classes.dropdownIcons} /> {this.authText()} 
            </Link>
          </ListItem>
        </Hidden>
        <Hidden smDown>
          <ListItem style={{display:this.showSearch()}}>
            <CustomInput
                  value={this.state.query}
                  inputProps={{ placeholder: "Search" }}
                  formControlProps={{ fullWidth: true, style:{paddingTop:"0px", margin:"0px", width:"134px"} }}
                  onChange={(e) => this.setSearchTimer(e.target.value)}
                  white
                />
          </ListItem>
          <ListItem style={{display:this.showShopping()}}>
            <Link to="/cart" style={{color:"white", fontWeight:400}}>
              <GridContainer>
                <GridItem xs={5} style={{textAlign:"right"}}><ShoppingCart /></GridItem>
                <GridItem xs={5}><Badge color="success">{this.state.cart.length}</Badge></GridItem>
              </GridContainer>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <CustomDropdown
              noLiPadding
              navDropdown
              hoverColor="success"
              buttonText={this.companyName()}
              buttonProps={{
                className: classes.navLink,
                color: "transparent"
              }}
              buttonIcon={Person}
              dropdownList={[
                <Link to="/estimates" className={classes.dropdownLink} style={{display:this.showEstimates()}}>
                  <Estimate className={classes.dropdownIcons} /> Estimates
                </Link>,
                <Link to="/orders" className={classes.dropdownLink} style={{display:this.showShopping()}}>
                  <History className={classes.dropdownIcons} /> Order History
                </Link>,
                <Link to="/invoices" className={classes.dropdownLink} style={{display:this.showInvoices()}}>
                  <Event className={classes.dropdownIcons} /> Invoices
                </Link>,
                <Link to="/locations" className={classes.dropdownLink} >
                  <Location className={classes.dropdownIcons} /> Locations
                </Link>,
                <Link to="/reporting" className={classes.dropdownLink} style={{display:this.showReporting()}}>
                  <Report className={classes.dropdownIcons} /> Reporting
                </Link>,
                <Link to="#" onClick={() => this.setState({openCustomerChooser: true})} className={classes.dropdownLink} style={{display:this.showCustomerSwitcher()}}>
                  <Group className={classes.dropdownIcons} /> Switch Customer
                </Link>,    
                <Link to="/settings" className={classes.dropdownLink} >
                  <Settings className={classes.dropdownIcons} /> Settings
                </Link>,
                <Link to={this.authLink()} className={classes.dropdownLink}>
                  <Lock className={classes.dropdownIcons} /> {this.authText()} 
                </Link>
                
              ]}
            />
          </ListItem>
        </Hidden>
        <CustomerChooser isOpen={this.state.openCustomerChooser}  />
      </List>
    );
  }
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};


const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onNavStateChanged: NavStateChanged }, dispatch);  
}

HeaderLinks = connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);
//HeaderLinks = withMobileDialog()(HeaderLinks);
export default withStyles(headerLinksStyle)(HeaderLinks);
