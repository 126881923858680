import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
//import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import { CartProductAdded } from 'redux/actions';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.jsx";

//components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
//import Button from "components/CustomButtons/Button.jsx";

//kekaha
import Estimate from "components/Estimate/Estimate.jsx";

//icons
import List from "@material-ui/icons/ListAlt";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class EstimatesList extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
          estimates: []
        };

        this.estimateChanged = this.estimateChanged.bind(this);
        this.getEstimates = this.getEstimates.bind(this);
        this.refreshEstimates = this.refreshEstimates.bind(this);

        this.refreshEstimates();
    }

    getEstimates(callback) {
        
        db.getEstimatesByUser(this.props.user.id).then(estimates => {
            callback(estimates);
        });
    }

    refreshEstimates() {
        
        var filtered = [];
        this.getEstimates(estimates => {
            
            for(var i = 0; i < estimates.length; i++) {
/*
                if(!estimates[i].estimateTotal)
                    estimates[i].estimateTotal = 0;
                estimates[i].estimateTotal = Number(estimates[i].estimateTotal).toFixed(2);
*/

                var estimateTotal = 0.0;
                for(var o = 0; o < estimates[i].Items.length; o++) {

                    var qty = 1;
                    if(estimates[i].Items[o].qty)
                        qty = estimates[i].Items[o].qty;
                    else //temp for test orders
                        estimates[i].Items[o].qty = 1;

                    estimates[i].Items[o].listPrice = Number(estimates[i].Items[o].listPrice).toFixed(2);

                    estimates[i].Items[o].total = Number(estimates[i].Items[o].customPrice * qty).toFixed(2);
                    estimateTotal = estimateTotal + (estimates[i].Items[o].customPrice * qty);
                }

                estimates[i].estimateTotal = estimateTotal.toFixed(2);
                estimates[i].dateCreated = new Date(estimates[i].created * 1000).toLocaleDateString();

                filtered.push(estimates[i]);     
            }

            this.setState({estimates: filtered});
        });
    }

    estimateChanged(estimate) {
        var estimates = this.state.estimates;
        for(var i = 0; i < estimates.length; i++) {
            if(estimates[i].id === estimate.id) {
                estimates[i] = estimate;
                break;
            }
        }
        this.setState({estimates: estimates});
    }

    showNoResults() {
        if(this.state.estimates.length === 0) {
            return "block";
        } else {
            return "none";
        }
    }

    render() {
        const { classes } = this.props;
    
        if (!this.props.user) {
            return <Redirect to='/login' />
        } else if (!this.props.user.authenticated) {
            return <Redirect to='/login' />
        }

        return (
          <div>
            <Header
              brand="Ecclesia"
              links={<HeaderLinks dropdownHoverColor="info" />}
              fixed
              color="dark"
            />
            <div className={classes.container} style={{minHeight:"369px", marginTop:"100px"}}>
                <GridContainer>
                    <GridItem xs={12}>
                        <h3 className={classes.cardTitle}>Estimates</h3>
                    </GridItem>

                    <CustomTabs
                        headerColor="success"
                        tabs={[
                            {
                                tabName: "Estimates",
                                tabIcon: List,
                                tabContent: (
                                    <GridContainer>
                                        <GridItem xs={12}>           
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{paddingRight:"6px"}}>

                                                        </TableCell>
                                                        <TableCell style={{paddingRight:"6px"}}>
                                                            Date Created
                                                        </TableCell>
                                                        <TableCell style={{paddingRight:"6px"}}>
                                                            Prospective Customer
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right"}}>
                                                            Estimate Total
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right"}}>
                                                            Estimated Commission
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right"}}>
                                                            Status
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.estimates.map((estimate,index) => (
                                                    <TableRow key={index}>
                                                        <TableCell style={{paddingRight:"6px"}}>
                                                            <Estimate estimate={estimate} iconButton estimateSaved={this.estimateChanged} />
                                                        </TableCell>
                                                        <TableCell style={{paddingRight:"6px"}}>
                                                            {estimate.dateCreated}
                                                        </TableCell>
                                                        <TableCell style={{paddingRight:"6px"}}>
                                                            {estimate.CustomerName}
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right"}}>
                                                            ${numberWithCommas(estimate.estimateTotal)}
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right", color:"#3fa31b"}}>
                                                            ${numberWithCommas(estimate.totalCommission)}
                                                        </TableCell>
                                                        <TableCell style={{textAlign:"right"}}>
                                                            {estimate.status}
                                                        </TableCell>
                                                    </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>

                                        </GridItem>

                                        <GridItem xs={12} style={{display:this.showNoResults(), textAlign:"center", color:"maroon", fontSize:"12px", marginTop:"50px"}}>
                                            Sorry, we did not find any estimates.
                                        </GridItem>
                                    </GridContainer>
                                )}
                            ]}
                    />
                </GridContainer>
            </div>
            <Footer
                content={
                    <div>
                    <div className={classes.right} style={{fontSize:"13px"}}>
                        &copy; {1900 + new Date().getYear()} Ecclesia, Inc.
                    </div>
                    </div>
                }
            />
          </div>
        );
    }
}

const mapStateToProps = state => {
  return state;
}
/*
function mapDispatchToProps(dispatch) {
    return bindActionCreators({onCartProductAdded: CartProductAdded }, dispatch);  
}*/

EstimatesList = connect(mapStateToProps)(EstimatesList);
export default withStyles(shoppingCartStyle)(EstimatesList);
