import React from "react";

//redux
import { connect } from "react-redux";

// html parser
import Parser from 'html-react-parser';

//styles
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";


class SectionHTML extends React.Component {

   
    render() {

        const { classes } = this.props;

        return (
            <div className={classNames(classes.container)} style={{ paddingBottom:"70px" }}>
          
                <GridContainer>
                    <GridItem xs={12} style={{color:"black"}}>
                        {Parser(this.props.content)}
                    </GridItem>
                </GridContainer>
                
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return state;
};
  
SectionHTML = connect(mapStateToProps)(SectionHTML);
export default withStyles(landingPageStyle)(SectionHTML);
