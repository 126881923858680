

//algolia full text search
import algoliaSearch from 'algoliasearch';

//var searchClient = algoliaSearch('XNS8WHB8E8', '6f84d3fc8047e897714f2e57e071360c');
var searchClient = algoliaSearch('XNS8WHB8E8', '52b877f6f4481db6ea2785b2292b22cb');
var productIndex = searchClient.initIndex('Products');

/*
export const applySettings = () => {
    productIndex.setSettings({
        searchableAttributes: ['name', 'productID', 'productIDNumber'],
        queryType: 'prefixLast'
      });
}
*/
export const searchProducts = (query, manufacturers, category, subCat1, subCat2, subCat3) => {

    return new Promise(function(resolve, reject) {
        
        var filter = "isVisible:true ";
        
        if(category) 
            filter = filter + " AND categoryID:" + category 
        
        if(subCat1) {
            if(subCat1.length > 0) {
                filter = filter + " AND ("
                for(var j = 0; j < subCat1.length; j++) {
                    if(j === subCat1.length - 1) 
                        filter = filter + "subCat1ID:" + subCat1[j];
                    else 
                        filter = filter + "subCat1ID:" + subCat1[j] + " OR ";
                }
                filter = filter + ")"
            }
        }
        
        if(subCat2) {
            if(subCat2.length > 0){
                filter = filter + " AND ("
                for(var k = 0; k < subCat2.length; k++) {
                    if(k === subCat2.length - 1) 
                        filter = filter + "subCat2ID:" + subCat2[k];
                    else 
                        filter = filter + "subCat2ID:" + subCat2[k] + " OR ";
                }
                filter = filter + ")"
            }
        }
        
        if(subCat3) {
            if(subCat3.length > 0){
                filter = filter + " AND ("
                for(var l = 0; l < subCat3.length; l++) {
                    if(l === subCat3.length - 1) 
                        filter = filter + "subCat3ID:" + subCat3[l];
                    else 
                        filter = filter + "subCat3ID:" + subCat3[l] + " OR ";
                }
                filter = filter + ")"
            }
        }
        
        if(manufacturers) {
            if(manufacturers.length > 0) {
                filter = filter + " AND ("
                for(var m = 0; m < manufacturers.length; m++) {
                    if(m === manufacturers.length - 1)
                        filter = filter + "manufacturerID:" + manufacturers[m];
                    else
                        filter = filter + "manufacturerID:" + manufacturers[m] + " OR ";
                }
                filter = filter + ")"
            }
        }
        
        productIndex.search({query: query + "/", filters: filter, hitsPerPage: 600}).then(results => {
            
            var objs = [];
            
            for(var i = 0; i < results.hits.length; i++) {
                var obj = results.hits[i];
                objs.push(obj);
            }

            resolve(objs);
        });
    });
}

export const getProductsIDs = (query, manufacturers, category, subCat1, subCat2, subCat3, referenceData) => {

    return new Promise(function(resolve, reject) {
        
        var filter = "categoryID:" + category 

        if(subCat1.length > 0){
            filter = filter + " AND ("
            for(var j = 0; j < subCat1.length; j++) {
                if(j === subCat1.length - 1) 
                    filter = filter + "subCat1ID:" + subCat1[j];
                else 
                    filter = filter + "subCat1ID:" + subCat1[j] + " OR ";
            }
            filter = filter + ")"
        }

        if(subCat2.length > 0){
            filter = filter + " AND ("
            for(var k = 0; k < subCat2.length; k++) {
                if(k === subCat2.length - 1) 
                    filter = filter + "subCat2ID:" + subCat2[k];
                else 
                    filter = filter + "subCat2ID:" + subCat2[k] + " OR ";
            }
            filter = filter + ")"
        }

        if(subCat3.length > 0){
            filter = filter + " AND ("
            for(var l = 0; l < subCat3.length; l++) {
                if(l === subCat3.length - 1) 
                    filter = filter + "subCat3ID:" + subCat3[l];
                else 
                    filter = filter + "subCat3ID:" + subCat3[l] + " OR ";
            }
            filter = filter + ")"
        }

        if(manufacturers.length > 0) {
            filter = filter + " AND ("
            for(var i = 0; i < manufacturers.length; i++) {
                if(i === manufacturers.length - 1)
                    filter = filter + "manufacturerID:" + manufacturers[i];
                else
                    filter = filter + "manufacturerID:" + manufacturers[i] + " OR ";
            }
            filter = filter + ")"
        }
        
    /*      
        filter = filter + " AND "        
        filter = filter + "isVisible:true"
    */

        productIndex.search({query: query, filters: filter, attributesToRetrieve: [
            "id",
            "content"
          ], hitsPerPage: 500}).then(results => {
            
            var objs = [];
            
            for(var i = 0; i < results.hits.length; i++) {
                var obj = results.hits[i];
                obj.referenceData = referenceData;
                objs.push(obj);
            }

            resolve(objs);
        });
    });
}

export const getProductsByID = (id) => {

    return new Promise(function(resolve, reject) {
        
        productIndex.search({query: id, restrictSearchableAttributes: ['productID']}).then(result => {
            
            console.log("result.hits");
            console.log(result.hits);

            var objs = [];
            
            for(var i = 0; i < result.hits.length; i++) {
                var obj = result.hits[i];
                objs.push(obj);
            }

            resolve(objs);
        });
    });
}

export const getProductsByName = (name) => {

    return new Promise(function(resolve, reject) {
        
        productIndex.search({query: name, restrictSearchableAttributes: ['name']}).then(result => {
            
            console.log("result.hits");
            console.log(result.hits);

            var objs = [];
            
            for(var i = 0; i < result.hits.length; i++) {
                var obj = result.hits[i];
                objs.push(obj);
            }

            resolve(objs);
        });
    });
}