import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavStateChanged } from 'redux/actions';

//styles
import classNames from "classnames";
import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";

//devtools
import PropTypes from "prop-types";

//components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";

//icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";

//images
import Logo from "assets/img/logo.png";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      goHome: false
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.landingPage = this.landingPage.bind(this);
  }

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  componentDidMount() {
    
  }

  landingPage() {
    this.props.onNavStateChanged('landing');
    if(window.location.pathname !== '/products')
      this.setState({goHome: true});
  }

  render() {
    const { classes, color, links, fixed, absolute } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed
    });

    if(this.state.goHome)
      return <Redirect to='/products' /> 
      
    return (
      <AppBar className={appBarClasses}>
        <Toolbar className={classes.container}>
          <img src={Logo} style={{height:"34px", cursor:"pointer"}} alt="logo" onClick={this.landingPage} />
          <Hidden smDown implementation="css" className={classes.hidden}>
            <div className={classes.collapse} >{links}</div>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={this.state.mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={this.handleDrawerToggle}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.closeButtonDrawer}
            >
              <Close />
            </IconButton>
            <div className={classes.appResponsive}>{links}</div>
          </Drawer>
        </Hidden>
      </AppBar>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onNavStateChanged: NavStateChanged }, dispatch);  
}

Header = connect(mapStateToProps, mapDispatchToProps)(Header);
export default withStyles(headerStyle)(Header);
