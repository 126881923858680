//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.jsx';

//core
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Hidden from '@material-ui/core/Hidden';

//components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from 'components/CustomButtons/Button.jsx';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress.jsx';

//icons
import Search from '@material-ui/icons/Search';
import Filter from '@material-ui/icons/FilterList';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

//const uuidv4 = require('uuid/v4');

function getMonday() {
  var d = new Date();
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  return new Date(d.setDate(diff));
}

function daysBetween(date1, date2) {
  //Get 1 day in milliseconds
  var one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  var difference_ms = date2_ms - date1_ms;

  // Convert back to days and return
  return Math.round(difference_ms / one_day);
}

function getDayOfYear(fiscalStartDate) {
  var now = new Date();
  var start = new Date(now.getFullYear(), 0, 0);
  if (fiscalStartDate) start = fiscalStartDate;
  var diff =
    now -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

class AddressChooser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,

      shipToAddress: { name: '' },
      filtered: props.locations,

      addressName: '',
      addressStreet: '',
      addressCity: '',
      addressState: '',
      addressPostal: '',

      showNewAddressForm: false,

      budget: 0.0,
      budgetSpent: 0.0,
      budgetRemaining: 0,
      budgetPercentageRemaining: 100,

      annualBudget: 0,
      annualSpend: 0,
      annualBudgetRemaining: 0,
      annualPercentageRemaining: 100,
    };

    if (props.shipToAddress) {
      this.state.shipToAddress = props.shipToAddress;
    }

    this.getAddresses = this.getAddresses.bind(this);

    this.searchAddresses = this.searchAddresses.bind(this);
    this.chooseAddress = this.chooseAddress.bind(this);
    this.chooseAllAddresses = this.chooseAllAddresses.bind(this);

    this.showShipToAddress = this.showShipToAddress.bind(this);

    this.addressNameChange = this.addressNameChange.bind(this);
    this.addressStreetChange = this.addressStreetChange.bind(this);
    this.addressCityChange = this.addressCityChange.bind(this);
    this.addressStateChange = this.addressStateChange.bind(this);
    this.addressPostalChange = this.addressPostalChange.bind(this);

    this.addNewAddress = this.addNewAddress.bind(this);
    this.saveAddress = this.saveAddress.bind(this);

    this.getBudgetInformation = this.getBudgetInformation.bind(this);
  }

  componentDidMount() {
    this.getAddresses();
  }

  /*
  // initializing with a chosen address
  componentWillReceiveProps(nextProps){

    if(nextProps.cartTotal) {
      this.getBudgetInformation();
    }
  }
  */

  componentDidUpdate(prevProps) {
    if (prevProps.cartTotal !== this.props.cartTotal) {
      this.getBudgetInformation();
    }
  }

  // dialog open/close
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  getAddresses() {
    if (
      !this.state.shipToAddress.address_id &&
      this.props.locations.length > 0
    ) {
      if (this.props.shipTo && this.props.shipTo !== 'all') {
        var isFound = false;
        for (var k = 0; k < this.props.locations.length; k++) {
          if (this.props.locations[k].address_id === this.props.shipTo) {
            this.chooseAddress(this.props.locations[k]);
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          this.chooseAddress(this.props.locations[0]);
        }
      } else if (this.props.shipTo === 'all' && !this.props.chooseShipTo) {
        this.chooseAllAddresses();
      } else {
        this.chooseAddress(this.props.locations[0]);
      }
    }

    if (this.props.locations.length === 0 && !this.props.user.customerID) {
      this.setState({ showNewAddressForm: true });
    }
  }

  // return a filter of a single address or all addresses
  chooseAddress(address) {
    this.setState({ shipToAddress: address }, () => {
      this.getBudgetInformation();
    });

    if (this.props.onAddressChosen)
      this.props.onAddressChosen(address.address_id);

    this.handleClose('modal');
  }

  getOrderBudgetAmount(order) {
    if (this.props.budgetExceptions) {
      if (this.props.budgetExceptions.length > 0) {
        var total = 0.0;
        var excludedTotal = 0.0;

        for (var i = 0; i < order.items.length; i++) {
          var isExcluded = false;

          for (var j = 0; j < this.props.budgetExceptions.length; j++) {
            if (
              this.props.budgetExceptions[j].productID === order.items[i].id
            ) {
              isExcluded = true;
              excludedTotal = excludedTotal + Number(order.items[i].total);
              break;
            }
          }

          if (!isExcluded) {
            total = total + Number(order.items[i].total);
          }
        }

        return Number(total);
      } else {
        return Number(order.orderTotal);
      }
    } else {
      return Number(order.orderTotal);
    }
  }

  getBudgetInformation() {
    var addressID = this.state.shipToAddress.address_id;

    if (this.props.cart) {
      if (addressID) {
        db.getBudgetByAddress(addressID).then((budget) => {
          this.setState({ budget: budget });

          db.getOrdersByAddress(addressID).then((orders) => {
            var today = new Date();

            var fiscalStartDate = new Date(
              today.getFullYear(),
              0,
              1,
              0,
              0,
              0,
              0,
            );
            if (budget.fiscalStart)
              fiscalStartDate = new Date(budget.fiscalStart);

            var periodStartDate;
            var periodPercentInto;

            if (budget.period === 12) {
              periodStartDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                1,
                0,
                0,
                0,
                0,
              );
              var daysIntoPeriod = daysBetween(periodStartDate, today);
              periodPercentInto = daysIntoPeriod / 29;
            } else if (budget.period === 52) {
              periodStartDate = getMonday(today);
              periodPercentInto = today.getDay() / 7;
            }

            var periodSpend = 0.0;
            var expectedSpend = budget.amount * periodPercentInto;

            var annualSpend = 0.0;
            var annualBudget = (budget.amount * budget.period).toFixed(2);
            var dayOfYear = getDayOfYear(fiscalStartDate);
            var annualExpectedSpend = annualBudget * (dayOfYear / 365);

            // get starting balances
            if (budget.startingBalance)
              annualSpend = Number(budget.startingBalance);

            // first exclude what's appropriate from previous orders
            for (var i = 0; i < orders.length; i++) {
              var orderPlaced = new Date(orders[i].orderPlaced);

              if (orderPlaced.getTime() >= periodStartDate.getTime())
                periodSpend = (
                  Number(periodSpend) + this.getOrderBudgetAmount(orders[i])
                ).toFixed(2);

              if (orderPlaced.getTime() >= fiscalStartDate.getTime())
                annualSpend = (
                  Number(annualSpend) + this.getOrderBudgetAmount(orders[i])
                ).toFixed(2);
            }

            // now exclude what's appropriate from the cart
            var cartTotal = 0.0;
            for (var k = 0; k < this.props.cart.length; k++) {
              var isExcluded = false;

              for (var j = 0; j < this.props.budgetExceptions.length; j++) {
                if (
                  this.props.budgetExceptions[j].productID ===
                  this.props.cart[k].id
                ) {
                  isExcluded = true;
                  break;
                }
              }

              if (!isExcluded) {
                cartTotal =
                  cartTotal +
                  Number(this.props.cart[k].qty) *
                    Number(this.props.cart[k].listPrice);
              }
            }

            periodSpend = (Number(periodSpend) + Number(cartTotal)).toFixed(2);
            annualSpend = (Number(annualSpend) + Number(cartTotal)).toFixed(2);

            var budgetRemaining = (
              Number(budget.amount) - Number(periodSpend)
            ).toFixed(2);
            var annualBudgetRemaining = (
              Number(annualBudget) - Number(annualSpend)
            ).toFixed(2);

            var budgetPercentageRemaining = 100;
            var annualPercentageRemaining = 100;
            if (budget.amount > 0) {
              budgetPercentageRemaining = (periodSpend / budget.amount) * 100;
              annualPercentageRemaining = (annualSpend / annualBudget) * 100;
            }

            this.setState({
              annualSpend: annualSpend,
              annualExpectedSpend: annualExpectedSpend,
              annualBudget: annualBudget,
              annualBudgetRemaining: annualBudgetRemaining,
              annualPercentageRemaining: annualPercentageRemaining,
              budgetSpent: periodSpend,
              expectedSpend: expectedSpend,
              budgetRemaining: budgetRemaining,
              budgetPercentageRemaining: budgetPercentageRemaining,
            });
          });
        });
      }
    }
  }

  getBudgetStatusColor() {
    if (this.state.budgetSpent > this.state.expectedSpend * 1.2) {
      return 'danger';
    } else if (this.state.budgetSpent > this.state.expectedSpend) {
      return 'warning';
    } else {
      return 'success';
    }
  }

  getAnnualBudgetStatusColor() {
    var abovePaceWarningThreshold = this.state.annualExpectedSpend * 1.2;

    if (this.state.annualSpend > abovePaceWarningThreshold) {
      return 'danger';
    } else if (this.state.annualSpend > this.state.annualExpectedSpend) {
      return 'yellow';
    } else {
      return 'success';
    }
  }

  chooseAllAddresses() {
    this.setState({ shipToAddress: 'all' });
    this.props.onAddressChosen('all');
    this.handleClose('modal');
  }

  // search text of addresses
  searchAddresses(e) {
    var query = e.target.value.toLowerCase();
    var results = [];

    if (!query || query === '') results = this.props.locations;
    else {
      for (var i = 0; i < this.props.locations.length; i++) {
        if (
          this.props.locations[i].address.toLowerCase().indexOf(query) > -1 ||
          this.props.locations[i].city.toLowerCase().indexOf(query) > -1 ||
          this.props.locations[i].name.toLowerCase().indexOf(query) > -1 ||
          this.props.locations[i].state.toLowerCase().indexOf(query) > -1 ||
          this.props.locations[i].jobCode.indexOf(query) > -1 ||
          this.props.locations[i].zip.indexOf(query) > -1
        ) {
          results.push(this.props.locations[i]);
        }
      }
    }

    results.sort(this.sortByLabel);
    this.setState({ filtered: results });
  }

  // new address form event handlers
  addressNameChange(e) {
    this.setState({ addressName: e.target.value });
  }
  addressStreetChange(e) {
    this.setState({ addressStreet: e.target.value });
  }
  addressCityChange(e) {
    this.setState({ addressCity: e.target.value });
  }
  addressStateChange(e) {
    this.setState({ addressState: e.target.value });
  }
  addressPostalChange(e) {
    this.setState({ addressPostal: e.target.value });
  }

  // save a new address to the db
  saveAddress() {
    /*
    var address = { addresses: this.state.addresses, customerID: this.props.user.id };

    var newAddress = { 
      address_id: uuidv4(),
      name: this.state.addressName, 
      address: this.state.addressStreet,
      city: this.state.addressCity,
      state: this.state.addressState,
      zip: this.state.addressPostal
    }

    address.addresses.push ( newAddress )

    db.updateAddress(this.props.user.id, address).then(() => {
      this.props.onAddressesLoaded(address.addresses); // return all available addresses
      this.props.onAddressChosen(newAddress.address_id); // return currently chosen address (new one)
      this.setState({addresses: address.addresses, 
          showNewAddressForm: false,
          addressName: "",
          addressStreet: "",
          addressCity: "",
          addressState: "",
          addressPostal: ""
        }); // update addresses on chooser
      this.handleClose("modal");
    });
    */
  }

  // display tweak functions

  getBudgetDescriptor() {
    var budget = this.state.budget;

    if (budget.period === 12) {
      return '$' + budget.amount + ' / month';
    } else if (budget.period === 13) {
      return '$' + budget.amount + ' / 4 weeks';
    } else if (budget.period === 26) {
      return '$' + budget.amount + ' / every two weeks';
    } else if (budget.period === 52) {
      return '$' + budget.amount + ' / week';
    }
  }

  // hide search if only a handful of addresses are available
  showSearch() {
    if (this.props.locations.length > 4) return 'block';
    else return 'none';
  }

  // show address picker (as opposed to filter)
  showShipTo() {
    if (this.props.chooseShipTo) return 'block';
    else return 'none';
  }

  showShipToAddress() {
    if (this.state.shipToAddress.address) return 'block';
    else return 'none';
  }

  showShipToAddressPrompt() {
    if (!this.state.shipToAddress.address && !this.props.user.customerID)
      return 'block';
    else return 'none';
  }

  addNewAddress() {
    this.setState({ showNewAddressForm: true });
  }

  showNewAddressForm() {
    if (this.state.showNewAddressForm) return 'block';
    else return 'none';
  }

  showAddressFilter() {
    if (this.props.addressFilter) return 'block';
    else return 'none';
  }

  showAddAddressButton() {
    if (
      this.props.shipTo &&
      !this.state.showNewAddressForm &&
      !this.props.user.customerID
    )
      return 'block';
    else return 'none';
  }

  showAddresses() {
    if (this.state.showNewAddressForm) return 'none';
    else return 'block';
  }

  showAllAddresses() {
    if (this.state.shipToAddress === 'all') return 'block';
    else return 'none';
  }

  showFilteredAddress() {
    if (this.state.shipToAddress !== 'all') return 'block';
    else return 'none';
  }

  showNoFilter() {
    if (this.state.shipToAddress === 'all') return 'block';
    else return 'none';
  }

  getWidth() {
    if (this.props.showNewAddressForm) return 'sm';
    else return 'md';
  }

  showBudgets() {
    if (this.state.budget.amount > 0) {
      return 'block';
    } else {
      return 'none';
    }
  }

  // finally
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Card
          style={{ cursor: 'pointer', display: this.showShipTo() }}
          onClick={() => this.handleClickOpen('modal')}
        >
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <div style={{ display: this.showShipToAddress() }}>
                  <div style={{ fontSize: '12px', color: '#696969' }}>
                    Ship to Address
                  </div>
                  <h4
                    style={{
                      fontFamily: '"Roboto Slab", "Times New Roman", serif',
                      fontSize: '15px',
                      marginBottom: '0px',
                    }}
                  >
                    {this.state.shipToAddress.name}
                  </h4>
                  {this.state.shipToAddress.address}
                  <br />
                  {this.state.shipToAddress.city},{' '}
                  {this.state.shipToAddress.state}{' '}
                  {this.state.shipToAddress.zip}
                  <br />
                  {this.state.shipToAddress.country}
                </div>
                <div style={{ display: this.showShipToAddressPrompt() }}>
                  Create a Shipping Address
                </div>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={7}
                style={{ display: this.showBudgets() }}
              >
                <Hidden mdUp>
                  <br />
                </Hidden>

                <GridContainer style={{ marginTop: '11px' }}>
                  <GridItem xs={4} style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        color: 'green',
                        fontSize: '12px',
                        marginBottom: '10px',
                      }}
                    >
                      {this.getBudgetDescriptor()}
                    </div>
                  </GridItem>
                  <GridItem xs={4} style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        color: 'green',
                        fontSize: '12px',
                        marginBottom: '10px',
                      }}
                    >
                      ${numberWithCommas(this.state.budgetSpent)}
                    </div>
                  </GridItem>
                  <GridItem xs={4} style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        color: 'green',
                        fontSize: '12px',
                        marginBottom: '10px',
                      }}
                    >
                      ${numberWithCommas(this.state.budgetRemaining)}
                    </div>
                  </GridItem>

                  <GridItem
                    xs={12}
                    style={{ textAlign: 'center', marginBottom: '-18px' }}
                  >
                    <CustomLinearProgress
                      variant="determinate"
                      color={this.getBudgetStatusColor()}
                      value={this.state.budgetPercentageRemaining}
                    />
                  </GridItem>

                  <GridItem xs={4} style={{ textAlign: 'center' }}>
                    <div style={{ color: 'gray', fontSize: '9px' }}>BUDGET</div>
                  </GridItem>
                  <GridItem xs={4} style={{ textAlign: 'center' }}>
                    <div style={{ color: 'gray', fontSize: '9px' }}>SPENT</div>
                  </GridItem>
                  <GridItem xs={4} style={{ textAlign: 'center' }}>
                    <div style={{ color: 'gray', fontSize: '9px' }}>
                      REMAINING
                    </div>
                  </GridItem>

                  <GridItem
                    xs={12}
                    style={{ textAlign: 'center', marginBottom: '-8px' }}
                  >
                    <CustomLinearProgress
                      variant="determinate"
                      color={this.getAnnualBudgetStatusColor()}
                      value={this.state.annualPercentageRemaining}
                    />
                  </GridItem>

                  <GridItem xs={4} style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        color: 'green',
                        fontSize: '12px',
                        marginBottom: '10px',
                      }}
                    >
                      ${numberWithCommas(this.state.annualBudget)} / year
                    </div>
                  </GridItem>
                  <GridItem xs={4} style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        color: 'green',
                        fontSize: '12px',
                        marginBottom: '10px',
                      }}
                    >
                      ${numberWithCommas(this.state.annualSpend)}
                    </div>
                  </GridItem>
                  <GridItem xs={4} style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        color: 'green',
                        fontSize: '12px',
                        marginBottom: '10px',
                      }}
                    >
                      ${numberWithCommas(this.state.annualBudgetRemaining)}
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card style={{ cursor: 'pointer', display: this.showAddressFilter() }}>
          <CardBody>
            <GridContainer direction="row">
              <GridItem xs={8}>
                <div style={{ display: this.showFilteredAddress() }}>
                  <div style={{ fontSize: '12px', color: '#696969' }}>
                    Address Filter
                  </div>
                  <h4
                    style={{
                      fontFamily: '"Roboto Slab", "Times New Roman", serif',
                      fontSize: '15px',
                      marginBottom: '0px',
                    }}
                  >
                    {this.state.shipToAddress.name}
                  </h4>
                  {this.state.shipToAddress.address}
                  <br />
                  {this.state.shipToAddress.city},{' '}
                  {this.state.shipToAddress.state}{' '}
                  {this.state.shipToAddress.zip}
                  <br />
                  {this.state.shipToAddress.country}
                </div>
                <div style={{ display: this.showAllAddresses() }}>
                  <div style={{ fontSize: '12px', color: '#696969' }}>
                    Address Filter
                  </div>
                  <h4
                    style={{
                      fontFamily: '"Roboto Slab", "Times New Roman", serif',
                      fontSize: '15px',
                      marginBottom: '0px',
                    }}
                  >
                    All Addresses
                  </h4>
                  <br />
                </div>
              </GridItem>
              <GridItem xs={4} style={{ textAlign: 'right' }}>
                <Button onClick={() => this.handleClickOpen('modal')}>
                  <Filter />
                  Filter
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Dialog
          classes={{ root: classes.center, paper: classes.modal }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth={this.getWidth()}
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={6}>
                <h4 className={classes.modalTitle}>Shipping Addresses</h4>
              </GridItem>
              <GridItem
                xs={6}
                style={{
                  textAlign: 'right',
                  display: this.showAddressFilter(),
                }}
              >
                <Button
                  color="success"
                  onClick={() => this.chooseAllAddresses()}
                >
                  Show All Addresses
                </Button>
              </GridItem>
              <GridItem
                xs={6}
                style={{
                  textAlign: 'right',
                  display: this.showAddAddressButton(),
                }}
              >
                <Button color="success" onClick={this.addNewAddress}>
                  Add an Address
                </Button>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{ backgroundColor: '#efefef', minHeight: '432px' }}
          >
            <GridContainer style={{ overflow: 'auto', maxHeight: '432px' }}>
              <GridItem xs={12} style={{ display: this.showNewAddressForm() }}>
                <Card>
                  <CardHeader color="success">New Address</CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText="Name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'text',
                            placeholder: 'e.g. home, office',
                          }}
                          onChange={this.addressNameChange}
                          value={this.state.addressName}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText="Address"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'text',
                            placeholder: 'street address, unit #',
                          }}
                          onChange={this.addressStreetChange}
                          value={this.state.addressStreet}
                        />
                      </GridItem>
                      <GridItem xs={5}>
                        <CustomInput
                          labelText="City"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          onChange={this.addressCityChange}
                          value={this.state.addressCity}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <CustomInput
                          labelText="State"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          onChange={this.addressStateChange}
                          value={this.state.addressState}
                        />
                      </GridItem>
                      <GridItem xs={4}>
                        <CustomInput
                          labelText="Postal Code"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'text',
                          }}
                          onChange={this.addressPostalChange}
                          value={this.state.addressPostal}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  <Button
                    onClick={() => this.setState({ showNewAddressForm: false })}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button color="success" onClick={this.saveAddress}>
                    Save
                  </Button>
                </div>
              </GridItem>
              <GridItem xs={12} style={{ display: this.showSearch() }}>
                <CustomInput
                  labelText="Search"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className={classes.icon} />
                      </InputAdornment>
                    ),
                    placeholder: 'Address Search',
                  }}
                  onChange={this.searchAddresses}
                />
              </GridItem>
              {this.state.filtered.map((address, index) => (
                <GridItem
                  xs={12}
                  sm={6}
                  key={index}
                  style={{ display: this.showAddresses() }}
                >
                  <Card
                    style={{
                      cursor: 'pointer',
                      marginTop: '10px',
                      marginBottom: '10px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                    }}
                    onClick={() => this.chooseAddress(address)}
                  >
                    <div
                      style={{
                        minHeight: '100px',
                        padding: '6px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        fontSize: '13px',
                      }}
                    >
                      <h4
                        style={{
                          fontFamily: '"Roboto Slab", "Times New Roman", serif',
                          fontSize: '15px',
                          marginBottom: '0px',
                        }}
                      >
                        {address.name}
                      </h4>
                      {address.address}
                      <br />
                      {address.city}, {address.state} {address.zip}
                      <br />
                      {address.jobCode}
                    </div>
                  </Card>
                </GridItem>
              ))}
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    budgetExceptions: state.budgetExceptions,
    cart: state.cart,
    locations: state.locations,
    shipTo: state.shipTo,
    shipToAddress: state.shipToAddress,
    user: state.user,
  };
};

AddressChooser = connect(mapStateToProps)(AddressChooser);
export default withStyles(modalStyle)(AddressChooser);
