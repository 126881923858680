//react
import React from 'react';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CartProductAdded } from 'redux/actions';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle';

//core
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import withMobileDialog from '@material-ui/core/withMobileDialog';

//components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

//
import Parser from 'html-react-parser';

// @material-ui/icons
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      product: props.product,
      highlight: false,
    };

    this.state.product.qty = 1;

    this.addToCart = this.addToCart.bind(this);
    this.revertColor = this.revertColor.bind(this);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  addToCart() {
    setTimeout(this.revertColor, 2300);
    this.setState({ highlight: true });

    //console.log(this.state.product)

    var product = {
      id: this.state.product.id,
      description: this.state.product.description,
      listPrice: this.state.product.listPrice,
      manufacturer: this.state.product.manufacturer,
      name: this.state.product.name,
      photo: this.state.product.photo,
      productID: this.state.product.productID,
      qty: this.state.product.qty,
      thumbnail: this.state.product.thumbnail,
      cost: this.state.product.cost,
    };

    const debugInfo = {
      user: this.props.user,
      profileExceptions: this.props.pricing,
      action: 'addToCart',
      product: product,
    };

    //    customerExceptions: _customerExceptions,
    //    totalExceptions: _totalExceptions

    fetch('https://us-central1-kekaha-ebf1f.cloudfunctions.net/logDebugInfo', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(debugInfo),
    })
      .then((response) => {
        if (response.ok) {
        } else {
          console.log('error (1): ');
          console.log(response);
        }
      })
      .catch((error) => {
        // If there is any error you will catch them here
        console.log('error (2):  ' + error);
      });

    this.props.onCartProductAdded(product);
    this.updateQty(1);

    this.handleClose('modal');
  }

  revertColor() {
    this.setState({ highlight: false });
    clearTimeout(this.revertColor);
  }

  updateQty(qty) {
    var product = this.state.product;
    product.qty = qty;
    this.setState({ product: product });
  }

  showQty() {
    if (this.state.product.qty > 0) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showColorScentOther() {
    if (this.state.product.colorScentOther) {
      return 'table-row';
    } else {
      return 'none';
    }
  }

  showSize() {
    if (this.state.product.size) {
      return 'table-row';
    } else {
      return 'none';
    }
  }

  showCasePack() {
    if (this.state.product.casePack) {
      return 'table-row';
    } else {
      return 'none';
    }
  }

  showAttachments() {
    if (this.props.product.attachments) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showHtmlContentTab() {
    if (this.state.product.Content) return false;
    else return true;
  }
  htmlContent() {
    if (this.state.product.Content) return Parser(this.state.product.Content);
    else return '';
  }

  trim(value, maxLength) {
    if (value) {
      if (value.trim().length > maxLength)
        return value.trim().substring(0, maxLength) + '...';
      else return value.trim();
    } else return '';
  }

  cartOpacity() {
    if (this.state.highlight) {
      return 1.0;
    } else {
      return 0.6;
    }
  }

  openAttachment(attachment) {
    window.open(
      attachment.URL,
      attachment.Name,
      'resizable=1,location=0,toolbar=0,height=600,width=400,left=200,top=100',
    );
  }

  getMDColumnCount() {
    if(this.props.narrow)
      return 3;
    else
      return 4;
  }

  render() {
    const { classes } = this.props;

    return (
      <GridItem xs={12} sm={6} md={this.getMDColumnCount()}>
        <Hidden mdUp>
          <Card style={{ marginTop: '6px', marginBottom: '6px' }}>
            <CardBody
              style={{ cursor: 'pointer', whiteSpace: 'pre', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
              onClick={() => this.handleClickOpen('modal')}
            >
              <div
                style={{
                  width: '70px',
                  marginTop: '10px',
                  marginBottom: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={this.props.product.thumbnail}
                  alt=".."
                  style={{ height: 69,  maxWidth: 69 }}
                />
              </div>
              <div
                style={{
                  display: 'inline-block',
                  marginLeft: '10px',
                  marginRight: '10px',
                  // width: 'calc(100% - 70px)',
                  width: '92%',
                }}
              >
                <h4
                  style={{
                    fontFamily: '"Roboto Slab", "Times New Roman", serif',
                    fontSize: '15px',
                    whiteSpace: 'normal'
                  }}
                >
                  {/* {this.trim(this.props.product.name, window.innerWidth < 400? 25 : 30)} */}
                  {this.props.product.name}
                </h4>
                <div style={{ color: '#3fa31b', marginTop: '-10px' }}>
                  {this.props.product.manufacturer}
                  <span
                    style={{
                      color: '#232323',
                      fontSize: '11px',
                      marginLeft: '10px',
                    }}
                  >
                    {this.props.product.productID}
                  </span>
                </div>
                <div
                  className={classes.description}
                  style={{ fontSize: '11px', whiteSpace: 'normal' }}
                >
                  {this.props.product.description}
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <div className={classes.priceContainer}>
                <span className={classes.price} style={{ fontWeight: 400, paddingLeft: 13 }}>
                  {' '}
                  ${this.props.product.listPrice + (this.props.product.listPrice.toString().indexOf(".") > -1? '' : '.00')}
                </span>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  textAlign: 'right',
                  width: '100%',
                  height: '34px',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    height: '34px',
                    position: 'relative',
                    top: '-6px',
                  }}
                >
                  <input
                    type="text"
                    value={this.state.product.qty}
                    onChange={(e) => this.updateQty(e.target.value)}
                    placeholder="qty"
                    style={{
                      height: '34px',
                      borderStyle: 'none',
                      backgroundColor: '#efefef',
                      padding: '6px',
                      paddingRight: '10px',
                      borderTopLeftRadius: '6px',
                      borderBottomLeftRadius: '6px',
                      textAlign: 'right',
                      width: '50px',
                    }}
                  />
                </div>
                <div
                  onClick={() => this.addToCart()}
                  style={{
                    height: '34px',
                    backgroundColor: 'green',
                    opacity: this.cartOpacity(),
                    transition: 'opacity 0.5s',
                    padding: '4px',
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px',
                    display: 'inline-block',
                    cursor: 'pointer',
                    width: '43px',
                    textAlign: 'center',
                  }}
                >
                  <AddShoppingCart
                    style={{ color: 'white', height: '17px', marginTop: '6px' }}
                  />
                </div>
              </div>
            </CardFooter>
          </Card>
        </Hidden>
        <Hidden smDown>
          <Card style={{ marginTop: '6px', marginBottom: '6px' }}>
            <CardBody
              style={{ cursor: 'pointer' }}
              onClick={() => this.handleClickOpen('modal')}
            >
              <div style={{ textAlign: 'center' }}>
                <img
                  src={this.props.product.thumbnail}
                  alt=".."
                  style={{
                    minHeight: '127px',
                    maxHeight: '127px',
                    maxWidth: '90%',
                  }}
                />
              </div>
              <hr />
              <div style={{ minHeight: '123px' }}>
                <h4
                  style={{
                    fontFamily: '"Roboto Slab", "Times New Roman", serif',
                    fontSize: '15px',
                  }}
                >
                  {this.trim(this.props.product.name, 40)}
                </h4>
                <p style={{ color: '#3fa31b' }}>
                  {this.props.product.manufacturer}
                  <span
                    style={{
                      color: '#232323',
                      fontSize: '11px',
                      marginLeft: '10px',
                    }}
                  >
                    {this.props.product.productID}
                  </span>
                </p>
                <div
                  className={classes.description}
                  style={{ minHeight: '67px' }}
                >
                  {this.props.product.description}
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <div className={classes.priceContainer}>
                <span className={classes.price} style={{ fontWeight: 400 }}>
                  {' '}
                  ${this.props.product.listPrice}
                </span>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  textAlign: 'right',
                  width: '100%',
                  height: '34px',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    height: '34px',
                    position: 'relative',
                    top: '-6px',
                  }}
                >
                  <input
                    type="text"
                    value={this.state.product.qty}
                    onChange={(e) => this.updateQty(e.target.value)}
                    placeholder="qty"
                    style={{
                      height: '34px',
                      borderStyle: 'none',
                      backgroundColor: '#efefef',
                      padding: '6px',
                      paddingRight: '10px',
                      borderTopLeftRadius: '6px',
                      borderBottomLeftRadius: '6px',
                      textAlign: 'right',
                      width: '50px',
                    }}
                  />
                </div>
                <div
                  onClick={() => this.addToCart()}
                  style={{
                    height: '34px',
                    backgroundColor: 'green',
                    opacity: this.cartOpacity(),
                    transition: 'opacity 0.5s',
                    padding: '4px',
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px',
                    display: 'inline-block',
                    cursor: 'pointer',
                    width: '43px',
                    textAlign: 'center',
                  }}
                >
                  <AddShoppingCart
                    style={{ color: 'white', height: '17px', marginTop: '6px' }}
                  />
                </div>
              </div>
            </CardFooter>
          </Card>
        </Hidden>
        <Dialog
          fullScreen={this.props.fullScreen}
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          style={{
            height: '100%',
            overflowY: 'scroll',
          }}
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={12} sm={12}>
                <h4 className={classes.modalTitle}>Details</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{
              backgroundColor: '#efefef',
            }}
          >
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: 'General',
                  tabContent: (
                    <GridContainer
                      direction="row"
                      style={{ minHeight: '369px' }}
                    >
                      <GridItem xs={12} sm={6}>
                        <img
                          src={this.props.product.photo}
                          style={{ width: '100%' }}
                          alt="product"
                        />

                        <Table
                          style={{
                            marginTop: '23px',
                            display: this.showAttachments(),
                          }}
                        >
                          <TableBody>
                            {this.props.product.attachments.map(
                              (attachment, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    style={{
                                      padding: '3px',
                                      color: '#3fa31b',
                                      width: '100%',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      this.openAttachment(attachment)
                                    }
                                  >
                                    {attachment.Name}
                                  </TableCell>
                                </TableRow>
                              ),
                            )}
                          </TableBody>
                        </Table>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h5>{this.props.product.name}</h5>
                        <p>{this.props.product.description}</p>

                        <Table style={{ marginTop: '23px' }}>
                          <TableBody>
                            <TableRow>
                              <TableCell style={{ padding: '3px' }}>
                                Price
                              </TableCell>
                              <TableCell
                                style={{ padding: '3px', color: '#3fa31b' }}
                              >
                                ${this.props.product.listPrice}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              style={{ display: this.showColorScentOther() }}
                            >
                              <TableCell style={{ padding: '3px' }}>
                                Color/Scent
                              </TableCell>
                              <TableCell style={{ padding: '3px' }}>
                                {this.props.product.colorScentOther}
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ display: this.showSize() }}>
                              <TableCell style={{ padding: '3px' }}>
                                Size
                              </TableCell>
                              <TableCell style={{ padding: '3px' }}>
                                {this.props.product.size}
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ display: this.showCasePack() }}>
                              <TableCell style={{ padding: '3px' }}>
                                Case / Pack
                              </TableCell>
                              <TableCell style={{ padding: '3px' }}>
                                {this.props.product.casePack}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ padding: '3px' }}>
                                Product ID
                              </TableCell>
                              <TableCell style={{ padding: '3px' }}>
                                {this.props.product.productID}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <p />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'More',
                  tabHidden: this.showHtmlContentTab(),
                  tabContent: (
                    <GridContainer
                      style={{
                        minHeight: '369px',
                        maxHeight: '469px',
                        overflowY: 'scroll',
                      }}
                    >
                      {this.htmlContent()}
                    </GridContainer>
                  ),
                },
              ]}
            />
          </DialogContent>
          <DialogActions
            className={classes.modalFooter}
            style={{ backgroundColor: '#efefef', marginTop: '-17px' }}
          >
            <Button
              onClick={() => this.handleClose('modal')}
              style={{ float: 'right' }}
            >
              Cancel
            </Button>
            &nbsp;
            <div
              style={{
                display: 'inline-block',
                textAlign: 'right',
                width: '123px',
                height: '43px',
                marginRight: '4px',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  height: '43px',
                  position: 'relative',
                  top: '-4px',
                }}
              >
                <input
                  type="text"
                  value={this.state.product.qty}
                  onChange={(e) => this.updateQty(e.target.value)}
                  placeholder="qty"
                  style={{
                    height: '43px',
                    borderStyle: 'none',
                    backgroundColor: '#ffffff',
                    color: 'green',
                    padding: '6px',
                    paddingRight: '12px',
                    borderTopLeftRadius: '6px',
                    borderBottomLeftRadius: '6px',
                    textAlign: 'right',
                    width: '50px',
                  }}
                />
              </div>
              <div
                onClick={() => this.addToCart()}
                style={{
                  height: '43px',
                  backgroundColor: 'green',
                  opacity: this.cartOpacity(),
                  transition: 'opacity 0.5s',
                  padding: '4px',
                  borderTopRightRadius: '6px',
                  borderBottomRightRadius: '6px',
                  display: 'inline-block',
                  cursor: 'pointer',
                  width: '69px',
                  textAlign: 'center',
                }}
              >
                <AddShoppingCart
                  style={{ color: 'white', height: '17px', marginTop: '9px' }}
                />
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </GridItem>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
  //return {
  //  vocabulary: state.vocabulary
  //}
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onCartProductAdded: CartProductAdded }, dispatch);
}

ProductDetails = connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
ProductDetails = withMobileDialog()(ProductDetails);
export default withStyles(modalStyle)(ProductDetails);
