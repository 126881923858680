import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LocationSelected } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.jsx";

//components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Hidden from '@material-ui/core/Hidden';

//kekaha
import AddressChooser from "components/AddressChooser/AddressChooser.jsx";
import AddressEditor from "components/AddressEditor/AddressEditor.jsx";


class LocationList extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
          shipTo: 'all',
          filtered: []
      };

      this.changeShipTo = this.changeShipTo.bind(this);
      this.addressesLoaded = this.addressesLoaded.bind(this);
      this.refreshAddresses = this.refreshAddresses.bind(this);
      this.budgetUpdated = this.budgetUpdated.bind(this);

      db.logActivity(this.props.user.id, this.props.user.customerID, "View-Locations");

      //console.log(props)
    }

    compareLabel(a,b) {

        var aName = a.name?.toString().toLowerCase();
        var bName = b.name?.toString().toLowerCase();
        
        if (aName < bName)
            return -1;
        if (aName > bName)
            return 1;
        return 0;
    }

    addressesLoaded(addresses) {
        this.setState({addresses:addresses},() => {
            this.changeShipTo('all');
        });
    }

    refreshAddresses() {
        
        var addresses = this.props.locations;
        var filtered = [];
        
        db.getBudgetsByCustomer(this.props.user.customerID).then((budgets) => {

            for(var i = 0; i < addresses.length; i++) {
                if(this.props.user.addresses) {
                    for(var j = 0; j < this.props.user.addresses.length; j++) {
                        if(addresses[i].address_id === this.props.user.addresses[j]) {
            
                            if(addresses[i].address_id === this.state.shipTo || this.state.shipTo === 'all') {

                                for(var k = 0; k < budgets.length; k++) {
                                    if(budgets[k].addressID === addresses[i].address_id) {
                                        
                                        addresses[i].budget = budgets[k];
                                        
                                    }
                                }

                                if(!addresses[i].budget) {
                                    addresses[i].budget = {
                                        amount: Number(0).toFixed(2),
                                        period: 12
                                    }
                                }
                                    
                                filtered.push(addresses[i]);
                            } 
                        }
                    }
                } 
                else 
                {

                    if(addresses[i].address_id === this.state.shipTo || this.state.shipTo === 'all') {

                        for(var l = 0; l < budgets.length; l++) {
                            if(budgets[l].addressID === addresses[i].address_id) {
                                addresses[i].budget = budgets[l];
                                break;
                            }
                        }

                        if(!addresses[i].budget) {
                            addresses[i].budget = {
                                amount: Number(0).toFixed(2),
                                period: 12
                            }
                        }

                        filtered.push(addresses[i]);
                    }
                }
            }

            filtered.sort(this.compareLabel);
            this.setState({filtered:filtered});
            
        });
    }

    updateLocation(location, property, value) {

        var addresses = this.state.addresses;

        for(var i = 0; i < addresses.length; i++) {
            if(addresses[i].id === location.id) {
                addresses[i][property] = value;
                break;
            }
        }

        this.setState({addresses: addresses});
    }

    budgetUpdated(budget) {
        
        var addresses = this.state.filtered;

        for(var i = 0; i < addresses.length; i++) {
            if(addresses[i].address_id === budget.addressID) {
                addresses[i].budget = budget;
                break;
            }
        }
        this.setState({filtered: addresses});
    }

    getBudgetDescriptor(budget) {

        if(budget) {
            if(budget.period === 12) {
                return "$" + budget.amount + " / month";
            } else if(budget.period === 13) {
                return "$" + budget.amount + " / 4 weeks";
            } else if(budget.period === 26) {
                return "$" + budget.amount + " / every two weeks";
            } else if(budget.period === 52) {
                return "$" + budget.amount + " / week";
            }
        } else return "n/a";
    }

    changeShipTo(value) {
    
        this.props.onLocationSelected(value);

        // for the select
        this.setState({shipTo: value}, () => {
            this.refreshAddresses();
        });
    }

    render() {
        const { classes } = this.props;
    
        if (!this.props.user) {
            return <Redirect to='/login' />
        } else if (!this.props.user.authenticated) {
            return <Redirect to='/login' />
        }

        return (
          <div>
            <Header
              brand="Ecclesia"
              links={<HeaderLinks dropdownHoverColor="info" />}
              fixed
              color="dark"
            />
            <div className={classes.container} style={{minHeight:"369px", marginTop:"100px"}}>

                <GridContainer>
                    <GridItem xs={12}>
                        <h3 className={classes.cardTitle}>Locations</h3>
                    </GridItem>
                    <GridItem xs={12}>
                        <AddressChooser onAddressesLoaded={this.addressesLoaded} shipToAddress={this.state.shipTo} onAddressChosen={this.changeShipTo} addressFilter />
                    </GridItem>
                    <Hidden smDown>
                        <GridItem xs={12}>
                            <Card>
                                <CardBody>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{padding:"3px"}}>

                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    Name
                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    Street
                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    City
                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    State
                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    Postal
                                                </TableCell>
                                                <TableCell style={{padding:"3px", textAlign:"right"}}>
                                                    Budget
                                                </TableCell>
                                                <TableCell style={{padding:"3px", textAlign:"right"}}>
                                                    Starting
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.filtered.map((location,secondIndex) => ( 
                                                <TableRow key={secondIndex}>
                                                    <TableCell style={{padding:"3px"}}>
                                                        <AddressEditor address={location} budget={location.budget} onBudgetUpdated={this.budgetUpdated} />
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", color:"green"}}>
                                                        {location.name}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        {location.address}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        {location.city}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        {location.state}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        {location.zip}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:"right"}}>
                                                        {this.getBudgetDescriptor(location.budget)}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:"right"}}>
                                                        {location.budget.startingBalance}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </Hidden>
                    <Hidden mdUp>
                        {this.state.filtered.map((location,thirdIndex) => ( 

                            <Card style={{margin:"6px"}} key={thirdIndex}>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={2}>
                                            <AddressEditor address={location} budget={location.budget} onBudgetUpdated={this.budgetUpdated} />
                                        </GridItem>
                                        <GridItem xs={10}>
                                            <div style={{color:"green"}}>{location.name}</div>
                                            {location.address}<br />
                                            {location.city}, {location.state} {location.zip}<br />
                                            <span style={{color:"#545454"}}>Budget: {this.getBudgetDescriptor(location.budget)}</span>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>

                        ))}
                    </Hidden>
                </GridContainer>
                        
            </div>
            <Footer
                content={
                    <div>
                    <div className={classes.right} style={{fontSize:"13px"}}>
                        &copy; {1900 + new Date().getYear()} Ecclesia, Inc.
                    </div>
                    </div>
                }
            />
          </div>
        );
    }
}

const mapStateToProps = state => {
  return state;
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({onLocationSelected: LocationSelected }, dispatch);  
  }

LocationList = connect(mapStateToProps, mapDispatchToProps)(LocationList);
export default withStyles(shoppingCartStyle)(LocationList);
