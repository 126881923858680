import React, { Component } from 'react'
import { connect } from 'react-redux'

//styles
import { withStyles, createStyles } from '@material-ui/core'
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ProductDetails from "components/ProductDetails/ProductDetails.jsx";

//firebase
import { db } from 'assets/firebase';

const style = createStyles({
  special: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: 250,
    height: 400,
    margin: 10,
    padding: '20px 10px',
    border: '1px solid #eee',
    borderRadius: 12,
  },
  specialImage: {
    width: 250,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& > img': {
      maxWidth: '100%',
      height: 160,
    }
  },
  specialName: {
    textAlign: 'center'
  },
  specialLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
})


class SectionFrequent extends Component {
  constructor(props) {
    super()

    this.state = {
      products: [],
      customContent: '',
      selectedLocation: props.selectedLocation
    }

    this.refreshProducts = this.refreshProducts.bind(this);
  }

  componentDidMount() {
      this.refreshProducts();
  }

  componentDidUpdate(prevProps) {

    if(this.props.selectedLocation !== prevProps.selectedLocation) {
        this.setState({selectedLocation: this.props.selectedLocation}, () => {
            this.refreshProducts();   
        })
    }
  }

  refreshProducts() {
      
    db.getFrequentPurchases(this.state.selectedLocation, this.props.user.customerID)
    .then((results)=>{
        
        var products = [];
        results.forEach(result => {
            var p = {
                productID:result.ProductID,
                name:result.ProductName,
                description:result.BriefDescription,
                manufacturerID:result.ManufacturerID,
                categoryID:result.CategoryID,
                subCat1ID:result.SubCat1ID,
                subCat2ID:result.SubCat2ID,
                subCat3ID:result.SubCat3ID,
                isVisible: result.IsVisible,
                id: result.id,
                brandID: result.BrandID,
                casePark: result.CasePack,
                size: result.Size,
                cost: result.WholesalePrice,
                listPrice: result.ListPrice
            }

            if(result.Photos) {
                if(result.Photos.length > 0) {
                    for(var i = 0; i < result.Photos.length; i++) {
                        if(result.Photos[i].isCover) {
                            p.photo = result.Photos[i].URL;
                        }
                        if (result.Photos[i].isThumb) {
                            p.thumbnail = result.Photos[i].URL;
                        }
                    }
                }
            }

            if(result.Attachments) {
                p.attachments = result.Attachments;
            } else {
                p.attachments = [];
            }

            if(result.Content) {
                p.Content = result.Content;
            }

            if(p.listPrice) // wtf?
                products.push(p);
        });


    this.setState({products:products});
    })
    .catch(console.error)
  }

  showNoData() {
    if(this.state.products.length === 0) {
      return "block";
    } else {
      return "none";
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        
        <GridContainer>
          <GridItem xs={12}>
            <h4 className={classes.title}>FREQUENTLY PURCHASED PRODUCTS</h4>
          </GridItem>
        
          <GridItem xs={12} style={{display:this.showNoData()}} >
            No Frequent Purchases Found
          </GridItem>

          {
            this.state.products.map((product)=>(
              <ProductDetails product={product} key={product.id} />
            ))
          }
        </GridContainer>
      </div>
    )
  }
}

const mapStateToProps = state => state
export default connect(mapStateToProps)(withStyles(Object.assign({}, featuresStyle, style))(SectionFrequent))