
export const calculateCommission = function(product, price, user) {
  
  //console.log('calculateCommission()');
  //console.log('affiliate:')
  //console.log(user);

  if(!price)
    price = product.customPrice;

    //console.log('custom price: ' + product.customPrice);

  // calculating affiliate commission
  var baseCommissionPercentage = Number(user.baseCommission / 100);
  
  //console.log('commissionType: ' + user.commissionType);
  if(user.commissionType === 'flat') {

    product.commission = (price * baseCommissionPercentage).toFixed(2);
    //console.log('flat commission: ' + product.commission + " based on $" + price + " and a " + baseCommissionPercentage + " commission.");

  } else if(user.commissionType === 'margin') {

    var floorCommissionPercentage = Number(user.floorCommission / 100);
    //var floorPercentage = (Number(1) + (Number(user.floorPercentage) / 100)).toFixed(2);

    //console.log('cost: ' + product.cost);
    var priceFloor = (Number(product.cost) * user.floorPercentage).toFixed(2); 
    var adjustedMargin = (Number(price) - priceFloor ).toFixed(2); 
    
    //console.log('price floor: ' + priceFloor);
    //console.log('adjusted margin: ' + adjustedMargin);

    var fc = Number(priceFloor * floorCommissionPercentage);
    var mc = Number(adjustedMargin * baseCommissionPercentage);

    product.commission = Number(fc + mc).toFixed(2);
    //console.log('margin commission: ' + product.commission + " based on a $" + price + " custom price, a $" + fc + " (" + floorCommissionPercentage + ") floor commission and a " + mc + "(" + baseCommissionPercentage + ") base commission.");
  }

  return product.commission;
}
