import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';


var config = {
  apiKey: "AIzaSyBrwI1dqHfFlfwwIX0S6C2PWI26rwd7420",
  authDomain: "kekaha-ebf1f.firebaseapp.com",
  databaseURL: "https://kekaha-ebf1f.firebaseio.com",
  projectId: "kekaha-ebf1f",
  storageBucket: "kekaha-ebf1f.appspot.com",
  messagingSenderId: "1077353329329"
};
  
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }

  const db = firebase.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();

export {
  db,
  auth,
  storage
};