//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.jsx';

//core
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import CustomSelect from 'components/Select/Select.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog.jsx';

//icons
import Filter from '@material-ui/icons/FilterList';
import Check from '@material-ui/icons/Check';
import Add from '@material-ui/icons/Add';
import Save from '@material-ui/icons/Save';

const uuidv4 = require('uuid/v4');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function compareLabel(a, b) {
  var aName = a.label;
  var bName = b.label;

  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
}

class AddressPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      regions: [],
      region: '',
      regionName: '',
      showNewRegion: false,
      selectedText: '',
    };

    var selected = [];
    
    this.state.options = [];
    this.state.selected = selected;

    this.filterResults = this.filterResults.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.addRegion = this.addRegion.bind(this);
    this.saveRegion = this.saveRegion.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.setSelectedText = this.setSelectedText.bind(this);
  }

  componentDidMount() {
    this.getRegions();
    this.loadSelected();
  }

  componentDidUpdate(prevProps) {
    
    if(prevProps.locations !== this.props.locations) {
      
      var options = [];
      for (var i = 0; i < this.props.locations.length; i++) {
        options.push({
          key: this.props.locations[i].address_id,
          label: this.props.locations[i].name,
        });
      }

      this.setState({options:options}, this.loadSelected);

    } else if (prevProps.selected !== this.props.selected) {
      this.loadSelected();
    }
  }

  // dialog open/close
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    this.setSelectedText();

    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadSelected() {
    
    var selected = [];

    if (this.props.selected === 'all') {
      for (var i = 0; i < this.props.locations.length; i++)
        selected.push(this.props.locations[i].address_id);
    } else {
      for (var j = 0; j < this.props.selected.length; j++)
        selected.push(this.props.selected[j]);
    }

    this.setState({ selected: selected }, this.setSelectedText);
  }

  // search text of addresses
  searchAddresses(e) {
    var query = e.target.value.toLowerCase();
    var results = [];

    if (!query || query === '') results = this.props.locations;
    else {
      for (var i = 0; i < this.props.locations.length; i++) {
        if (
          this.props.locations[i].address.toLowerCase().indexOf(query) > -1 ||
          this.props.locations[i].city.toLowerCase().indexOf(query) > -1 ||
          this.props.locations[i].name.toLowerCase().indexOf(query) > -1 ||
          this.props.locations[i].state.toLowerCase().indexOf(query) > -1 ||
          this.props.locations[i].jobCode.indexOf(query) > -1 ||
          this.props.locations[i].zip.indexOf(query) > -1
        ) {
          results.push(this.props.locations[i]);
        }
      }
    }

    results.sort(this.sortByLabel);
    this.setState({ filtered: results });
  }

  toggleAddress(value) {
    const checked = this.state.selected;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ selected: newChecked, region: '' }, () => {});
  }

  toggleAll() {
    var selected = [];
    if (this.state.selected.length === 0) {
      for (var i = 0; i < this.state.options.length; i++)
        selected.push(this.state.options[i].key);
    }
    this.setState({ selected: selected, region: '' }, () => {});
  }

  setSelectedText() {
    
    var selectedText = '';

    this.state.selected.forEach((key) => {
      for (var i = 0; i < this.props.locations.length; i++) {
        if (this.props.locations[i].address_id === key) {
          selectedText = selectedText + this.props.locations[i].name + ', ';
          break;
        }
      }
    });

    if (selectedText.length > 0)
      // remove trailing comma
      selectedText = selectedText.substr(0, selectedText.length - 2);

    this.setState({ selectedText: selectedText });
  }

  filterResults() {
    this.props.onChange(this.state.selected);
    this.handleClose('modal');
  }

  getRegions() {
    db.getRegionsByCustomer(this.props.user.customerID).then((regions) => {
      for (var i = 0; i < regions.length; i++) {
        regions[i].key = regions[i].id;
        regions[i].label = regions[i].name;
      }
      regions.sort(compareLabel);
      this.setState({ regions: regions });
    });
  }

  addRegion() {
    this.setState({ showNewRegion: true });
  }

  saveRegion() {
    var region = {
      id: uuidv4(),
      name: this.state.regionName,
      customerID: this.props.user.customerID,
      locations: this.state.selected,
    };

    db.updateRegion(region.id, region).then(() => {
      var regions = this.state.regions;
      region.key = region.id;
      region.label = region.name;
      regions.push(region);
      regions.sort(compareLabel);

      this.setState({ showNewRegion: false, regions: regions });
    });
  }

  showNewRegion() {
    if (this.state.showNewRegion) return 'block';
    else return 'none';
  }

  showAddRegion() {
    if (this.state.showNewRegion || this.state.region.length > 0) return 'none';
    else return 'block';
  }

  showDeleteRegion() {
    if (this.state.region.length > 0) return 'block';
    else return 'none';
  }

  selectRegion(region) {
    var selected = [];
    for (var i = 0; i < this.state.regions.length; i++) {
      if (this.state.regions[i].id === region) {
        selected = this.state.regions[i].locations;
        break;
      }
    }
    this.setState({ selected: selected, region: region });
  }

  deleteRegion() {
    db.deleteRegion(this.state.region).then(() => {
      var regions = this.state.regions;
      for (var i = 0; i < regions.length; i++) {
        if (regions[i].id === this.state.region) {
          regions.splice(i, 1);

          this.setState({ region: '', regions: regions });
          break;
        }
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={11}>
            <CustomInput
              disabled
              labelText="Addresses"
              inputProps={{ placeholder: 'Addresses' }}
              formControlProps={{ fullWidth: true }}
              value={this.state.selectedText}
              placeholder="all"
            />
          </GridItem>
          <GridItem xs={1}>
            <IconButton
              style={{ marginTop: '14px' }}
              onClick={() => this.handleClickOpen('modal')}
            >
              <Filter />
            </IconButton>
          </GridItem>
        </GridContainer>
        <Dialog
          classes={{ root: classes.center, paper: classes.modal }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth={'md'}
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={12}>
                <h4 className={classes.modalTitle}>Shipping Addresses</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            style={{ backgroundColor: '#efefef', paddingBottom: '0px' }}
          >
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardBody style={{ overflow: 'scroll', maxHeight: '400px' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            <IconButton onClick={this.toggleAll}>
                              <Check style={{ color: 'green' }} />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', fontSize: '13px' }}
                          >
                            Name
                          </TableCell>
                          <TableCell style={{ padding: '3px' }}>
                            Address
                          </TableCell>
                          <TableCell style={{ padding: '3px' }}>City</TableCell>
                          <TableCell style={{ padding: '3px' }}>
                            State
                          </TableCell>
                          <TableCell style={{ padding: '3px' }}>
                            Postal
                          </TableCell>
                          <TableCell style={{ padding: '3px' }}>
                            Job Code
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.locations.map((address, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ padding: '3px' }}>
                              <Checkbox
                                tabIndex={-1}
                                onClick={() =>
                                  this.toggleAddress(address.address_id)
                                }
                                checked={
                                  this.state.selected.indexOf(
                                    address.address_id,
                                  ) !== -1
                                    ? true
                                    : false
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {address.name}
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {address.address}
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {address.city}
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {address.state}
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {address.zip}
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {address.jobCode}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            style={{
              backgroundColor: '#efefef',
              paddingLeft: '17px',
              paddingTop: '-20px',
              paddingRight: '17px',
              paddingBottom: '17px',
              margin: '0px',
            }}
          >
            <GridContainer style={{ width: '100%' }}>
              <GridItem xs={4} style={{ marginTop: '-20px' }}>
                <CustomSelect
                  labelText="Region"
                  value={this.state.region}
                  inputProps={{
                    placeholder: 'Region',
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onChange={(e) => this.selectRegion(e.target.value)}
                  options={this.state.regions}
                />
              </GridItem>
              <GridItem xs={3} style={{ marginTop: '-20px' }}>
                <div
                  style={{
                    display: this.showDeleteRegion(),
                    marginTop: '17px',
                  }}
                >
                  <Tooltip
                    id="tooltip-top-start"
                    title="Delete Region"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <ConfirmDialog
                      header="Confirm Delete"
                      description="Are you sure you want to delete this region? This action cannot be undone."
                      confirmed={() => this.deleteRegion()}
                    />
                  </Tooltip>
                </div>
                <Tooltip
                  id="tooltip-top-start"
                  title="Add a New Region"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    style={{ marginTop: '17px', display: this.showAddRegion() }}
                    onClick={this.addRegion}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
                <div style={{ display: this.showNewRegion() }}>
                  <CustomInput
                    labelText="Region Name"
                    value={this.state.regionName}
                    inputProps={{
                      placeholder: 'Give it a name ...',
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    onChange={(e) =>
                      this.setState({ regionName: e.target.value })
                    }
                  />
                </div>
              </GridItem>
              <GridItem xs={1}>
                <IconButton
                  style={{ marginTop: '0px', display: this.showNewRegion() }}
                  onClick={this.saveRegion}
                >
                  <Save />
                </IconButton>
              </GridItem>
              <GridItem xs={4} style={{ textAlign: 'right' }}>
                <Button color="success" onClick={this.filterResults}>
                  <Filter />
                  OK
                </Button>
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
};

AddressPicker = connect(mapStateToProps)(AddressPicker);
export default withStyles(modalStyle)(AddressPicker);
