//react
import React from 'react';

//router
import { Redirect } from 'react-router-dom';

//firebase
import { db } from 'assets/firebase';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CartCleared } from 'redux/actions';

//affiliate
import { affiliate } from 'assets/affiliate';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.jsx';

//core
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Card from 'components/Card/Card.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

//kekaha
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog.jsx';
import PricePopover from 'components/PricePopover/PricePopover.jsx';

//icons
import Edit from '@material-ui/icons/Edit';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
  return time;
}
function dateConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();

  var time = month + ' ' + date + ', ' + year;
  return time;
}
function numberWithCommas(x) {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else return '';
}

class Estimate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      editMode: false,
      tax: 0.0,
      estimate: {
        Name: '',
        CustomerName: '',
        tax: 0.0,
        estimateTotal: 0.0,
        Items: [],
      },
      shipToAddress: {},
      complete: false,
    };

    this.calculateTotal = this.calculateTotal.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.productChanged = this.productChanged.bind(this);
    this.saveEstimate = this.saveEstimate.bind(this);
    this.sendNotifications = this.sendNotifications.bind(this);
  }

  handleClickOpen(modal) {
    if (this.props.estimate) {
      // console.log('existing estimate')

      var editMode = true;
      if (this.props.estimate.status === 'COMPLETE') editMode = false;

      var estimate = this.props.estimate;
      for (var i = 0; i < estimate.Items.length; i++) {
        var product = estimate.Items[i];
        var priceFloor = (
          Number(product.cost) * Number(this.props.affiliate.floorPercentage)
        ).toFixed(2);
        var margin = product.listPrice - priceFloor;
        var adjusted = product.customPrice - priceFloor;
        estimate.Items[i].chosenMargin = Math.round(
          1 - (adjusted / margin) * -100,
        );
      }

      this.setState(
        {
          estimate: this.props.estimate,
          editMode: editMode,
          shipToAddress: this.props.shippingAddress,
        },
        () => this.calculateTotal(),
      );
    } else {
      // new estimate
      var e2 = this.state.estimate;
      e2.Items = this.props.items;

      this.setState(
        { estimate: e2, shipToAddress: this.props.shippingAddress },
        () => this.calculateTotal(),
      );
    }

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  updateEstimate(property, value, callback) {
    var obj = this.state.estimate;
    obj[property] = value;
    this.setState({ estimate: obj });

    if (callback) callback();
  }

  updateLineItem(id, property, value) {
    var x = this.state.estimate;

    for (var i = 0; i < x.Items.length; i++) {
      if (x.Items[i].id === id) x.Items[i][property] = value;
    }

    this.setState({ estimate: x }, () => {
      this.calculateTotal();
    });
  }

  deleteLineItem(id) {
    var x = this.state.estimate;

    for (var i = 0; i < x.Items.length; i++) {
      if (x.Items[i].id === id) {
        x.Items.splice(i, 1);
        break;
      }
    }

    this.setState({ estimate: x }, () => {
      this.calculateTotal();
    });
  }

  calculateTotal() {
    var x = this.state.estimate;

    var total = 0.0;
    var totalCommission = 0.0;

    for (var i = 0; i < x.Items.length; i++) {
      if (!isNaN(x.Items[i].qty) && !isNaN(x.Items[i].listPrice)) {
        x.Items[i].total = (
          Number(x.Items[i].qty) * Number(x.Items[i].customPrice)
        ).toFixed(2);
      } else {
        x.Items[i].total = 0.0;
      }
      total = (Number(total) + Number(x.Items[i].total)).toFixed(2);

      x.Items[i].commission = affiliate.calculateCommission(
        x.Items[i],
        x.Items[i].customPrice,
        this.props.affiliate,
      );
      x.Items[i].totalCommission = (
        Number(x.Items[i].commission) * Number(x.Items[i].qty)
      ).toFixed(2);
      totalCommission =
        Number(totalCommission) + Number(x.Items[i].totalCommission);
      console.log('item commission:' + x.Items[i].commission);
      console.log('total commission:' + x.Items[i].totalCommission);
      console.log('estimate Commission:' + totalCommission);
    }

    x.estimateTotal = (Number(total) + Number(this.state.tax)).toFixed(2);
    x.totalCommission = Number(totalCommission).toFixed(2);
    this.setState({ estimate: x });
  }

  saveEstimate() {
    var estimate = this.state.estimate;

    for (var i = 0; i < estimate.Items.length; i++) {
      estimate.Items[i] = {
        id: estimate.Items[i].id,
        customPrice: estimate.Items[i].customPrice,
        listPrice: estimate.Items[i].listPrice,
        name: estimate.Items[i].name,
        productID: estimate.Items[i].productID,
        qty: estimate.Items[i].qty,
        total: estimate.Items[i].total,
        commission: estimate.Items[i].commission,
        totalCommission: estimate.Items[i].totalCommission,
        chosenMargin: estimate.Items[i].chosenMargin,
        cost: estimate.Items[i].cost,
      };
    }

    estimate.userID = this.props.user.id;
    estimate.userName =
      this.props.user.firstName + ' ' + this.props.user.lastName;

    if (!estimate.created)
      estimate.created = Math.round(new Date().getTime() / 1000);

    estimate.lastModifiedBy = estimate.userName;
    estimate.lastModifiedOn = Math.round(new Date().getTime() / 1000);

    db.updateEstimate(estimate.id, estimate).then(() => {
      this.sendNotifications(estimate);

      if (this.props.estimateSaved) this.props.estimateSaved(estimate);

      if (!this.state.editMode) {
        this.props.onCartCleared();
        this.setState({ complete: true });
      } else this.handleClose('modal');
    });
  }

  sendNotifications(estimate) {
    var text = 'A new estimate was created by ' + this.props.user.email + '.';

    var html = '<html>';
    html =
      html + 'A new estimate was created by ' + this.props.user.email + '.';

    html = html + '<br /><br />';
    html = html + '<b>Estimate Name</b><br />';
    html = html + estimate.Name;

    html = html + '<br /><br />';
    html = html + '<b>Customer Name</b><br />';
    html = html + estimate.CustomerName;

    html = html + '<br /><br />';

    html = html + '<table style="width:100%">';
    html = html + '<tr>';
    html = html + '<td><b>ID</b></td>';
    html = html + '<td><b>Name</b></td>';
    html = html + '<td><b>Quoted</b></td>';
    html = html + '<td><b>List</b></td>';
    html = html + '</tr>';

    for (var i = 0; i < estimate.Items.length; i++) {
      html = html + '<tr>';
      html = html + '<td>' + estimate.Items[i].productID + '</td>';
      html = html + '<td>' + estimate.Items[i].name + '</td>';
      html = html + '<td>$' + estimate.Items[i].customPrice + '</td>';
      html = html + '<td>$' + estimate.Items[i].listPrice + '</td>';
      html = html + '</tr>';
    }
    html = html + '</table>';

    html = html + '<br /><br />';
    html = html + '';
    html = html + '</html>';

    // admin Email

    const adminEmail = {
      to: 'rbj@eccjan.com', //
      cc: 'cs@eccjan.com',
      subject: 'New Estimate: ' + estimate.CustomerName,
      text: text,
      html: html,
    };

    fetch('https://us-central1-kekaha-ebf1f.cloudfunctions.net/sendEmail', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(adminEmail),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log('error (1): ');
          console.log(response);
        }
      })
      .catch((error) => {
        // If there is any error you will catch them here
        console.log('error (2):  ' + error);
      });
  }

  downloadPDF() {
    var estimate = this.state.estimate;
    var url = 'https://us-central1-kekaha-ebf1f.cloudfunctions.net/getPDF';

    var html = '<html>';
    html = html + '<head>';
    html = html + '<style>';
    html =
      html +
      'h2 {font-size:17px; font-family:arial, helvetica, sans-serif; font-weight:600}';
    html =
      html +
      '.lineItem {font-size:8px; line-height:13px; font-family:arial, helvetica, sans-serif; }';
    html = html + '</style>';
    html = html + '</head>';
    html = html + '<body>';

    html =
      html +
      "<img src='https://www.eccjan.com/ecclesia.jpg' style='height:123px; margin-left:-12px;' />";
    html = html + '<br />';
    html = html + "<div class='lineItem' >4100 Eldorado Pkwy</div>";
    html = html + "<div class='lineItem' >Ste. 100-#121</div>";
    html = html + "<div class='lineItem' >McKinney, Texas 75070</div>";
    html = html + "<div class='lineItem' >844 4ECCJAN (432-2526)</div>";
    html =
      html +
      "<div class='lineItem' style='color:gray;' >https://www.eccjan.com</div>";
    html = html + '<h2>Estimate</h2>';
    html =
      html +
      "<div class='lineItem' style='color:green' >Prepared for " +
      estimate.CustomerName +
      ' by ' +
      this.props.user.firstName +
      ' ' +
      this.props.user.lastName +
      '</div>';
    html =
      html +
      "<div class='lineItem' style='color:green' >" +
      dateConverter(new Date().getTime()) +
      '</div>';
    html = html + '<br />';
    html = html + '<div>';
    html =
      html +
      "<div class='lineItem' style='width:100px; display: inline-block; font-weight:600; color: gray;'>Product ID</div>";
    html =
      html +
      "<div class='lineItem' style='width:321px; display: inline-block; font-weight:600; color: gray;'>Name</div>";
    html =
      html +
      "<div class='lineItem' style='width:100px; display: inline-block; font-weight:600; color: gray; text-align:right'>Price</div>";
    html = html + '</div>';
    html = html + '<hr />';

    for (var i = 0; i < estimate.Items.length; i++) {
      html = html + '<div>';
      html =
        html +
        "<div class='lineItem' style='width:100px; display: inline-block;'>" +
        estimate.Items[i].productID +
        '</div>';
      html =
        html +
        "<div class='lineItem' style='width:321px; display: inline-block;'>" +
        this.trim(estimate.Items[i].name, 66) +
        '</div>';
      html =
        html +
        "<div class='lineItem' style='width:100px; display: inline-block; text-align:right'>$" +
        estimate.Items[i].customPrice +
        '</div>';
      html = html + '</div>';
    }
    html = html + '<div>';
    html =
      html +
      "<div class='lineItem' style='width:100px; display: inline-block; font-weight:600;'></div>";
    html =
      html +
      "<div class='lineItem' style='width:321px; display: inline-block; font-weight:600;'></div>";
    html =
      html +
      "<div class='lineItem' style='width:100px; display: inline-block; font-weight:600; text-align:right'>$" +
      numberWithCommas(this.state.estimate.estimateTotal) +
      '</div>';
    html = html + '</div>';

    html = html + '<br />';
    html =
      html +
      "<div class='lineItem' style='color:gray' >Note: this estimate must be accepted within 15 days to be remain valid.</div>";

    html = html + '</body>';
    html = html + '</html>';

    var data = {
      html: html,
    };

    fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.blob();
        } else {
          console.log('error1');
          console.log(response);
        }
      })
      .then((blob) => {
        this.downloadFile(blob, 'estimate.pdf');
      })
      .catch((error) => {
        // If there is any error you will catch them here
        console.log('error getting PDF for estimate: ' + error);
      });
  }

  downloadFile(blob, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
  }

  disableSave() {
    var estimate = this.state.estimate;

    if (estimate.Name.length > 0) {
      return false;
    } else return true;
  }

  trim(value, maxLength) {
    if (value) {
      if (value.trim().length > maxLength)
        return value.trim().substring(0, maxLength) + '...';
      else return value.trim();
    } else return '';
  }

  showButton() {
    if (this.props.button) return 'block';
    else return 'none';
  }

  showIconButton() {
    if (this.props.iconButton) return 'block';
    else return 'none';
  }

  showDeleteBtn() {
    if (this.state.editMode) return 'table-cell';
    else return 'none';
  }

  showEditCtrl() {
    if (this.state.editMode) return 'inline-block';
    else return 'none';
  }

  showReadOnlyQty() {
    if (this.state.editMode) return 'none';
    else return 'block';
  }

  getLastModified() {
    if (
      this.state.estimate.lastModifiedBy &&
      this.state.estimate.lastModifiedOn
    ) {
      return (
        <div>
          Last updated by {this.state.estimate.lastModifiedBy} on{' '}
          {timeConverter(this.state.estimate.lastModifiedOn)}.
        </div>
      );
    } else {
      return '';
    }
  }

  productChanged(product) {
    var x = this.state.estimate;

    for (var i = 0; i < x.Items.length; i++) {
      if (x.Items[i].id === product.id) x.Items[i] = product;
    }

    this.setState({ estimate: x }, () => {
      this.calculateTotal();
    });
  }

  orderComplete() {
    if (this.state.estimate.status === 'COMPLETE') return true;
    else return false;
  }

  showSave() {
    if (this.state.estimate.status === 'COMPLETE') return 'none';
    else return 'inline-block';
  }

  render() {
    const { classes } = this.props;

    if (this.state.complete === true) {
      return <Redirect to="/products" />;
    }

    return (
      <div>
        <Button
          style={{ width: '100%', display: this.showButton() }}
          disabled={this.props.isDisabled}
          onClick={() => this.handleClickOpen('modal')}
        >
          Save as Estimate
        </Button>
        <IconButton
          style={{ display: this.showIconButton() }}
          onClick={() => this.handleClickOpen('modal')}
        >
          <Edit />
        </IconButton>
        <Dialog
          classes={{ root: classes.center, paper: classes.modal }}
          maxWidth="md"
          fullWidth
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose('modal')}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer direction="row">
              <GridItem xs={12} sm={12}>
                <h4 className={classes.modalTitle}>Estimate</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{ backgroundColor: '#efefef', paddingTop: '-0px' }}
          >
            <Card
              style={{
                minHeight: '369px',
                padding: '20px',
                paddingBottom: '0px',
              }}
            >
              <GridContainer direction="row">
                <GridItem xs={6}>
                  <CustomInput
                    labelText="Name"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControl,
                    }}
                    inputProps={{
                      placeholder: 'Give this estimate a name',
                    }}
                    value={this.state.estimate.Name}
                    onChange={(e) =>
                      this.updateEstimate('Name', e.target.value)
                    }
                    disabled={this.orderComplete()}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <CustomInput
                    labelText="Customer Name"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControl,
                    }}
                    inputProps={{
                      placeholder: 'Who is the prospective customer?',
                    }}
                    value={this.state.estimate.CustomerName}
                    onChange={(e) =>
                      this.updateEstimate('CustomerName', e.target.value)
                    }
                    disabled={this.orderComplete()}
                  />
                </GridItem>

                <GridItem xs={12} style={{ marginTop: '23px' }}>
                  <div style={{ fontWeight: 500, fontSize: '17px' }}>
                    Product Summary
                  </div>
                  <div
                    style={{
                      paddingBottom: '20px',
                      maxHeight: '369px',
                      overflowY: 'auto',
                    }}
                  >
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              padding: '0px',
                              width: '50px',
                              display: this.showDeleteBtn(),
                            }}
                          ></TableCell>
                          <TableCell style={{ padding: '3px' }}>
                            Product ID
                          </TableCell>
                          <TableCell style={{ padding: '3px' }}>Name</TableCell>
                          <TableCell
                            style={{ padding: '3px', textAlign: 'right' }}
                          >
                            Qty
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', textAlign: 'right' }}
                          >
                            Price
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', textAlign: 'right' }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', textAlign: 'right' }}
                          >
                            Commission
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.estimate.Items.map((value, index) => (
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                padding: '0px',
                                width: '50px',
                                display: this.showDeleteBtn(),
                              }}
                            >
                              <Tooltip
                                id="tooltip-top-start"
                                title="Delete Line Item"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <ConfirmDialog
                                  header="Confirm Delete"
                                  description="Are you sure you want to delete this product? This action cannot be undone."
                                  confirmed={() =>
                                    this.deleteLineItem(value.id)
                                  }
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {value.productID}
                            </TableCell>
                            <TableCell style={{ padding: '3px' }}>
                              {this.trim(value.name, 54)}
                            </TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              <div style={{ display: this.showReadOnlyQty() }}>
                                {value.qty}
                              </div>
                              <div style={{ display: this.showEditCtrl() }}>
                                <input
                                  type="text"
                                  value={value.qty}
                                  placeholder="qty"
                                  onChange={(e) =>
                                    this.updateLineItem(
                                      value.id,
                                      'qty',
                                      e.target.value,
                                    )
                                  }
                                  className={classes.modalTextBox}
                                  style={{ width: '50px' }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '3px',
                                textAlign: 'right',
                                width: '140px',
                              }}
                            >
                              <div style={{ display: this.showEditCtrl() }}>
                                <PricePopover
                                  product={value}
                                  productChanged={this.productChanged}
                                />
                              </div>
                              <div style={{ display: 'inline-block' }}>
                                ${value.customPrice}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ padding: '3px', textAlign: 'right' }}
                            >
                              ${value.total}
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '3px',
                                textAlign: 'right',
                                color: '#3fa31b',
                              }}
                            >
                              ${value.totalCommission}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            style={{ display: this.showDeleteBtn() }}
                          ></TableCell>
                          <TableCell colSpan="3"></TableCell>
                          <TableCell
                            style={{ padding: '3px', textAlign: 'right' }}
                          >
                            <br />
                            <b>Total</b>
                          </TableCell>
                          <TableCell
                            style={{ padding: '3px', textAlign: 'right' }}
                          >
                            <br />
                            <b>
                              $
                              {numberWithCommas(
                                this.state.estimate.estimateTotal,
                              )}
                            </b>
                          </TableCell>
                          <TableCell
                            style={{
                              padding: '3px',
                              textAlign: 'right',
                              color: '#3fa31b',
                            }}
                          >
                            <br />
                            <b>
                              $
                              {numberWithCommas(
                                this.state.estimate.totalCommission,
                              )}
                            </b>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </div>
                </GridItem>
              </GridContainer>
            </Card>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter}
            style={{ backgroundColor: '#efefef' }}
          >
            <GridContainer style={{ width: '100%' }}>
              <GridItem
                xs={6}
                style={{
                  textAlign: 'left',
                  color: '#545454',
                  fontSize: '11px',
                }}
              >
                {/*this.getLastModified()*/}
                <Button onClick={() => this.downloadPDF()}>Download PDF</Button>
              </GridItem>
              <GridItem xs={6}>
                <Button onClick={() => this.handleClose('modal')}>
                  Cancel
                </Button>
                &nbsp;
                <Button
                  color="success"
                  onClick={this.saveEstimate}
                  disabled={this.disableSave()}
                  style={{ display: this.showSave() }}
                >
                  Save
                </Button>
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onCartCleared: CartCleared }, dispatch);
}

Estimate = connect(mapStateToProps, mapDispatchToProps)(Estimate);
export default withStyles(modalStyle)(Estimate);
