import React from "react";

//redux
import { connect } from "react-redux";

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//page sections
import SectionFrequent from './SectionFrequent';
import SectionSpecials from './SectionSpecials';

class SectionLanding extends React.Component {

    constructor(props) {
        super()
        this.state = {
          
        }
    }
    

    render() {

        return (
            <div style={{ paddingBottom:"70px" }}>
                <GridContainer>
                    <GridItem xs={12} sm={3} style={{color:'gray'}}>
                        <SectionSpecials />
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <SectionFrequent selectedLocation={this.props.shipTo} />
                    </GridItem>
                </GridContainer>
                
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return state;
};
  
SectionLanding = connect(mapStateToProps)(SectionLanding);
export default withStyles(landingPageStyle)(SectionLanding);
