import React from 'react';

// styles
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import customInputStyle from "assets/jss/material-kit-pro-react/components/customInputStyle.jsx";

//controls
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";

function DatePicker({...props}) {
  const { 
    classes, 
    formControlProps, 
    labelText, 
    id,
    labelProps, 
    inputProps,
    white,
    inputRootCustomClasses, 
    onChange, 
    value, 
    rightAlign
  } = props;
 /*
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  */
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [classes.rightAlignInput]: rightAlign
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <InputLabel 
        style={{fontSize:"14px", color:"#aaaaaa", top:"10px", fontWeight:400, transform:"translate(0, 1.5px) scale(0.75)"}}
        htmlFor={id}
        {...labelProps}
        >
        {labelText}
      </InputLabel>
      <Datetime 
        classes={{
          root: marginTop,
          input: inputClasses
        }}
        timeFormat={false}
        {...inputProps}
        onChange={onChange}
        value={value}
        id={id}
      />
    </FormControl>
  );
}

export default withStyles(customInputStyle)(DatePicker);